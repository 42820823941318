import { AsyncAction, AsyncThunkAction } from 'actions/types'
import {
  ClientService,
  ClientTimer,
  Operation,
  saveClientStatisticApi,
} from 'api/clientStatisticApi'
import { indexPath } from 'components/paths'
import { splitOnFixedParts } from 'functions/array'
import { findPathContext } from 'functions/findPathContext'

const createContext = (pathname: string) => {
  const path = findPathContext(pathname)
  if (path && path !== indexPath) {
    return path
      .split('/')
      .filter((value: string) => value)
      .join('_')
  }
  return 'default'
}

export const ADD_CLIENT_STATISTIC = 'ADD_CLIENT_STATISTIC'
export const SAVE_CLIENT_STATISTIC = 'SAVE_CLIENT_STATISTIC'

interface AddClientStatisticAction {
  type: typeof ADD_CLIENT_STATISTIC
  payload: ClientTimer
}

interface SaveClientStatisticAction extends AsyncAction {
  type: typeof SAVE_CLIENT_STATISTIC
}

export type ClientStatisticTypes =
  | AddClientStatisticAction
  | SaveClientStatisticAction

const addClientTimerAction = (timer: ClientTimer): ClientStatisticTypes => {
  return {
    type: ADD_CLIENT_STATISTIC,
    payload: timer,
  }
}

/**
 * Похоже проблема в том, что time передаётся с нулевым значением (видно на скриншоте).
 * Необходимо передать длительность в поле time. Значение поля endTime не имеет значения,
 * оно не используется, но наличие поля обязательно (хотя бы с нулём).
 *
 * https://youtrack.mamba.ru/issue/M-9634#focus=Comments-4-59539.0-0
 */
export const addTimeAction =
  (
    service: ClientService,
    operation: Operation,
    time: number,
    endTime: number
  ): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      router: {
        location: { pathname },
      },
    } = getState()

    dispatch(
      addClientTimerAction({
        service,
        operation,
        context: createContext(pathname),
        time,
        endTime,
      })
    )
  }

export const addCountAction =
  (service: ClientService, operation: Operation): AsyncThunkAction =>
  (dispatch) => {
    if (process.env.production) {
      dispatch(addTimeAction(service, operation, 1, 1))
    }
  }

export const saveClientTimersAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    const { clientStatistic: { timers } = { timers: [] } } = getState()
    if (timers.length) {
      splitOnFixedParts(timers).forEach((timersPart) =>
        dispatch({
          type: SAVE_CLIENT_STATISTIC,
          promise: () => saveClientStatisticApi({ timers: timersPart }),
        })
      )
    }
  }
