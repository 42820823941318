import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { fetchQueryApi } from 'api/fetchApi'
import { paths } from 'api/generated/system_settings'

type Method = paths['/system_settings']['get']
type Query = Method['parameters']['query']
type Result = Method['responses']['200']['schema']

type CorrectType =
  | 'start_screen'
  | 'comet'
  | 'ask-to-rate-app'
  | 'the-game-promo'

export const fetchSettingServicesEnableApi = (
  service: string[],
  headers?: Api6NodeHeaders
) => {
  return fetchQueryApi<Result, Query>(
    '/system_settings',
    {
      settings: service.join(',') as unknown as CorrectType,
    },
    {
      headers,
    }
  )
}
