import { AsyncAction } from 'actions/actions.types'
import { fetchAlbumsApi } from 'api/photoApi'

export const FETCH_ALBUMS = 'FETCH_ALBUMS' as const

export interface FetchAlbumsIfNeededAction
  extends AsyncAction<Awaited<ReturnType<typeof fetchAlbumsApi>>['result']> {
  type: typeof FETCH_ALBUMS
}

export const fetchAlbumsIfNeededAction = () => (dispatch, getState) => {
  const state = getState()
  if (
    state.photoReducer.albums?.length === 0 &&
    state.authorizationReducer.authorized
  ) {
    dispatch({
      type: FETCH_ALBUMS,
      promise: () => fetchAlbumsApi(),
    })
  }
}
