import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { removeAccountApi } from 'api/settings/removeAccountApi'

export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT' as const

const removeAccountPlainAction = (
  removalReason: string,
  confirmationCode: string,
  mailstat: string
) => ({
  type: REMOVE_ACCOUNT,
  promise: () => removeAccountApi(removalReason, confirmationCode, mailstat),
})

export const removeAccountAction =
  (
    removalReason: string,
    confirmationCode: string,
    mailstat: string
  ): AsyncThunkAction =>
  async (dispatch) => {
    const result = await dispatch(
      removeAccountPlainAction(removalReason, confirmationCode, mailstat)
    )

    /** @ts-expect-error @TODO Удалить этот комментарий, когда redux будет правильно затипизирован */
    if (result.error) {
      /** @ts-expect-error */
      const errorMessage = result.error?.code
      if (errorMessage) {
        console.error(`Произошла ошибка при удалении аккаунта: ${errorMessage}`)
      } else {
        console.error('Нет описание ошибки, при удаление аккаунта')
      }
    }

    return result
  }

/** @TODO Удалить, когда redux будет правильно затипизирован */
export type RemoveAccountAction = AsyncAction &
  ReturnType<typeof removeAccountPlainAction>
