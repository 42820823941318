import { AsyncAction } from 'actions/actions.types'
import { SOCKET_RECEIVE_DATA } from 'actions/socket/socketReceiveDataAction'
import { displayUniNoticeAction } from 'actions/uninotice/displayUniNoticeAction'
import { FETCH_NOTICE_SHORTCUT } from 'actions/uninotice/noticeShortCutAction'
import {
  ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX,
  FETCH_UNI_NOTICE,
} from 'actions/uninotice/uniNoticeAction'
import { setNewMessageCursorAction } from 'actions/webSocketAction'
import { Api6Error } from 'api/api.types'
import { DeprecatedApi6Error } from 'api/types'
import { UniNotice } from 'api/uninotice/UniNotice'
import {
  UniNoticePayload,
  UniNoticeToastPayload,
} from 'api/uninotice/uninotice.types'
import { AppStore } from 'common/store/store.types'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'
import { ignoredUniNoticesIdsList } from 'components/uninotice/uninotice.types'
import { notEmptyArray } from 'functions/array'
import { createUniNoticeChannel } from 'functions/comet'
import { isNoticeNewMessage } from 'functions/isNoticeNewMessage'
import { filterCometMessages, isActionHasNotice } from 'reducers/functions'

const extractNoticeFromAction = (
  action: AsyncAction<Api6Error & DeprecatedApi6Error>,
  store: AppStore
) => {
  const { result, type, ready, error } = action
  const {
    authorizationReducer: { profile: { id: profileId } = {} },
  } = store.getState()

  if (
    (type === FETCH_UNI_NOTICE || type === FETCH_NOTICE_SHORTCUT) &&
    ready === true
  ) {
    return [result]
  } else if (type === ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX) {
    return [result]
  } else if (result && result.notice) {
    return [result.notice]
  } else if (result && result.internalError && result.internalError.uniNotice) {
    /**
     * Отдельно для api5
     * https://redmine.mamba.ru/issues/121972
     */
    return [result.internalError.uniNotice]
  } else if (result && result.internalError) {
    return [
      {
        ...result.internalError.notice,
        errorCode: result.internalError.code,
      },
    ]
  } else if (error && error.notice) {
    return [
      {
        ...error.notice,
        errorCode: error.code,
      },
    ]
  } else if (type === SOCKET_RECEIVE_DATA && profileId) {
    const channelUniNotice = filterCometMessages<UniNotice>(
      result as any,
      createUniNoticeChannel(profileId)
    )

    if (notEmptyArray(channelUniNotice)) {
      return channelUniNotice.map((object) => {
        if (isNoticeNewMessage(object.content)) {
          store.dispatch(setNewMessageCursorAction(object.cursor))
        }

        if (
          [
            UniNoticeType.liveness_verification_failed,
            UniNoticeType.liveness_verification_success,
          ].includes(object.content.noticeId as UniNoticeType)
        ) {
          return []
        }

        return object.content
      })
    }

    return []
  } else {
    return []
  }
}

const isNoticeActionsSupported = (
  uniNotice: UniNotice,
  availableCommonActions: string[]
) => {
  const { payload, noticeId } = uniNotice

  if (!payload) {
    console.error(`Payload is empty: "${noticeId}"`)

    return false
  }

  const toastPayload = payload as UniNoticeToastPayload
  const commonPayload = payload as UniNoticePayload

  if (
    toastPayload.action &&
    !availableCommonActions.includes(toastPayload.action.actionId)
  ) {
    console.error(
      `Toast action does not supported: "${toastPayload.action.actionId}"`
    )
    return false
  }

  if (
    commonPayload.auxBtn &&
    !availableCommonActions.includes(commonPayload.auxBtn.action.actionId)
  ) {
    console.error(
      `Action does not supported: "${commonPayload.auxBtn.action.actionId}"`
    )
    return false
  }
  if (
    commonPayload.actionBtn &&
    !availableCommonActions.includes(commonPayload.actionBtn.action.actionId)
  ) {
    console.error(
      `Action does not supported: "${commonPayload.actionBtn.action.actionId}"`
    )
    return false
  }
  return true
}

const showNoticeIfNeeded = async (
  store: AppStore,
  action: AsyncAction<Api6Error & DeprecatedApi6Error>
) => {
  const { availableCommonActions } = await import(
    'actions/uninotice/commonUniNoticeActions'
  )

  const uniNoticeList = extractNoticeFromAction(action as any, store)

  uniNoticeList.forEach((uniNotice) => {
    if (ignoredUniNoticesIdsList.includes(uniNotice.noticeId)) {
      return
    }

    if (!isNoticeActionsSupported(uniNotice, availableCommonActions)) {
      return console.error(
        `UniNotice does not supported "${uniNotice.noticeId}"`
      )
    }
    store.dispatch(displayUniNoticeAction(uniNotice as UniNotice))
  })
}

export const noticeMiddleware =
  (store: AppStore) =>
  (next: Function) =>
  (action: AsyncAction<Api6Error & DeprecatedApi6Error>) => {
    if (isActionHasNotice(action, store)) {
      showNoticeIfNeeded(store, action).catch(console.error)
    }

    try {
      return next(action)
    } catch (error) {
      console.error('Notice middleware error', action)

      return next(action)
    }
  }
