export const zIndex = {
  hidden: -2,
  background: 0,
  default: 1,
  /**
    Нужно для love.mail.ru
  */
  mailruMenuBurger: 2,
  mailruMenuTop: 3,
  /** Самое высокое значение */
  topMost: 999,
} as const
