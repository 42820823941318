import { AsyncAction, AsyncThunkActionVoid } from 'actions/actions.types'
import { createHeadersForNotifications } from 'api/push/functions'
import { subscribeOnPushesApi } from 'api/push/subscribeOnPushesApi'

export const SUBSCRIBE_ON_PUSHES = 'SUBSCRIBE_ON_PUSHES' as const

export interface SubscribeOnPushesAction extends AsyncAction {
  type: typeof SUBSCRIBE_ON_PUSHES
  field: string
  value: boolean
}

export const subscribeOnPushesAction =
  (field: string, value: boolean): AsyncThunkActionVoid =>
  (dispatch, getState) => {
    const {
      systemReducer: { partnerId },
    } = getState()

    /**
     * На сервер нужно передавать дельту/дифф состояния подписок.
     * Но, поскольку, на клиенте уже реализована логика работы с одним чекбоксом за раз,
     * то нужен вот такой костыль ниже.
     */
    const subscribed = value ? [Number(field)] : []
    const unsubscribed = value ? [] : [Number(field)]

    const action: SubscribeOnPushesAction = {
      type: SUBSCRIBE_ON_PUSHES,
      promise: () =>
        subscribeOnPushesApi(
          subscribed,
          unsubscribed,
          createHeadersForNotifications(partnerId)
        ),
      field,
      value,
    }

    dispatch(action)
  }
