import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { fetchMiniApi } from 'api/fetchMiniApi'
import { NodeHeaders } from 'api/NodeHeaders'
import { MiniApiResult } from 'api/user/MiniApiResult'
import { Api5AuthResponse, Api5Error } from 'common-constants/api5.types'

export const FETCH_MINI = 'FETCH_MINI'

export interface FetchMiniIfNeededAction
  extends AsyncAction<MiniApiResult & Api5Error> {
  type: typeof FETCH_MINI
}

export const fetchMiniIfNeededAction =
  (headers?: NodeHeaders): AsyncThunkAction<ReturnType<typeof fetchMiniApi>> =>
  async (dispatch, getState) => {
    const {
      authorizationReducer: { miniLoading },
    } = getState()

    if (!miniLoading) {
      return dispatch({
        type: FETCH_MINI,
        promise: () => fetchMiniApi(headers),
      })
    }

    return Promise.resolve({} as Api5AuthResponse)
  }
