import { findApiLocale } from 'actions/actions.functions'
import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchLocationSuggestListApi } from 'api/locationApi'
import { LocationPosition } from 'api/types'
import { concatLocationName } from 'functions/client'

export const FETCH_LOCATION_SUGGEST_LIST =
  'FETCH_LOCATION_SUGGEST_LIST' as const

export interface LocationSuggestion extends LocationPosition {
  /** Например: "Москва, Московская область, Россия" */
  locationString: string
}

export interface FetchLocationSuggestListAction
  extends AsyncAction<LocationSuggestion[]> {
  locationId: string
  type: typeof FETCH_LOCATION_SUGGEST_LIST
  payload: LocationPosition[]
}

export const fetchLocationSuggestListAction =
  (locationId: string, skipInStateCheck?: boolean) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const state = getState()

    const { locationSuggestionList } = state.geolocation

    const hasLocationSuggestionInState = Boolean(
      locationSuggestionList?.find(({ location }) => location === locationId)
    )

    if (!skipInStateCheck && hasLocationSuggestionInState) {
      return
    }

    const locale = findApiLocale(
      state.authorizationReducer.authorized,
      state.systemReducer.locale
    )

    dispatch({
      type: FETCH_LOCATION_SUGGEST_LIST,
      promise: async () => {
        const { result } = await fetchLocationSuggestListApi(locationId, locale)

        if (result) {
          return result.suggestions.map((itemLocation) => ({
            ...itemLocation,
            locationString: concatLocationName(itemLocation),
          }))
        }
      },
      locationId,
    })
  }
