// Обсудили с Сашей Шустовым, что лучше скопипастить типы из GQL
// так как они могу разойтись с GQL

export enum Gender {
  Male = 'M',
  Female = 'F',
  Any = 'N',
}

export enum DatingGoals {
  Friendship = 'Friendship',
  Email = 'Email',
  Love = 'Love',
  Flirtation = 'Flirtation',
  Family = 'Family',
  Sport = 'Sport',
  Travel = 'Travel',
  Meet = 'Meet',

  /** Update Goals https://youtrack.mamba.ru/issue/M-4832 */
  SeriousRelationship = 'SeriousRelationship',
  FriendlyCommunication = 'FriendlyCommunication',
  FlirtationAndDating = 'FlirtationAndDating',
  WillDecideWhenMeet = 'WillDecideWhenMeet',
}

export enum Constitution {
  Thin = 'Thin',
  Normal = 'Normal',
  Sports = 'Sports',
  Athletic = 'Athletic',
  Thick = 'Thick',
  Stout = 'Stout',
}

export enum Education {
  HighSchool = 'HighSchool',
  College = 'College',
  SomeUniversityCourses = 'SomeUniversityCourses',
}

export enum Sign {
  Oven = 'oven',
  Telez = 'telez',
  Bliznezi = 'bliznezi',
  Rak = 'rak',
  Lev = 'lev',
  Deva = 'deva',
  Vesi = 'vesi',
  Skorpion = 'skorpion',
  Strelez = 'strelez',
  Kozerog = 'kozerog',
  Vodoley = 'vodoley',
  Ribi = 'ribi',
}

export enum Home {
  Along = 'Along',
  Public = 'Public',
  Family = 'Family',
  Friend = 'Friend',
  Punk = 'Punk',
}

export enum Children {
  No = 'No',
  Want = 'Want',
  Together = 'Together',
  Apart = 'Apart',
}

export enum Smoke {
  No = 'No',
  Yes = 'Yes',
  Sometimes = 'Sometimes',
  GiveUp = 'GiveUp',
}

export enum Circumstance {
  Unemployed = 'Unemployed',
  Student = 'Student',
  NonStable = 'NonStable',
  Stable = 'Stable',
  Average = 'Average',
  Huge = 'Huge',
}

export enum Period {
  All = 'a',
  Day = 'd',
  Week = 'w',
  Month = 'm',
}

export type SearchFormFieldGender = 'N' | 'M' | 'F' | 'T' | 'MF' | 'MM' | 'FF'

/**
 * 204
 */
export type NoContentApi6 = null
