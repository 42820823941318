import { AsyncAction } from 'actions/actions.types'
import { fetchCaptchaApi, verifyCaptchaApi } from 'api/captcha/captchaApi'

import { AppDispatchNext, AppGetState } from './actions.typesNext'

export const CAPTCHA_SCRIPT_LOADED = 'CAPTCHA_SCRIPT_LOADED' as const
export const captchaLoadedAction = () => ({
  type: CAPTCHA_SCRIPT_LOADED,
})

export const FETCH_CAPTCHA_DATA = 'FETCH_CAPTCHA_DATA' as const
export const fetchCaptchaDataAction = () => ({
  type: FETCH_CAPTCHA_DATA,
  promise: () => fetchCaptchaApi(),
})

export const CAPTCHA_RESOLVED = 'CAPTCHA_RESOLVED' as const
export const captchaResolvedAction = (recaptchaResponse: string) => ({
  type: CAPTCHA_RESOLVED,
  recaptchaResponse,
})

export const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA' as const

export interface VerifyCaptchaAction extends AsyncAction {
  type: typeof VERIFY_CAPTCHA
}

export const verifyCaptchaAction =
  () => async (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      captchaReducer: { recaptchaResponse },
    } = getState()

    if (recaptchaResponse) {
      dispatch(verifyCaptchaPlainAction(recaptchaResponse))
    }
  }

const verifyCaptchaPlainAction = (key: string) => ({
  type: VERIFY_CAPTCHA,
  promise: () => verifyCaptchaApi(key),
})

export const UPDATE_FROM_URL_CAPTCHA = 'UPDATE_FROM_URL_CAPTCHA' as const
export const updateCaptchaFromUrlAction = (url: string) => ({
  type: UPDATE_FROM_URL_CAPTCHA,
  url,
})

export type CaptchaTypes =
  | ReturnType<typeof captchaLoadedAction>
  | ReturnType<typeof fetchCaptchaDataAction>
  | ReturnType<typeof captchaResolvedAction>
  | ReturnType<typeof verifyCaptchaPlainAction>
  | ReturnType<typeof updateCaptchaFromUrlAction>
