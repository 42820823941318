import { AsyncAction } from 'actions/actions.types'
import { AsyncThunkAction } from 'actions/types'
import { errorCodeEquals } from 'api/index'
import { deleteAccountApi } from 'api/settings/settingsApi'
import { push } from 'functions/router'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'

export interface DeleteAccountAction extends AsyncAction {
  type: typeof DELETE_ACCOUNT
}

export const deleteAccountAction =
  (successUrl: string): AsyncThunkAction =>
  (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT,
      promise: () =>
        deleteAccountApi().then((json) => {
          if (
            (json.ok || errorCodeEquals(json, 'user_deleted')) &&
            successUrl
          ) {
            dispatch(push(successUrl))
          }
          return json
        }),
    })
  }
