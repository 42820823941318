import { AppDispatchNext } from 'actions/actions.typesNext'
import { pushShortCutNotificationAction } from 'actions/uninotice/noticeShortCutAction'
import { getCurrentPositionApi } from 'api/browser/getCurrentPositionApi'
import { VoidHandler } from 'common/types'
import { NotificationType } from 'components/presentational/Notifications/NotificationType'
import { Coordinates } from 'reducers/geolocation/geolocationReducer.types'

import { setGeolocationErrorCodeAction } from './setGeolocationErrorCodeAction'
import { updateCoordinatesInStateAction } from './updateCoordinatesInStateAction'

export const getCurrentPositionAction =
  (onSuccess?: VoidHandler) => async (dispatch: AppDispatchNext) => {
    try {
      const { coords } = await getCurrentPositionApi()

      const coordinates: Coordinates = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      }

      dispatch(updateCoordinatesInStateAction(coordinates))
      onSuccess?.()

      return coordinates
    } catch (error) {
      dispatch(setGeolocationErrorCodeAction(error?.code))

      switch (error?.code) {
        case 3: // Timeout
          dispatch(
            pushShortCutNotificationAction(NotificationType.genericError)
          )
          break
      }
    }
  }
