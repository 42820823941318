import React, { FC } from 'react'

import { RouteComponentProps } from 'react-router'

import { locationField } from 'common/constants'
import { AppStaticContext } from 'components/components.types'
import { settingsMainPath } from 'components/page/Settings/Settings.paths'
import { settingsPath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const SettingsRedirect: FC<RouteComponentProps> = ({
  staticContext,
}) => {
  // Не понятно почему через match.params не пробрасывается
  if (
    staticContext &&
    (staticContext as AppStaticContext).query.place === locationField
  ) {
    return (
      <Replace
        to={mergeAllUrls(settingsPath, settingsMainPath)}
        uid="/my/edit.phtml"
      />
    )
  }
  return <Replace to={settingsPath} uid="/my/edit.phtml" />
}
