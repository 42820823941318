import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext } from 'actions/actions.typesNext'
import { setCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import { phoneVerificationAlternativeMethodApi } from 'api/phoneVerification/phoneVerificationAlternativeMethodApi'

export const PHONE_VERIFICATION_ALTERNATIVE_METHOD =
  'PHONE_VERIFICATION_ALTERNATIVE_METHOD' as const

type Response = Awaited<
  ReturnType<typeof phoneVerificationAlternativeMethodApi>
>['result']

export interface PhoneVerificationAlternativeMethodAction
  extends AsyncAction<Response> {
  type: typeof PHONE_VERIFICATION_ALTERNATIVE_METHOD
}

export const phoneVerificationAlternativeMethodAction =
  () => (dispatch: AppDispatchNext) => {
    dispatch({
      type: PHONE_VERIFICATION_ALTERNATIVE_METHOD,
      promise: async () => {
        const json = await phoneVerificationAlternativeMethodApi()

        // Установим таймер, отведенный для верификации
        if (json.result?.timer) {
          dispatch(setCounterDownAction(json.result.timer))
        }

        return json
      },
    })
  }
