import { AsyncThunkAction } from 'actions/types'
import { UniNoticeId } from 'api/uninotice/uniNoticeApi'

export const TOGGLE_INLINE_NOTICE = 'TOGGLE_INLINE_NOTICE'

export interface ToggleInlineNoticeAction {
  type: typeof TOGGLE_INLINE_NOTICE
  result: {
    uniNoticeId: UniNoticeId
    value: boolean
  }
}

export const toggleInlineNoticeAction =
  (uniNoticeId: UniNoticeId, value: boolean): AsyncThunkAction =>
  (dispatch, getState) => {
    if (
      Boolean(getState().uniNoticeReducer.activeInline[uniNoticeId]) !== value
    ) {
      return dispatch({
        type: TOGGLE_INLINE_NOTICE,
        result: {
          uniNoticeId,
          value,
        },
      })
    }

    return Promise.resolve()
  }
