import { AppRoute } from 'components/components.types'
import { FoundComponent } from 'components/system/FoundComponent'

import {
  ratingLegacyMatchPath,
  matchCouplePath,
  ratingPath,
} from './Rating.paths'
import { RatingSwitch } from './RatingSwitch'
import { MatchModalLoadable } from '../PhotoRating/MatchModalLoadable'

export const ratingRoute: AppRoute[] = [
  {
    path: ratingPath,
    component: RatingSwitch,
    exact: false,
  },
]

export const ratingLegacyMatchRoute: AppRoute[] = [
  {
    path: ratingLegacyMatchPath,
    component: MatchModalLoadable,
    exact: true,
  },
]

export const ratingMatchRoute: AppRoute[] = [
  {
    path: matchCouplePath,
    component: FoundComponent,
    exact: true,
  },
]
