import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { toggleSettingEnabledAction } from 'actions/settings/toggleSettingAction'
import { switchIncognitoApi } from 'api/settings/settingsApi'
import { SingleSelectFieldsSettings } from 'components/page/Settings/Fields'

export const DISABLE_INCOGNITO = 'DISABLE_INCOGNITO' as const

export interface DisableIncognitoAction extends AsyncAction {
  type: typeof DISABLE_INCOGNITO
}

export const disableIncognitoAction = (): DisableIncognitoAction => ({
  type: DISABLE_INCOGNITO,
  promise: () => switchIncognitoApi(false),
})

/**
 * У пользователя могу быть не загружены настройки
 * В этом случае мы просто посылает запрос на выключение.
 * При переходе на страницу настроек мы запросим настройки с сервера
 * Если же настройки были загружены, то все хорошо
 * Мы отправим запрос на сервер и обновим state
 */
export const switchIncognitoUserAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    if (getState().settingsReducer.form.incognito !== undefined) {
      return dispatch(
        toggleSettingEnabledAction(
          SingleSelectFieldsSettings.Incognito,
          false,
          true
        )
      )
    } else {
      return dispatch(disableIncognitoAction())
    }
  }
