import { goBack } from 'redux-first-history'

import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { resetReferrerAction } from 'actions/system/resetReferrerAction'
import { StartScreen } from 'common/constantsStartScreen'
import { indexPath } from 'components/paths'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { go, push, replace } from 'functions/router'
import { fromPayPalOrBillingExchange } from 'hooks/useGoBackAfterPayPal'
import { findStepsBackCount } from 'reducers/locationsHistory/locationsHistoryReducer.functions'

import { isHistoryEmpty } from '../../../client/functions/isHistoryEmpty'

export const navigateModalAction =
  (method: Function, ...paths: string[]) =>
  (dispatch: AppDispatchNext, getState: AppGetState) =>
    dispatch(method(mergeAllUrls(getState().systemReducer.baseUrl, ...paths)))

export const pushBaseUrlAction =
  () => (dispatch: AppDispatchNext, getState: AppGetState) =>
    dispatch(push(getState().systemReducer.baseUrl))

export const pushModalAction = (...paths: string[]) =>
  navigateModalAction(push, ...paths)

export const replaceModalAction = (...paths: string[]) =>
  navigateModalAction(replace, ...paths)

export const goBackAction =
  (forceGoBack?: boolean) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    /**
     * Открытие некоторых модалов происходит по прямой ссылке, например:
     * app/agreement/vip
     */
    if (forceGoBack) {
      // console.log('force go back')
      dispatch(goBack())
      return
    }

    const {
      authorizationReducer: { authorized },
      locationsHistory,
      systemReducer: { baseUrl, locale },
      router: {
        location: { pathname },
      },
    } = getState()

    const hasPreviousLocation = locationsHistory.browserHistory.length > 1

    if (!hasPreviousLocation && baseUrl === pathname) {
      // console.log('go index')
      dispatch(
        replace(authorized ? indexPath : mergeAllUrls(indexPath, locale))
      )
      return
    }

    if (
      hasPreviousLocation ||
      /**
       * Сделал глобальное изменение при нажатии на серую область модала.
       * Лучше всего сделать регрессионное тестирование, т.к. в какой-то части приложения,
       * при определенной последовательности, которая включает нажатие на серую область,
       * переход назад(нажатие на крести или стрелку) может сработать некорретно
       * и показать неправильный экран.
       *
       * https://redmine.mamba.ru/issues/117338#note-6
       */
      baseUrl === pathname
    ) {
      const locations = [...locationsHistory.browserHistory]

      // console.log({ hasPreviousLocation, isBase: baseUrl === pathname })
      /**
       * Бывает ситуация, когда из-за реплейсов и нотисов
       * повторяются пути в истории.
       *
       * Подробнее:
       * https://youtrack.mamba.ru/issue/M-8775#focus=Comments-4-54619.0-0
       */
      if (locations[locations.length - 1]?.pathname === pathname) {
        console.warn('Duplicated back action', pathname)
        // console.log(
        //   JSON.stringify(
        //     locations.map(({ pathname }) => pathname),
        //     null,
        //     2
        //   )
        // )
        /**
         * На один назад мы идем и так, надо прибавить ниже дубликаты.
         */
        let count = 1

        /** Повторившихся урлов путем пуша может быть N */
        while (
          locations[locations.length - (1 + count)]?.pathname === pathname
        ) {
          console.warn('Count duplicated back action')
          count += 1
          locations.pop()
        }

        /** Если история не пустая после удаления повторов */
        if (locations.length) {
          // console.log('go', -count)
          return dispatch(go(-count))
        }

        // console.log('push base url')
        return dispatch(push(baseUrl))
      }

      // console.log('go back')
      return dispatch(goBack())
    }

    dispatch(replace(baseUrl))
  }

/**
 * Находит в истории локаций path и возвращает на N шагов назад в window.location.history.
 *
 * К примеру ты хочешь вернуться со страницы А на страницу Б.
 * В сценарии, когда ты сам до нее дошел и Б есть в истории, сделается go(-N)
 * Если страницы в истории нет, обычно это из-за того, что сделали рефреш страницы, отправляем юзера на нужную страницу с помощью replace
 *
 * @param path путь, куда нужно вернуться
 * @param forceIndex логика, если не нашли путь, вернуться в индекс
 */
export const goBackToAction =
  (path: string, forceIndex?: boolean) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const { locationsHistory } = getState()

    const stepsBackCount = findStepsBackCount(
      locationsHistory.browserHistory,
      path
    )

    if (stepsBackCount) {
      dispatch(go(stepsBackCount))
      return
    }

    dispatch(replace(forceIndex ? indexPath : path))
  }

/**
 * Возвращает нас к baseUrl
 */
export const goBackToBaseUrlAction =
  () => (dispatch: AppDispatchNext, getState: AppGetState) => {
    dispatch(goBackToAction(getState().systemReducer.baseUrl))
  }

/**
 * Возврат из витрин, учитывающий PayPal.
 */
export const goBackStorefrontAction =
  () => (dispatch: AppDispatchNext, getState: AppGetState) => {
    if (fromPayPalOrBillingExchange(getState().systemReducer.referrer)) {
      dispatch(resetReferrerAction())
      dispatch(go(-2))
    } else {
      dispatch(goBackAction())
    }
  }

export const replaceBaseUrlAction =
  () => (dispatch: AppDispatchNext, getState: AppGetState) => {
    return dispatch(replace(getState().systemReducer.baseUrl))
  }

export const goStartScreenPath = () => (dispatch: AppDispatchNext) =>
  dispatch(push(defineStartScreenPath(StartScreen.voting)))

export const goBackOrRatingPathAction = () => (dispatch: AppDispatchNext) => {
  if (isHistoryEmpty()) {
    dispatch(goStartScreenPath())
  } else {
    dispatch(goBackAction())
  }
}
