import { AsyncAction, AsyncThunkActionVoid } from 'actions/actions.types'
import { AsyncThunkAction } from 'actions/types'
import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import {
  authorizingInClassMateApi,
  fetchFromClassMateApi,
  fetchUrlClassMateApi,
  fetchUrlToUploadFileApi,
  uploaderPhotosSwitchApi,
  uploadPhotoFromSocialNetworkApi,
} from 'api/uploaderPhotosApi'
import { FetchFacebookPhotosAction } from 'components/page/UploaderPhotos/facebook/fetchFacebookPhotosAction'
import { FetchFacebookPhotosFailedAction } from 'components/page/UploaderPhotos/facebook/fetchFacebookPhotosFailedAction'
import { findResultNotice } from 'components/page/UploaderPhotos/function/findResultNotice'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import { ConvertedAlbum } from 'components/system/third-party/vk/api/api.types'
import {
  photoUploadFailedNoticeId,
  photoUploadStartedNoticeId,
} from 'components/uninotice/uniNoticeIdList'
import { hasUserRestrictions } from 'functions/hasUserRestrictions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'
import { onPhotoUploaded as onPhotosUploaded } from 'hooks/useShowPhotoUploader/useShowPhotoUploader.functions'
import { UploadPhoto } from 'reducers/uploaderPhoto/UploaderPhotosState'

import { FetchInternalVkPhotosAction } from './fetchInternalVkPhotosAction'

export const GET_URL_FOR_UPLOAD_PHOTOS = 'GET_URL_FOR_UPLOAD_PHOTOS' as const
export const START_UPLOAD_PHOTOS = 'START_UPLOAD_PHOTOS' as const
export const UPDATE_ALBUMS = 'UPDATE_ALBUMS' as const
export const UPDATE_PHOTO = 'UPDATE_PHOTO' as const
export const SELECT_ALL_PHOTOS = 'SELECT_ALL_PHOTOS' as const
export const UNSELECT_ALL_PHOTOS = 'UNSELECT_ALL_PHOTOS' as const
export const RESET_PHOTOS_UPLOAD = 'RESET_PHOTOS_UPLOAD' as const
export const START_UPLOAD_PHOTOS_FROM_SOCIAL_NETWORK =
  'START_UPLOAD_PHOTOS_FROM_SOCIAL_NETWORK' as const
export const FETCH_URL_CLASSMATE = 'FETCH_URL_CLASSMATE' as const
export const AUTHORIZING_IN_CLASSMATE = 'AUTHORIZING_IN_CLASSMATE' as const
export const PHOTOS_FROM_CLASS_MATE = 'PHOTOS_FROM_CLASS_MATE' as const
export const INCREASE_COUNT_UPLOAD = 'INCREASE_COUNT_UPLOAD' as const
export const RESET_INFO_CLASS_MATE = 'RESET_INFO_CLASS_MATE' as const
export const RESET_FAILED_UPLOAD = 'RESET_FAILED_UPLOAD' as const

export const fetchUrlForUploadPhotosAction = () => ({
  type: GET_URL_FOR_UPLOAD_PHOTOS,
  promise: () => fetchUrlToUploadFileApi(),
})

interface UploadPhotosAction extends AsyncAction {
  type: typeof START_UPLOAD_PHOTOS
  photosLength: number
}

export const uploadPhotosAction =
  (photos: string[] | File[], albumId: number): AsyncThunkAction =>
  (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: START_UPLOAD_PHOTOS,
      promise: async () => {
        const onPhotoUploaded = () => {
          dispatch(updateCountUploadPhotosAction())
        }

        await dispatch(
          fetchUniNoticeAction(photoUploadStartedNoticeId, {
            totalCount: photos.length,
          })
        )

        const json = await uploaderPhotosSwitchApi(
          photos,
          albumId,
          onPhotoUploaded,
          hasUserRestrictions(state)
        )

        const { noticeId, paramsForNoticeId } = findResultNotice(json)

        if (noticeId !== photoUploadFailedNoticeId) {
          dispatch(fetchUniNoticeAction(noticeId, paramsForNoticeId))
        }

        const failedUpload = noticeId === photoUploadFailedNoticeId

        if (!failedUpload) {
          onPhotosUploaded()
        }

        return {
          ...json,
          failedUpload,
        }
      },
      photosLength: photos.length,
    })
  }
export const updateCountUploadPhotosAction = (countForProgress?: number) => ({
  type: INCREASE_COUNT_UPLOAD,
  countForProgress,
})

export const updateAlbumsAction = (albums: ConvertedAlbum[]) => ({
  type: UPDATE_ALBUMS,
  albums,
})

interface SelectPhotosAllAction {
  type: typeof SELECT_ALL_PHOTOS
}

interface UnSelectPhotosAllAction {
  type: typeof UNSELECT_ALL_PHOTOS
}

export const unSelectPhotosAllAction = () => ({
  type: UNSELECT_ALL_PHOTOS,
})

interface UpdatePhotoUploadAction {
  type: typeof UPDATE_PHOTO
  photoId: number
}

export const updatePhotoUploadAction = (photoId: number | string) => {
  return {
    type: UPDATE_PHOTO,
    photoId,
  }
}

export const uploadPhotosFromSocialNetworkAction = (
  photos: UploadPhoto[],
  albumId: number
) => ({
  type: START_UPLOAD_PHOTOS_FROM_SOCIAL_NETWORK,
  promise: () => uploadPhotoFromSocialNetworkApi(photos, albumId),
})

export const resetPhotosUploadAction = () => ({
  type: RESET_PHOTOS_UPLOAD,
})

export interface FetchUrlClassMateAction extends AsyncAction<{ url: string }> {
  type: typeof FETCH_URL_CLASSMATE
}

export const fetchUrlClassMateAction = (): FetchUrlClassMateAction => ({
  type: FETCH_URL_CLASSMATE,
  promise: () => fetchUrlClassMateApi(),
})

export interface AuthorizingWaitingAction
  extends AsyncAction<{ error_code?: unknown }> {
  type: typeof AUTHORIZING_IN_CLASSMATE
}

export const authorizingWaitingAction = (): AuthorizingWaitingAction => ({
  type: AUTHORIZING_IN_CLASSMATE,
  promise: () => authorizingInClassMateApi(),
})

export interface FetchPhotosFromClassMateAction
  extends AsyncAction<ConvertedAlbum[]> {
  type: typeof PHOTOS_FROM_CLASS_MATE
}

export const fetchPhotosFromClassMateAction = () => ({
  type: PHOTOS_FROM_CLASS_MATE,
  promise: () => fetchFromClassMateApi(),
})

export const resetInfoClassMateAction = () => ({
  type: RESET_INFO_CLASS_MATE,
})

export const pushOrReplaceUploadPhotoAction =
  (pushUrl = false): AsyncThunkActionVoid =>
  (dispatch, getState) => {
    const {
      systemReducer: { baseUrl },
    } = getState()

    const url = mergeAllUrls(baseUrl, uploadPhotosPath, albumPath)

    if (pushUrl) {
      return dispatch(push(url))
    } else {
      return dispatch(replace(url))
    }
  }

export const resetFailedUploadAction = () => ({
  type: RESET_FAILED_UPLOAD,
})

export const UPDATE_PHOTO_FROM_USER = 'UPDATE_PHOTO_FROM_USER' as const

export const updatePhotoFromUserAction = (id: number, link: string) => ({
  type: UPDATE_PHOTO_FROM_USER,
  id,
  link,
})

export const UPDATE_QUEUE_PHOTO_UPLOAD = 'UPDATE_QUEUE_PHOTO_UPLOAD' as const

export const updateQueuePhotoUploadAction = (id: number) => ({
  type: UPDATE_QUEUE_PHOTO_UPLOAD,
  id,
})

export const PREPROCESSING_PHOTO_QUEUE = 'PREPROCESSING_PHOTO_QUEUE' as const

export const preProcessingPhotoQueueAction = (value: boolean) => ({
  value,
  type: PREPROCESSING_PHOTO_QUEUE,
})

export type UploaderTypes =
  | ReturnType<typeof fetchUrlForUploadPhotosAction>
  | UploadPhotosAction
  | ReturnType<typeof updateCountUploadPhotosAction>
  | ReturnType<typeof updateAlbumsAction>
  | SelectPhotosAllAction
  | UnSelectPhotosAllAction
  | UpdatePhotoUploadAction
  | ReturnType<typeof uploadPhotosFromSocialNetworkAction>
  | ReturnType<typeof resetPhotosUploadAction>
  | FetchUrlClassMateAction
  | AuthorizingWaitingAction
  | FetchPhotosFromClassMateAction
  | ReturnType<typeof resetInfoClassMateAction>
  | ReturnType<typeof resetFailedUploadAction>
  | ReturnType<typeof updatePhotoFromUserAction>
  | ReturnType<typeof updateQueuePhotoUploadAction>
  | ReturnType<typeof preProcessingPhotoQueueAction>
  | FetchFacebookPhotosAction
  | FetchFacebookPhotosFailedAction
  | FetchInternalVkPhotosAction
