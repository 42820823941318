import { AsyncAction } from 'actions/actions.types'
import { fetchSearchWithParamsApi } from 'api/search/fetchSearchWithParamsApi'

export const SERIOUS_SEARCH = 'SERIOUS_SEARCH' as const

export interface SeriousSearchAction
  extends AsyncAction<
    Awaited<ReturnType<typeof fetchSearchWithParamsApi>>['result']
  > {
  type: typeof SERIOUS_SEARCH
}
