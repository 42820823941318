import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { sendStatisticsAction } from 'actions/analytics/sendStatisticsAction'
import { createHeadersForNotifications } from 'api/push/functions'
import { registerPushConsumerApi } from 'api/push/registerPushConsumerApi'

export const REGISTER_PUSH_TOKEN = 'REGISTER_PUSH_TOKEN' as const

export interface RegisterPushConsumerAction extends AsyncAction {
  type: typeof REGISTER_PUSH_TOKEN
  value: string
}

export const registerPushConsumerAction =
  (token: string): AsyncThunkAction =>
  async (dispatch, getState) => {
    const {
      systemReducer: { locale, osName, partnerId },
    } = getState()

    dispatch(sendStatisticsAction('open', 'notifications_on', 'notifications'))

    return dispatch<RegisterPushConsumerAction>({
      type: REGISTER_PUSH_TOKEN,
      promise: () =>
        registerPushConsumerApi(
          { token, locale, osName },
          createHeadersForNotifications(partnerId)
        ),
      value: token,
    })
  }
