import { definitions } from 'api/generated/photoline'
import { PhotoLineItem } from 'reducers/photoLine/photoLine.types'

export const replacePhoto = (
  photos: PhotoLineItem[],
  modifiedData: PhotoLineItem
) => {
  const outdatedPhoto = photos.find(({ id }) => id === modifiedData.id)
  if (outdatedPhoto) {
    const index = photos.indexOf(outdatedPhoto)
    photos[index] = { ...outdatedPhoto, ...modifiedData }
  }
}

export const byAction =
  (selectedAction: string) =>
  ({ action }: { action: string }) =>
    action === selectedAction

export const equalId =
  (selectedId: number) =>
  ({ id }: { id: number | string }) =>
    id === selectedId

/**
 * authorId это фикс доки бекенда.
 * @param item
 */
export const fromFetch = (
  item: definitions['PhotolinePostWithAuthor'] & { authorId: number }
): PhotoLineItem => {
  const { id, authorId, user, photoUrls, message, num } = item
  const { name, age, isVip, locationName, statuses } = user
  const { online, hasVerifiedPhoto } = statuses
  return {
    key: id,
    id,
    authorId,
    name,
    age,
    vip: isVip,
    location: locationName,
    photo: photoUrls.squareSmall,
    online: Boolean(online && online.isOn),
    hasVerifiedPhoto: Boolean(hasVerifiedPhoto && hasVerifiedPhoto.isOn),
    message,
    amount: num,
  }
}
