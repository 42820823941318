import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { sendStatisticsAuthorizedApi } from 'api/analytics/sendStatisticsAuthorizedApi'
import { sendStatisticsNotAuthorizedApi } from 'api/analytics/sendStatisticsNotAuthorizedApi'

export const ANALYTIC_CUBSTATE_SENDING = 'ANALYTIC_CUBSTATE_SENDING' as const

export const sendStatisticsAction =
  (type: string, eventId: string, fromId: string = '-') =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      authorizationReducer: { authorized },
    } = getState()

    const apiMethod = authorized
      ? sendStatisticsAuthorizedApi
      : sendStatisticsNotAuthorizedApi

    return dispatch({
      type: ANALYTIC_CUBSTATE_SENDING,
      promise: () => apiMethod(type, eventId, fromId),
    })
  }
