import cookie from 'js-cookie'

import { sPostCookie } from 'common/constants'

export const resolveToken = (): string | null => {
  const sPost = cookie.get(sPostCookie)

  if (!sPost) {
    // Не будем жестко падать
    console.error(`Csrf-Token from s_post is not defined "${sPost}".`)
    return null
  }

  return sPost
}
