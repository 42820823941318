import { CATCH_NETWORK_ERROR } from 'api/api.constants'
import { UniNotice } from 'api/uninotice/UniNotice'

export const ProfileStatuses = {
  hasVerifiedPhoto: 'hasVerifiedPhoto',
  spaceTimeLocation: 'spaceTimeLocation',
  online: 'online',
  real: 'real',
}

/**
 * Swagger-генерация не подхватила описание ошибки.
 * TODO ?
 */
export interface Api6Error {
  code: string
  message: string
  humanMessage: string | null
  data: {
    limit: number
  } | null

  notice?: UniNotice
}

export enum Api6Code {
  captcha = 'captcha',
  PhotoNotFoundInRating = 'rating_photo_not_found',
}

/**
 * Использую классы, т.к. по другому не встроится в ошибки.
 */
export class AppNetworkError extends Error {
  public code: string

  constructor(message: string) {
    super(message)

    this.code = CATCH_NETWORK_ERROR
  }
}

export class CustomError<T> extends Error {
  public code: T

  constructor(message: string, code: T) {
    super(message)

    this.code = code
  }
}
