import { ApolloClient, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { createApolloHeaders, createApolloUrl } from 'api/apollo/createApollo'
import { createInMemoryCache } from 'api/apollo/createInMemoryCache'
import { errorLink } from 'api/apollo/errorLink'
import { resolveDeviceId } from 'functions/localStorage'

import { resolveToken } from './resolveToken'
import { store } from '../configureClientStore'

const httpLink = new HttpLink({
  uri: createApolloUrl(window.__INITIAL_STATE__.systemReducer.locale),
  credentials: 'same-origin',
  headers: createApolloHeaders(
    resolveToken(),
    JSON.stringify(window.API_6_CLIENT),
    resolveDeviceId()
  ),
})

const cache = createInMemoryCache().restore(window.__APOLLO_STATE__)

const client = new ApolloClient({
  cache,
  link: from([
    errorLink(store),
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ['Csrf-Token']: resolveToken(),
        },
      }
    }),
    httpLink,
  ]),
  ssrForceFetchDelay: 150,
})

export { client }
