import { AsyncThunkActionVoid } from 'actions/actions.types'
import { setShouldAppReloadAction } from 'actions/system/checkBundleUpdateAction'
import { isServiceWorkerSupported } from 'functions/pwa/isServiceWorkerSupported'
import { ShellUpdateOptions } from 'functions/pwa/pwa.types'
import { updateShell } from 'functions/pwa/updateShell'

/**
 * Нужно не дать сделать рефреш, пока идет апдейт шелл.
 * @param options
 */
export const updateShellAction =
  (options: ShellUpdateOptions = { reload: false }): AsyncThunkActionVoid =>
  (dispatch) => {
    if (isServiceWorkerSupported()) {
      console.info('Client: update shell with options', options)
      dispatch(setShouldAppReloadAction(false))
      updateShell(options)
    }

    return Promise.resolve()
  }
