import React, { FC } from 'react'

import { indexPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'

import { Replace } from './Replace'

export const UnauthorizedRedirect: FC<{ uid: string }> = ({ uid }) => {
  const locale = useLocale()

  /**
   * Убрал инструкцию перехода к профилю, после logout из профиля
   * Переход к onboarind добавлен в logoutAction
   * https://youtrack.mamba.ru/issue/M-11249
   */

  /** Убрал from, т.к. типизация говорит об ошибки, по-идее не нужно */
  return <Replace to={mergeAllUrls(locale, indexPath)} uid={uid} />
}
