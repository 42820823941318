import { SessionHandler } from '../sessionStorage'

/**
 * Сохраняя в сессионном хранилище историю:
 * 1. храним ее только для конкретной вкладки (не смешиваем с остальными)
 * 2. автоматически чистим при закрытии
 * @param state
 * @param partialState
 */
export const mutatePartialRootStateWithPhotoRatingNextState: SessionHandler = (
  state,
  partialState
) => {
  if (state.photoRatingNext) {
    const {
      currentQueuePhotos: _currentQueuePhotos,
      error: _error,
      rollbackLoading: _rollbackLoading,
      loading: _loading,
      ...rest
    } = state.photoRatingNext

    partialState.photoRatingNext = rest
  }
}
