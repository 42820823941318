import React, { FC } from 'react'

import loadable from '@loadable/component'
import { History } from 'history'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

import { InternalStart } from './InternalStart'

export const LoadableUuid = loadable(() => import('./loadable/ResolveUuid'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('uuid split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})

const ApplicationStart: FC<{
  locale: string
  history: History
}> = ({ history }) => {
  return (
    <>
      <InternalStart history={history} />
      <LoadableUuid />
    </>
  )
}

export default ApplicationStart
