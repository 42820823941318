import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { fetchSettingServicesEnableApi } from 'api/system/settingServicesEnableApi'

export const FETCH_SETTING_SERVICES_ENABLE =
  'FETCH_SETTING_SERVICES_ENABLE' as const

export const listServicesCheckAvailability = [
  'photoline',
  'featured_photos_x3_promo',
]

export const fetchSettingServicesEnableAction = (
  services: string[] = listServicesCheckAvailability,
  headers?: Api6NodeHeaders
) => {
  return {
    type: FETCH_SETTING_SERVICES_ENABLE,
    promise: () => fetchSettingServicesEnableApi(services, headers),
  }
}

export const checkEnabledServicesAndUpdateAction =
  () => async (dispatch: AppDispatchNext, getState: AppGetState) => {
    await dispatch(fetchSettingServicesEnableAction())
    if (
      JSON.stringify(window.__INITIAL_STATE__.systemReducer.services) !==
      JSON.stringify(getState().systemReducer.services)
    ) {
      console.info('Enabled services updated, should update shell.')
      updateShellAction()
    }
  }
