import { LocaleCode } from 'common-constants/locale'
import { EdnaLocales } from 'components/block/SupportEdna/SupportEdna.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { buildQuery } from 'functions/queryString'

import { ednaLocalesPath, internalApiPath } from './internal.constants'

export const fetchEdnaLocalesApi = async (
  locale: LocaleCode
): Promise<EdnaLocales> => {
  const query = buildQuery({ locale })
  const response = await fetch(
    `${mergeAllUrls(internalApiPath, ednaLocalesPath)}?${query}`
  )
  return await response.json()
}
