import { BannerConfig } from 'components/banner/banner.types'

/**
 * Инициализирует систему Яндекс.Рекламы на странице
 *
 * Создает необходимые глобальные объекты в window:
 * - yaContextCb: массив колбэков для Яндекс.Рекламы
 * - yaBannerQueue: очередь баннеров для загрузки
 * - pushBanner: функция для добавления баннеров в очередь
 *
 */
export const setUpYandexAds = () => {
  window.yaContextCb = window.yaContextCb || []

  /**
   * Сразу в loadable, чтобы успеть принять от баннерки.
   */
  window.yaBannerQueue = []

  /**
   * Через функцию, чтобы потом можно было подменить механизм,
   * если понадобится.
   * @param banner
   */
  window.pushBanner = (banner: BannerConfig) => {
    pushBanner(banner)
  }
}

export const pushBanner = (banner: BannerConfig) => {
  return window.yaBannerQueue.push(banner)
}
