import { DEFAULT_LOCALE } from 'common/constants'
import { LOCALES } from 'common-constants/locale'

export const INTERNATIONAL_LOCALE = 'en'
const INTERNATIONAL_LOCALE_ID = 3

export const fixForReactIntl = (locale: string) => {
  if (locale === 'zh-tw') {
    return 'zh'
  }
  return locale
}

const findLocaleByCode = (internalLocale: string | null) =>
  LOCALES.find(({ code }) => code === internalLocale)

export const findLocale = (selectedLocale: string | null) => {
  const localeDescription = findLocaleByCode(selectedLocale)
  if (localeDescription) {
    const { code } = localeDescription
    return code
  }
  return null
}

export const findLocaleWithDefaultFallback = (selectedLocale: string) => {
  const locale = findLocale(selectedLocale)
  if (locale) {
    return locale
  } else {
    console.error(`Locale description not found for "${selectedLocale}"`)
    return DEFAULT_LOCALE
  }
}

export const resolveExternalLocaleId = (internalLocale: string): number => {
  const externalLocale = findLocaleByCode(internalLocale)
  if (externalLocale) {
    return externalLocale.id
  }
  return INTERNATIONAL_LOCALE_ID
}

export const resolveLocale = (locale: string) =>
  locale === DEFAULT_LOCALE ? DEFAULT_LOCALE : INTERNATIONAL_LOCALE
