import { unregisterServiceWorkers } from './unregisterServiceWorkers'

export const uninstallPwa = () => {
  unregisterServiceWorkers()
  deletePwaCache()
  console.info('PWA is uninstalled')
}

const deletePwaCache = async () => {
  if ('caches' in window) {
    const cacheNames = await caches.keys()

    try {
      await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
      console.info('All caches are cleared')
    } catch (error) {
      console.error('Error clearing caches:', error)
    }
  }
}
