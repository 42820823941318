import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { updateCoordinatesApi } from 'api/settings/updateCoordinatesApi'
import { Coordinates } from 'reducers/geolocation/geolocationReducer.types'

export const UPDATE_COORDINATES = 'UPDATE_COORDINATES' as const

export const updateCoordinatesAction =
  (coordinates: Coordinates) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      authorizationReducer: { authorized },
    } = getState()

    return dispatch(updateCoordinatesPlainAction(coordinates, authorized))
  }

export const updateCoordinatesPlainAction = (
  coordinates: Coordinates,
  authorized: boolean
) => ({
  type: UPDATE_COORDINATES,
  promise: () => {
    if (!authorized) {
      // Неавторизованному прилетит 401 code: 'auth'
      return Promise.resolve()
    }

    return updateCoordinatesApi(coordinates.latitude, coordinates.longitude)
  },
  coordinates,
})
