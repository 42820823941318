import { AsyncAction } from 'actions/actions.types'
import { DeleteReasons } from 'components/page/DeleteProfile/DeleteProfile.constants'

export const SELECTED_REASON = 'SELECTED_REASON_DELETE'
export const CLEAR_SELECTED_REASON = 'CLEAR_SELECTED_REASON'
export const DELETE_PROFILE_SEND_PHONE = 'DELETE_PROFILE_SEND_PHONE'

interface SelectedReasonAction {
  type: typeof SELECTED_REASON
  reasonSelected: DeleteReasons
}

export const selectedReasonAction = (reasonSelected: string) => ({
  type: SELECTED_REASON,
  reasonSelected,
})

interface ClearSelectedReasonAction {
  type: typeof CLEAR_SELECTED_REASON
}

export const clearSelectedReasonAction = () => ({
  type: CLEAR_SELECTED_REASON,
})

interface DeleteProfileSendPhoneAction extends AsyncAction {
  type: typeof DELETE_PROFILE_SEND_PHONE
}

export const DELETE_PROFILE_SEND_CODE_SMS = 'DELETE_PROFILE_SEND_CODE_SMS'

export interface DeleteProfileSendCodeSmsAction extends AsyncAction {
  type: typeof DELETE_PROFILE_SEND_CODE_SMS
}

export type SelectedReasonTypes =
  | SelectedReasonAction
  | ClearSelectedReasonAction
  | DeleteProfileSendPhoneAction
  | DeleteProfileSendCodeSmsAction
