import { DEFAULT_LOCALE } from 'common/constants'
import { INTERNATIONAL_LOCALE } from 'functions/locale'

export function parseSearch(query) {
  return query
    .substring(1)
    .split('&')
    .map((param) => {
      const [name, value] = param.split('=')
      return { name, value }
    })
    .reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
}

/**
 * @deprecated src/common/functions/capitalizeFirstLetter.ts
 */
export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.substr(1)
}

export function resolveOsType(userAgentRaw = '') {
  const userAgent = userAgentRaw.toLowerCase()
  if (userAgent.indexOf('android') > -1) {
    return 'android'
  }
  if (/ipad|iphone|ipod/.test(userAgent)) {
    return 'ios'
  }
  return 'default'
}

const windowsXpRegExp = new RegExp(/^Mozilla\/.*Windows NT 5\.[12](.)*/)

export const resolveIfWindowsXp = (userAgentRaw = '') =>
  windowsXpRegExp.test(userAgentRaw)

export async function sendApiBtp(promise, _api, _url, _options) {
  if (!process.env.browser) {
    // TODO все равно не смотрим этот график + переведем на Сентри мониторинг запросов
    // return (await import('../../server/btp')).sendBtpApiHttpRequest(
    //   promise,
    //   api,
    //   url,
    //   options
    // )
  }
  return promise
}

export function checkIE(userAgentRaw = '') {
  const userAgent = userAgentRaw.toLowerCase()
  const msie = userAgent.indexOf('msie')
  return msie > 0 || !!userAgent.match(/trident.*rv\:11\./)
}

export const findCorporateSiteLocale = (locale: string): string =>
  locale === DEFAULT_LOCALE ? locale : INTERNATIONAL_LOCALE
