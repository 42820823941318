export interface MetaDataState {
  title: string | null
  header: string | null
  description: string | null
  metaRobots: string | null
  canonicalUrl: string | null
  /** Нашли при запросе или проставили в ручную */
  hasInfo: boolean
  updateTitleNewMessage: boolean
}

export const initialState: MetaDataState = {
  title: null,
  header: null,
  description: null,
  metaRobots: null,
  canonicalUrl: null,
  hasInfo: false,
  updateTitleNewMessage: false,
}
