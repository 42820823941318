import { AsyncAction, AsyncThunkActionVoid } from 'actions/actions.types'
import { setCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import { phoneVerificationCodeResendApi } from 'api/phoneVerification/phoneVerificationCodeResendApi'

export const PHONE_VERIFICATION_CODE_RESEND =
  'PHONE_VERIFICATION_CODE_RESEND' as const

type Response = Awaited<
  ReturnType<typeof phoneVerificationCodeResendApi>
>['result']

export interface PhoneVerificationCodeResendAction
  extends AsyncAction<Response> {
  type: typeof PHONE_VERIFICATION_CODE_RESEND
}

export const phoneVerificationCodeResendAction =
  (): AsyncThunkActionVoid => (dispatch) => {
    dispatch({
      type: PHONE_VERIFICATION_CODE_RESEND,
      promise: async () => {
        const json = await phoneVerificationCodeResendApi()

        if (json.result?.timer) {
          dispatch(setCounterDownAction(json.result.timer))
        }

        return json
      },
    })
  }
