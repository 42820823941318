import { captureException, setTag } from '@sentry/react'
import { detectIncognito } from 'detectincognitojs'

import { updateQueryAction } from 'actions/system/updateQueryAction'
import { AppStore } from 'common/store/store.types'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'

const safelyDetectIncognito = async () => {
  window.__PRIVATE__ = false
  try {
    const { isPrivate } = await detectIncognito()

    if (isPrivate) {
      window.__PRIVATE__ = true
    }
  } catch (error) {
    captureException(error, {
      tags: {
        section: 'pwa-incognito',
      },
    })
  }

  return window.__PRIVATE__
}

export const detectIncognitoAndPwa = async (store: AppStore) => {
  const incognito = await safelyDetectIncognito()
  const pwaSupported = isPwaSupported()

  setTag('pwa', pwaSupported ? 'yes' : 'no')
  setTag('country', store.getState().userReducer.countryId)
  setTag('partner', store.getState().systemReducer.partnerId)
  setTag(
    'authorized',
    store.getState().authorizationReducer.authorized ? 'yes' : 'no'
  )
  setTag('incognito', incognito ? 'yes' : 'no')

  if (pwaSupported) {
    store.dispatch(updateQueryAction())
  }
}
