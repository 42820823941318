import React, { FC } from 'react'

import { PhotoRatingLoadable } from 'components/page/PhotoRating/PhotoRatingLoadable'
import { RatingWaveLoadable } from 'components/page/RatingWave/RatingWaveLoadable'
import { useRedesignLayout } from 'hooks/useRedesign'

export const RatingSwitch: FC = () => {
  const redesignLayout = useRedesignLayout()

  if (redesignLayout) {
    return <RatingWaveLoadable />
  }

  return <PhotoRatingLoadable />
}
