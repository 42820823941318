import { ApiResult, postApi as newPostApi } from 'api/fetchApi'
import { definitions, paths } from 'api/generated/seo'
import { NodeHeaders } from 'api/NodeHeaders'
import {
  ProfileStatus,
  statusNamesParameter,
} from 'api/profile/profile.functions'
import { Cursor } from 'api/search/search.types'
import { buildQuery } from 'functions/queryString'

import { fetchApi } from './index'
import { MAMBA_FEATURES } from '../constants'

const parseCursor = (cursorObject: Cursor) => {
  if (cursorObject) {
    return Object.keys(cursorObject).reduce((result, key) => {
      result[`cursor[${key}]`] = cursorObject[key]
      return result
    }, {})
  }
}

export const resetSearchSettingsApi = () =>
  fetchApi('/search/normalizeOptions', {
    method: 'POST',
    [MAMBA_FEATURES]: true,
  } as RequestInit)

export type SeoSearchPath = paths['/seo/search']

export const landingSearchApi = (
  searchRequest: definitions['SeoSearchPostBodyRequest'],
  locale?: string,
  headers?: NodeHeaders
): Promise<ApiResult<SeoSearchPath['post']['responses']['200']['schema']>> =>
  newPostApi<
    SeoSearchPath['post']['responses']['200']['schema'],
    SeoSearchPath['post']['parameters']['body']['body']
  >(
    '/seo/search',
    {
      ...searchRequest,
    },
    {
      headers,
    }
  )

export const simpleSearchApi = (
  limit: number,
  cursor: Cursor,
  headers = {}
) => {
  const parsedCursor = parseCursor(cursor)

  const queryString = buildQuery({
    [statusNamesParameter]: [
      ProfileStatus.VerifiedPhoto,
      ProfileStatus.SpaceTimeLocation,
      ProfileStatus.Online,
    ].join(','),
    limit,
    ...parsedCursor,
  })

  return fetchApi(`/search?${queryString}`, { headers })
}
