import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

export const UPDATE_IS_MOBILE_VIEW = 'UPDATE_IS_MOBILE_VIEW' as const

export const updateIsMobileViewAction = (mobile: boolean) => {
  return (dispatch: AppDispatchNext, getState: AppGetState) => {
    if (getState().systemView.mobile === mobile) {
      return
    }

    dispatch({
      type: UPDATE_IS_MOBILE_VIEW,
      mobile,
    })
  }
}

export const updateIsMobileViewPlainAction = (mobile: boolean) => ({
  type: UPDATE_IS_MOBILE_VIEW,
  mobile,
})
