import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const PwaSettingsLoadable = loadable(() => import('./PwaSettings'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('PWA settings split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
