import React, { FC } from 'react'

import { useRouteMatch } from 'react-router'

import { ModalLayoutIndex } from 'components/layout/ModalLayout/ModalLayoutIndex'
import { photoRatingSettingsPath } from 'components/page/PhotoRatingSettings/PhotoRatingSettings.paths'
import { searchSettingsPath } from 'components/page/Search/Search.paths'
import { requestGeoPermissionPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { GeoPermissionRequest } from './GeoPermissionRequest'

export const GeoPermissionRequestModal: FC = () => {
  const math = useRouteMatch()

  if (pathsToExclude.includes(math.path)) {
    return null
  }

  return (
    <ModalLayoutIndex>
      <GeoPermissionRequest
        // Хардкод.
        // На данный момент, этот модал можно открыть только на странице профиля
        pageName="profile"
      />
    </ModalLayoutIndex>
  )
}

// На страницах знакомств и поиска открывается своя страница.
const pathsToExclude = [
  mergeAllUrls(photoRatingSettingsPath, requestGeoPermissionPath),
  mergeAllUrls(searchSettingsPath, requestGeoPermissionPath),
]
