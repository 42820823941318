export function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export function concatLocationName(location) {
  return [location.cityName, location.regionName, location.countryName]
    .filter((name) => name)
    .join(', ')
}

export const mostExactLocationName = (location) => {
  return location.cityName || location.regionName || location.countryName
}

export function addStyleOverflowToBody() {
  document.body.style.overflow = 'hidden'
}

export function removeStyleOverflowFromBody() {
  document.body.style.overflow = null
}

export const isArray = (element) => Array.isArray(element)

export function compareArrayElement(currentArray, defaultArray) {
  const sortArray = currentArray.sort()
  const defaultSortArray = defaultArray.sort()
  if (defaultArray.length === sortArray.length) {
    return defaultSortArray.every(
      (element, index) => element === sortArray[index]
    )
  } else {
    return false
  }
}
