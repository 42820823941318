import { useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'

import { fetchAbTestsGroupListAction } from 'actions/abTestAction'
import { updateLastAuthMethodAction } from 'actions/authorization/updateLastAuthMethodAction'
import { fetchIsAuthorizationMailruAction } from 'actions/authorizationAction'
import { fetchBannersAvailableAction } from 'actions/banner/bannerAction'
import { fetchCurrentLocationAction } from 'actions/location/fetchCurrentLocationAction'
import { isEnabledSoundNotificationAction } from 'actions/settings/isEnabledSoundNotificationAction'
import { fetchFeaturesAction } from 'actions/system/fetchFeaturesAction'
import { fetchSettingServicesEnableAction } from 'actions/system/fetchSettingServicesEnableAction'
import { toggleAnimationsDisabled } from 'actions/system/toggleAnimationsDisabled'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { ACTIVE_AB_TESTS } from 'api/abTestApi'
import { isLoveMailru } from 'common/constants'
import { BrowserList, OperationSystem } from 'common-constants/bowser'
import { isCheesyDesktopSafariVersion } from 'functions/isCheesyDesktopSafari'
import { isPreferReducedMotion } from 'functions/isPreferReducedMotion'
import { unregisterFirebaseMessagingServiceWorker } from 'functions/pwa/unregisterServiceWorkers'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { usePartner } from 'hooks/usePartner'
import { usePrevious } from 'hooks/usePrevious'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import {
  ANIMATIONS_OFF_ATTRIBUTE_KEY,
  ANIMATIONS_OFF_ATTRIBUTE_VALUE,
} from './designSystem/styles/applyIfAnimationsEnabledCss'

export const useDispatchInitialActions = () => {
  const dispatch = useDispatch()
  const { partnerId } = usePartner()

  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  useEffect(() => {
    dispatch(fetchCurrentLocationAction())

    if (authorized) {
      dispatch(isEnabledSoundNotificationAction())
      dispatch(fetchSettingServicesEnableAction())
    }
  }, [authorized, dispatch, partnerId])
}

export const useDispatchAfterAuthorizationActions = () => {
  const dispatch = useDispatch()
  const { partnerId } = usePartner()

  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  const prevAuthorized = usePrevious(authorized)

  useEffect(() => {
    if (!prevAuthorized && authorized) {
      dispatch(fetchAbTestsGroupListAction(ACTIVE_AB_TESTS))
      dispatch(fetchFeaturesAction())
      if (isLoveMailru(partnerId)) {
        dispatch(fetchIsAuthorizationMailruAction())
      }
    }
  }, [authorized, dispatch, partnerId, prevAuthorized])
}

/**
 * Принудительно применяет полифил для десктопного сафари.
 * Например на версии 15.4 scroll({ behavior: 'smooth' }) вообще не скроллит https://redmine.mamba.ru/issues/118289
 */
export const useSmoothScrollPolyfillForCheesyDesktopSafari = () => {
  const { isSafari, browserVersion } = useShallowEqualSelector(
    ({ systemReducer: { isSafari, browserVersion } }) => ({
      isSafari,
      browserVersion,
    })
  )

  const isDesktopSafari = useRef(Boolean(isSafari))

  useEffect(() => {
    if (!isDesktopSafari.current || !browserVersion) {
      return
    }

    if (!isCheesyDesktopSafariVersion(browserVersion)) {
      return
    }

    ;(async () => {
      const smoothscroll = await import('smoothscroll-polyfill')
      // @ts-expect-error
      window.__forceSmoothScrollPolyfill__ = true
      smoothscroll.polyfill()
    })()
  }, [browserVersion])
}

/**
 * Нужно, чтобы показать/скрыть рекламу, после смены VIP-статуса
 */
export const useAfterVipActivated = () => {
  const dispatch = useDispatch()
  const { vip } = useShallowEqualSelector(({ userReducer: { vip } }) => ({
    vip,
  }))

  const prevVip = usePrevious(vip)

  useEffect(() => {
    if (prevVip !== vip) {
      dispatch(fetchBannersAvailableAction())
    }
  }, [dispatch, prevVip, vip])
}

export const useUnregisterFirebaseMessagingServiceWorkerIfNeeded = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  useEffect(() => {
    if (!authorized) {
      unregisterFirebaseMessagingServiceWorker()
    }
  }, [authorized])
}

export const useFetchMiniOnProfileRestored = () => {
  const dispatch = useDispatch()

  const { profileRemoved } = useShallowEqualSelector(
    ({ errorReducer: { profileRemoved } }) => ({
      profileRemoved,
    })
  )

  const prevProfileRemoved = usePrevious(profileRemoved)

  useEffect(() => {
    if (prevProfileRemoved && !profileRemoved) {
      dispatch(fetchMiniIfNeededAction())
    }
  }, [dispatch, prevProfileRemoved, profileRemoved])
}

/** Обновляет метод авторизации. Нужно для отображения "Предыдущий способ входа" на стартовой странице  */
export const useUpdateLastAuthMethod = () => {
  const dispatch = useAppDispatch()
  const redesign = useRedesign()

  const { lastClickedAuthVendor, authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { lastClickedAuthVendor, authorized } }) => ({
      lastClickedAuthVendor,
      authorized,
    })
  )

  const prevAuthorized = usePrevious(authorized)

  useEffect(() => {
    if (!redesign) {
      return
    }

    if (!prevAuthorized && authorized && lastClickedAuthVendor) {
      dispatch(updateLastAuthMethodAction(lastClickedAuthVendor))
    }
  }, [dispatch, authorized, lastClickedAuthVendor, prevAuthorized, redesign])
}

/**
 * Отвечает за обновление флага systemReducer.animationsDisabled
 */
export const useUpdateAnimationsDisabled = () => {
  const dispatch = useAppDispatch()
  const { browserName, osName } = useShallowEqualSelector(
    ({ systemReducer: { browserName, osName } }) => ({
      browserName,
      osName,
    })
  )

  useEffect(() => {
    const prefersReducedMotion = isPreferReducedMotion()
    // В Firefox плохая производительность анимаций на windows и android.
    // На MacOS и iOS все ок.
    const firefox =
      browserName === BrowserList.FireFox &&
      (osName === OperationSystem.Android || osName === OperationSystem.Windows)

    const animationsDisabled = prefersReducedMotion || firefox

    if (animationsDisabled) {
      // Обновим флаг в redux store, чтобы обращаться к нему в js
      dispatch(toggleAnimationsDisabled(animationsDisabled))
      // Повесим аттрибут на html, чтобы обращаться к нему в css
      document.documentElement.setAttribute(
        ANIMATIONS_OFF_ATTRIBUTE_KEY,
        ANIMATIONS_OFF_ATTRIBUTE_VALUE
      )
    }
  }, [browserName, dispatch, osName])
}
