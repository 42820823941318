import { fetchEdnaInformationApi } from 'api/edna/fetchEdnaInformationApi'
import { ApiResult } from 'api/fetchApi'
import { fetchEdnaLocalesApi } from 'api/internal/fetchEdnaLocalesApi'
import { LocaleCode } from 'common-constants/locale'
import { SupportEdnaInformation } from 'reducers/user/UserState'

export const FETCH_EDNA_INFORMATION = 'FETCH_EDNA_INFORMATION' as const

export const fetchEdnaInformationAction = (locale: LocaleCode) => ({
  type: FETCH_EDNA_INFORMATION,
  promise: async () => {
    const [ednaResponse, localeResponse] = await Promise.all([
      fetchEdnaInformationApi(),
      fetchEdnaLocalesApi(locale),
    ])

    return Promise.resolve<ApiResult<SupportEdnaInformation>>({
      result: { ...ednaResponse.result, locales: localeResponse },
      headers: {} as Headers,
      ok: true,
    } as ApiResult<SupportEdnaInformation>)
  },
})

export const RESET_EDNA_INFORMATION = 'RESET_EDNA_INFORMATION' as const

export const resetEdnaInformationAction = () => ({
  type: RESET_EDNA_INFORMATION,
})
