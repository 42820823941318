import { AsyncAction, AsyncThunkAction } from 'actions/types'
import { CometMessage } from 'api/comet/comet.types'
import { fetchWebSocketUrlApi } from 'api/cometApi'
import { CometMethod } from 'common-constants/comet'
import { AppChannel } from 'components/system/AppChannel'

export const OPEN_WEB_SOCKET_CONNECTION = 'OPEN_WEB_SOCKET_CONNECTION'
export const FETCH_WEB_SOCKET_URL = 'FETCH_WEB_SOCKET_URL' as const
export const CLOSE_WEB_SOCKET_CONNECTION = 'CLOSE_WEB_SOCKET_CONNECTION'
export const CLOSE_SOCKET = 'CLOSE_SOCKET'
export const PUSH_SOCKET_MESSAGE = 'PUSH_SOCKET_MESSAGE'
export const SHIFT_SOCKET_MESSAGE = 'SHIFT_SOCKET_MESSAGE'
export const NEW_SOCKET = 'NEW_SOCKET'
export const SET_NEW_MESSAGE_CURSOR = 'SET_NEW_MESSAGE_CURSOR'

interface NewSocketAction {
  type: typeof NEW_SOCKET
}

export const newSocketAction = () => ({ type: NEW_SOCKET })

export const fetchWebSocketUrlAction = () => ({
  type: FETCH_WEB_SOCKET_URL,
  promise: () => fetchWebSocketUrlApi(),
})

interface OpenWebSocketConnectionAction {
  type: typeof OPEN_WEB_SOCKET_CONNECTION
}

export const openWebSocketConnectionAction = () => ({
  type: OPEN_WEB_SOCKET_CONNECTION,
})

interface CloseWebSocketConnectionAction {
  type: typeof CLOSE_WEB_SOCKET_CONNECTION
}

export const closeWebSocketConnectionAction = () => ({
  type: CLOSE_WEB_SOCKET_CONNECTION,
})

interface CloseSocketAction {
  type: typeof CLOSE_SOCKET
}

export const closeSocketAction = () => ({
  type: CLOSE_SOCKET,
})

export const subscribeChannelsAction =
  (channels: AppChannel[]): AsyncThunkAction =>
  (dispatch) => {
    const subscribeMessage = {
      method: CometMethod.subscribe,
      params: channels,
    }
    dispatch(pushSocketMessageAction(subscribeMessage))
  }

export const unsubscribeChannelsAction =
  (channels: AppChannel[]): AsyncThunkAction =>
  (dispatch) => {
    const unsubscribeMessage = {
      method: CometMethod.unsubscribe,
      params: channels,
    }
    dispatch(pushSocketMessageAction(unsubscribeMessage))
  }

interface PushSocketMessageAction {
  type: typeof PUSH_SOCKET_MESSAGE
  payload: {
    message: string
  }
}

export const pushSocketMessageAction = (message: CometMessage) => {
  return {
    type: PUSH_SOCKET_MESSAGE,
    payload: {
      message: JSON.stringify(message),
    },
  }
}

interface ShiftSocketMessageAction {
  type: typeof SHIFT_SOCKET_MESSAGE
}

export const shiftSocketMessageAction =
  (socket: WebSocket): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      webSocket: { messagesQueue },
    } = getState()
    if (messagesQueue.length > 0) {
      const [message] = messagesQueue
      socket.send(message)
      dispatch({
        type: SHIFT_SOCKET_MESSAGE,
      })
    }
  }

interface SetNewMessageCursorAction extends AsyncAction {
  type: typeof SET_NEW_MESSAGE_CURSOR
  cursor: number
}

export const setNewMessageCursorAction =
  (cursor: number): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      webSocket: { cursorNewMessage },
    } = getState()
    if (cursorNewMessage !== cursor) {
      dispatch({
        type: SET_NEW_MESSAGE_CURSOR,
        cursor,
      })
    }
  }

export type WebSocketActionTypes =
  | SetNewMessageCursorAction
  | ShiftSocketMessageAction
  | PushSocketMessageAction
  | CloseSocketAction
  | CloseWebSocketConnectionAction
  | OpenWebSocketConnectionAction
  | NewSocketAction
  | ReturnType<typeof fetchWebSocketUrlAction>
