import { AsyncThunkAction } from 'actions/actions.types'
import { fetchNotificationsPermissionStatus } from 'api/browser/fetchPermissionStatus'

export const UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS =
  'UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS' as const

export interface UpdatePushNotificationsPermissionStatusAction {
  type: typeof UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS
  value: PermissionState | undefined
}

export const updatePushNotificationsPermissionStatusAction =
  (): AsyncThunkAction => async (dispatch) => {
    const permissionStatus = await fetchNotificationsPermissionStatus()

    dispatch<UpdatePushNotificationsPermissionStatusAction>({
      type: UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS,
      value: permissionStatus?.state,
    })
  }
