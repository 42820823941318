export const ltrDirection = 'ltr'
export const rtlDirection = 'rtl'

type DirectionType = typeof ltrDirection | typeof rtlDirection
interface Locale {
  id: number
  code: string
  name: string
  localeFile?: string
  direction?: DirectionType
}

export const LOCALES: Locale[] = [
  { code: 'en', name: 'English', id: 3 },
  { code: 'de', name: 'Deutsch', id: 5 },
  { code: 'fr', name: 'Français', id: 6 },
  { code: 'es', name: 'Español', id: 7 },
  { code: 'it', name: 'Italiano', id: 8 },
  { code: 'he', name: 'עִבְרִית', direction: rtlDirection, id: 9 },
  { code: 'ru', name: 'Русский', id: 2 },
  { code: 'zh', name: 'Chinese Simplified', id: 10 },
  { code: 'ar', name: 'العربية', direction: rtlDirection, id: 14 },
  { code: 'bg', name: 'Български', id: 17 },
  { code: 'el', name: 'Ελληνικά', id: 21 },
  { code: 'fi', name: 'Suomi', id: 24 },
  { code: 'hi', name: 'हिंदी', id: 26 },
  { code: 'ja', name: '日本語', id: 30 },
  { code: 'pl', name: 'Polski', id: 36 },
  { code: 'pt', name: 'Português', id: 37 },
  { code: 'ro', name: 'Română', id: 38 },
  { code: 'sr', name: 'Српски', id: 42 },
  { code: 'sv', name: 'Svenska', id: 43 },
  { code: 'tr', name: 'Türkçe', id: 44 },
  { code: 'id', name: 'Bahasa Indonesia', id: 60 },
  { code: 'ko', name: '한국어', id: 63 },
  { code: 'ms', name: 'Bahasa Melayu', id: 72 },
  { code: 'th', name: 'ไทย', id: 87 },
  { code: 'vi', name: 'Tiếng Việt', id: 88 },
  {
    code: 'zh-tw',
    name: 'Chinese Traditional',
    localeFile: 'zh',
    id: 93,
  },
  { code: 'fa', name: 'فارسی', /* Persian */ id: 23, direction: rtlDirection },
  { code: 'kk', name: 'Қазақша', id: 32 },
]

export type LocaleCode = typeof LOCALES[number]['code']

export const LOCALES_REGEXP = LOCALES.reduce<string[]>(
  (acc, { code }) => [...acc, code],
  []
)
  // Сортировка и реверс нужны для того чтобы поднять zh-tw выше zh и другие
  // потенциальные схожие значения
  .sort()
  .reverse()
  .filter((value) => value)
  .join('|')
