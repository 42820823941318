import { eventListWithFilterPath } from 'components/page/EventList/EventList.paths'
import { ratingPath } from 'components/page/RatingWave/Rating.paths'
import { searchPath } from 'components/page/Search/Search.paths'
import { profileSearchFullPath } from 'components/page/Search/SearchProfile/SearchProfile.paths'
import {
  contactListPath,
  chatPath,
  contactWithoutContactIdPath,
  userProfilePath,
  commonFolderContactPath,
} from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const INCLUDED_PATHS_IN_AB_TEST = [
  ratingPath,
  searchPath,
  contactWithoutContactIdPath,
  commonFolderContactPath,
  contactListPath,
  chatPath,
  userProfilePath,
  eventListWithFilterPath,
  mergeAllUrls(userProfilePath, profileSearchFullPath),
]
