import { fetchQueryApi } from 'api/fetchApi'
import { allPaths } from 'api/fetchApiPaths'

export enum SnippetType {
  secondary = 'secondary',
}

type Response = {
  id: string
  url: string
  content: string
  type: SnippetType
}[]

export const fetchSnippetContentApi = (url: string) => {
  return fetchQueryApi<Response, { url: string }>(
    '/snippet/content-hierarchically' as allPaths,
    {
      url,
    }
  )
}
