import { matchPath } from 'react-router'

import { TimeRange } from 'common-constants/timeRange'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import { vipBeelinePath } from 'components/page/VipBeeline/VipBeeline.paths'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { findMatchByPath } from 'functions/findMatch'
import { getKeyValue, setKeyValue } from 'functions/localStorage'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { setSessionKeyValue } from 'functions/sessionStorage'
import { INCLUDED_PATHS_IN_AB_TEST } from 'hooks/useShowPhotoUploader/useShowPhotoUploader.constants'

export const isRouteIncludedInAbTest = (currentPath: string) => {
  // Исключения
  if (
    currentPath.includes(noticePath) || // Чтобы не перетереть нотис попрошайкой
    currentPath.includes(vipBeelinePath) // Если открыт модал активации VIP по промокоду через билайн
  ) {
    return false
  }

  for (const path of INCLUDED_PATHS_IN_AB_TEST) {
    const match = matchPath(currentPath, {
      exact: true,
      path,
    })

    if (match) {
      return true
    }
  }

  return false
}

const photoUploadedDateAbTestKey = 'photoUploadedDateAbTest'

export const onPhotoUploaded = () => {
  setSessionKeyValue(photoUploadedDateAbTestKey, String(Date.now()))
}

export const nextTimeShowPhotouploaderKey = 'nextTimeShowPhotouploader'

export const setNextTimeToShowPhotoUploader = (
  userId: number,
  delayInSeconds: number
) => {
  setKeyValue(
    nextTimeShowPhotouploaderKey,
    JSON.stringify({
      userId,
      nextShowTime: Date.now() + delayInSeconds * TimeRange.second,
    })
  )
}

export const getNextTimeToShowPhotoUploader = (userId: number) => {
  try {
    const record = getKeyValue(nextTimeShowPhotouploaderKey)

    if (!record) {
      return
    }

    const { userId: storedUserId, nextShowTime } = JSON.parse(
      JSON.parse(record)
    ) as {
      nextShowTime: number
      userId: number
    }

    if (userId !== storedUserId) {
      return
    }

    return nextShowTime
  } catch (error) {}
}

export const isToSoonToShowPhotoUploader = (userId: number) => {
  const nextShowTime = getNextTimeToShowPhotoUploader(userId)

  if (!nextShowTime) {
    return false
  }

  return nextShowTime > Date.now()
}

export const findMatchUploader = (pathname: string, baseUrl: string) => {
  return findMatchByPath(
    mergeAllUrls(baseUrl, uploadPhotosPath, albumPath),
    pathname,
    false
  )
}
