import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const YandexMetrikaLoadable = loadable(() => import('./YandexMetrika'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('YandexMetrika split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
