import { AsyncThunkAction } from 'actions/actions.types'
import { MetricEventSend } from 'components/metric/metric.types'

export const METRIC_USER_REGISTERED = 'METRIC_USER_REGISTERED'

export interface MetricRegisteredAction {
  type: typeof METRIC_USER_REGISTERED
}

export const metricRegisteredAction = () => ({
  type: METRIC_USER_REGISTERED,
})

export const START_VERIFICATION = 'START_VERIFICATION'

export interface StartVerificationAction {
  type: typeof START_VERIFICATION
}

export const startVerificationAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    if (getState().metric?.isNewUser) {
      return dispatch({
        type: START_VERIFICATION,
      })
    } else {
      return Promise.resolve()
    }
  }

export const ALREADY_SENT_EVENT = 'ALREADY_SENT_EVENT'

export interface SentEventAction {
  type: typeof ALREADY_SENT_EVENT
  event: MetricEventSend
}

export const sentEventAction = (event: MetricEventSend) => ({
  type: ALREADY_SENT_EVENT,
  event,
})

export const USER_CREATED_CONTACT = 'USER_CREATED_CONTACT'

export interface CreateContactAction {
  type: typeof USER_CREATED_CONTACT
}

export const createContactAction = () => ({
  type: USER_CREATED_CONTACT,
})

export const RESET_DEFAULT_EVENTS = 'RESET_DEFAULT_EVENTS'

export interface ResetDefaultAction {
  type: typeof RESET_DEFAULT_EVENTS
}

export const resetDefaultAction = () => ({
  type: RESET_DEFAULT_EVENTS,
})

export const RESET_PAYMENT_EVENT = 'RESET_PAYMENT_EVENT'

export interface ResetPaymentEventAction {
  type: typeof RESET_PAYMENT_EVENT
}

export const resetPaymentEventAction = () => ({
  type: RESET_PAYMENT_EVENT,
})

export type MetricTypes =
  | MetricRegisteredAction
  | StartVerificationAction
  | SentEventAction
  | CreateContactAction
  | ResetPaymentEventAction
  | ResetDefaultAction
