import { defineMessages } from 'react-intl'

import { Colors, mailuColor } from 'common-constants/colors'
import { hexToRgbA } from 'functions/hexToRgbA'

export const STATE = 'state'
export const DOMAIN_NAME = 'mamba.ru'
export const DEFAULT_LOCALE = 'ru'
export const DEFAULT_LOCALE_ID = 2
export const MAMBA_FEATURES = 'Mamba-Features'

export const KEY_CODE = {
  ENTER: 13,
  SHIFT: 16,
  ESCAPE: 27,
  SPACE: 32,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
}
export const imagesCdnUrl = 'https://images.wambacdn.net/images'

export const videoGestureCdnUrl = 'https://images.wambacdn.net/upload/gestures'

export const fontFamily = 'Arial, sans-serif'
export const whoCanFindStatusList = ['nobody', 'all', 'auth']

export const appBannerPopUpRulesCookie = 'appBannerPopUpRulesCookie'
export const sPostCookie = 's_post'

export const noticePath = '/notice'

export const favoriteService = 'favorite'

export const modalDesktopMaxWidth = 380
export const photoStreamBorderRadius = 10

export const giftsServiceType = 'gifts'
export const photoLineServiceType = 'photoline'
export const activityType = 'activity'
// For get user Info API5
export const aboutmeType = 'aboutme'
export const voteSourcePhotoRating = 1
export const voteSourcePhotoViewer = 2

export const mailruDomain = [
  '@mail.ru',
  '@inbox.ru',
  '@list.ru',
  '@bk.ru',
  '@mail.ua',
]
export const wwwMamba = 3
export const mambaLite = 1733384318
export const huaweiMamba = 1733381786
export const wambaLite = 1733385612
export const mambaPartnerIds = [wwwMamba, 81449119]
export const wambaPartnerIds = [958134831, 963463432]
export const yonjaPartnerIds = [1374393173, 1374393182]
export const mailruPartnerIds = [475306, 132113273]
export const mainPartnerIds = [
  ...mambaPartnerIds,
  ...wambaPartnerIds,
  ...yonjaPartnerIds,
  ...mailruPartnerIds,
]
export const mheartPartnerId = 25491
export const deleteProfile = 'DeleteProfile'
export const doNotUseHistoryBack = 'backUrl'

export const months = Array(12)
  .fill(null)
  .map((_value, index) => index + 1)

const getAvailableYears = () => {
  const currentYear = new Date().getFullYear()
  return [...Array(201)].map((value, index) => currentYear - 201 + index + 1)
}

export const minimumAge = 18
export const maximumAge = 80
export const availableYears = getAvailableYears().reverse()
export const availableAges = new Array<number>(maximumAge - minimumAge + 1)
  .fill(0)
  .map((age, index) => age + index + 18)

export const availableHeight = new Array<number>(71)
  .fill(0)
  .map((height, index) => height + index + 150)

export const availableWeight = new Array<number>(121)
  .fill(0)
  .map((height, index) => height + index + 40)

export function isLoveMailru(partnerId: number) {
  if (!partnerId) {
    return false
  }
  return mailruPartnerIds.indexOf(partnerId) > -1
}

export function isYonja(partnerId: number) {
  return yonjaPartnerIds.indexOf(partnerId) > -1
}

export function isMamba(partnerId: number) {
  if (!partnerId) {
    return false
  }
  return mambaPartnerIds.indexOf(partnerId) > -1
}

export const isMambaLite = (partnerId: number) => {
  return [mambaLite].indexOf(partnerId) > -1
}

export function isWamba(partnerId: number) {
  return wambaPartnerIds.indexOf(partnerId) > -1
}

export function isMheart(partnerId: number) {
  return mheartPartnerId === partnerId
}
export function isHasSubscribe(subscribe) {
  if (Boolean(subscribe)) {
    const webSubscriptions = subscribe.vip.filter((subscription) => {
      return (
        subscription.type !== 'GooglePlay' && subscription.type !== 'AppStore'
      )
    })

    return Boolean(webSubscriptions.length)
  }

  return false
}

export const settingPartner = {
  mailru: {
    color: mailuColor,
    shadow: `2px 1px 8px 1px ${hexToRgbA(Colors.black, 0.5)}`,
    $linkColor: Colors.white,
    $linkHoverColor: Colors.white,
    $linkSelectedColor: Colors.white,
    $backgroundHoverColor: hexToRgbA(Colors.black, 0.12),
    $backgroundSelectedColor: hexToRgbA(Colors.black, 0.24),
  },
}

export function hasWebSocketSupport() {
  return process.env.browser && 'WebSocket' in window
}

export const noop = function () {}

export const notification = 'notification'
export const shortcut = 'shortcut'
export const displayContainer = 'container'
export const displayInline = 'inline'
export const primaryUniNoticeType = 'primary-btn' // оранжевая кнопка с белым текстом;
export const secondaryUniNoticeType = 'secondary-btn' // голубая кнопка с белым текстом.
export const primaryUniNoticeLinkType = 'primary-link' // оранжевая ссылка;
export const secondaryUniNoticeLinkType = 'secondary-link' // голубая ссылка.

export const uniwebFeaturesFlags = {
  photoline: 'isNewPhotolineLayer2',
  maketop: 'isNewMaketopLayer2',
  gifts: 'isNewGiftsLayer2',
  featuredPhotos: 'isNewFeaturedPhotos2',
}

export const PhotoStatusOverflowAlbums = 'waiting' // TODO проблема в том что в статусах тоже может прийти pending их надо различать

export const MAX_SIZE_PHOTO_VIEWER_MOBILE = '100%'

export const TOUCH_DIFF_SHIFT_Y = 220
export const parameterMainPage = 'Main'
export const parameterUserPage = 'Anketa'
/**
 * Нужно перейти на другие flow для загрузки фото, в том числе и для контакта.
 */
export enum SocialNetworkForUploadPhotos {
  facebook = 'fb',
  vkConnect = 'vkConnect',
  vkontakte = 'vk',
  odnoklassniki = 'ok',
}

export const socialCdnDomain = 'social.wambacdn.net'

export const hitParametersUrl = 'hit'
export const locationField = 'location'

export enum ProfileHit {
  PHOTOLINE_HIT = 1,
  CONTACT_HIT = 3,
  GIFT_HIT = 5,
  HITLIST_HIT = 7,
  SEARCH_HIT = 35,
  COMMENT_HIT = 8,
}

export const configForIntl: Record<string, number> = {
  Chrome: 23,
  Edge: 11,
  Firefox: 28,
  'Internet Explorer': 10,
  Opera: 15,
  Safari: 9,
  'Yandex Browser': 19,
}

export const typeDevice = {
  videoDeviceInput: 'videoinput',
  audioDeviceInput: 'audioinput',
  audioDeviceOutput: 'audiooutput',
}

export const Russia = 'russia'

export const POPULAR = 'popular'
export const NEAR = 'near'
export const NEW = 'new'

export const contactHeaderTop = '40px'
export const contactHeaderMailRuMobileTop = '88px'

export const base64PngStart = 'data:image/jpg;base64,'

export const NUMBER_CONVERTED_KG_TO_POUNDS = 2.20462262

export const FROM_WINK_ROUTER_STATE_KEY = 'fromWink'

export const lgbtMessages = defineMessages({
  searchAll: { id: 'search.settings.search.all', defaultMessage: 'Всех' },
  save: {
    id: 'interest.save',
    defaultMessage: 'Сохранить',
  },
  searchFor: {
    id: 'search.for',
    defaultMessage: 'Ищу',
  },
  genderMMan: { id: 'search.settings.gender.M.one', defaultMessage: 'Мужчину' },
  genderMFriend: {
    id: 'app.friend',
    defaultMessage: 'Друг',
  },
  genderFGirl: {
    id: 'app.girl',
    defaultMessage: 'Девушка',
  },
  genderMGuy: {
    id: 'app.guy',
    defaultMessage: 'Парень',
  },
  genderFFriend: {
    id: 'app.female_friend',
    defaultMessage: 'Подруга',
  },
  genderM: { id: 'search.settings.gender.M', defaultMessage: 'Мужчины' },
  genderF: { id: 'search.settings.gender.F', defaultMessage: 'Женщины' },
  genderGay: { id: 'search.settings.gender.GAY', defaultMessage: 'Геи' },
  genderLesbian: {
    id: 'search.settings.gender.LESBIAN',
    defaultMessage: 'Лесби',
  },
})

export const WEIGHT_MINIMUM = 40
export const WEIGHT_MAXIMUM = 160
