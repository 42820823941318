export const leftClickButton = (event) => {
  /**
   * В старом iOS нету event.button
   */
  if (event.button) {
    return event.button === 0
  }

  return true
}
