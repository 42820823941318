import { Api5Dispatch, AsyncAction } from 'actions/actions.types'
import { postRegistrationAction } from 'actions/authorization/postRegistrationAction'
import { AsyncThunkAction } from 'actions/types'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { registrationApi } from 'api/authorization/registrationApi'
import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'
import { Api5AuthResponse, Api5Error } from 'common-constants/api5.types'
import { FEMALE, MALE } from 'components/page/Landing/constants'
import { RootState } from 'reducers/RootState'

export const REGISTER_ACTION = 'REGISTER_ACTION' as const

export interface RegistrationAction extends AsyncAction {
  type: typeof REGISTER_ACTION
}

export const registrationAction =
  (forceUseDataFromPreviousForm?: boolean): AsyncThunkAction =>
  async (dispatch, getState) => {
    const { authorizationReducer, stepRegistration, systemReducer } = getState()

    const {
      email,
      name,
      gender,
      targetGender,
      location = '',
      year,
      month,
      day,
    } = forceUseDataFromPreviousForm
      ? getRegistrationDataFromAuthorizationReducer(authorizationReducer)
      : getRegistrationDataFromStepRegistrationReducer(stepRegistration)

    const { result: json } = await (dispatch as Api5Dispatch)({
      type: REGISTER_ACTION,
      promise: () =>
        registrationApi(
          email,
          name,
          gender!,
          targetGender as string,
          location,
          {
            year,
            month,
            day,
          },
          systemReducer.locale
        ),
    })

    if (apiResultWithoutErrors(json as Api5Error)) {
      dispatch(postRegistrationAction(json as Api5AuthResponse))
    }

    dispatch(fetchMiniIfNeededAction())
  }

const getRegistrationDataFromAuthorizationReducer = (
  authorizationReducer: RootState['authorizationReducer']
) => {
  const {
    form: { email, name, gender, location, year, month, day },
  } = authorizationReducer

  return {
    email,
    name,
    gender,
    // В легаси форме такого поля нет, поэтому мы решаем за пользователя, что он ищет противоположный пол
    targetGender: gender === FEMALE ? MALE : FEMALE,
    location,
    year,
    month,
    day,
  }
}

const getRegistrationDataFromStepRegistrationReducer = (
  stepRegistration: RootState['stepRegistration']
) => {
  const {
    form: { email, name, year, month, day },
    gender,
    targetGender,
  } = stepRegistration

  return {
    email,
    name,
    gender,
    targetGender,
    location: '',
    year,
    month,
    day,
  }
}
