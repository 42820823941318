import React, { FC, useEffect } from 'react'

import { ApolloProvider } from '@apollo/client'
import { withProfiler } from '@sentry/react'
import { History } from 'history'
import cookie from 'js-cookie'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import { checkCookiesAction } from 'actions/system/checkCookiesAction'
import { sPostCookie } from 'common/constants'
import { App } from 'components/App'
import { handleIntlError } from 'functions/handleIntlError'

import { internalLocale } from './client'
import { store } from './configureClientStore'
import { client } from './functions/createClientSideApollo'

// #_=_ приходит после верификации через фейсбук и приводит в разнице при запуске роутера
// в итоге добавляется событие PUSH, которое ломает навигацию назад
if (location.hash === '_=_') {
  location.hash = ''
}

export const InternalStart: FC<{ history: History }> = ({ history }) => {
  useEffect(() => {
    store.dispatch(checkCookiesAction())
  }, [])

  if (!cookie.get(sPostCookie)) {
    // Просто ничего не покажем, пока не обновим куку
    console.error('Can not render: cookie not found: s_post')
    return null
  }

  const SentryApp = withProfiler(App)

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <IntlProvider
          locale={internalLocale}
          messages={window.__MESSAGES__}
          onError={handleIntlError}
        >
          <Router history={history}>
            <SentryApp store={store} history={history} />
          </Router>
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  )
}
