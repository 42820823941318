import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { findUpdateApiMethod } from 'actions/findUpdateApiMethod'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { goBackAction } from 'actions/route/routeAction'
import { saveStateToSessionStorage } from 'common/store/sessionStorage'
import { fullPageReload } from 'functions/fullPageReload'
import { hasServiceWorkerStatusActivated } from 'functions/pwa/hasServiceWorkerStatusActivated'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { push } from 'functions/router'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'

export const UPDATE_SETTING = 'UPDATE_SETTING'

export interface UpdateSettingAction extends AsyncAction {
  type: typeof UPDATE_SETTING
  field: keyof SettingsForm
  value: string
}

export const updateSettingAction =
  (field: keyof SettingsForm, returnUrl: string | null): AsyncThunkAction =>
  async (dispatch, getState) => {
    const { settingsReducer } = getState()
    if (!settingsReducer.lockUpdates[field]) {
      const value = settingsReducer.form[field]

      const { result: json } = (await dispatch({
        type: UPDATE_SETTING,
        field,
        value,
        promise: () => findUpdateApiMethod(field, value as string),
      })) as unknown as { result: unknown }

      if (!json) {
        return
      }

      // TODO вынести во View проверку на поле
      if (field === 'languageId') {
        /**
         * Может быть такое что пользователь очень быстро
         * дошел до данного пункт
         */
        if (isPwaSupported() && hasServiceWorkerStatusActivated()) {
          dispatch(goBackAction())
          dispatch(updateShellAction({ reload: true }))
        } else {
          window.addEventListener(
            'popstate',
            () => {
              /**
               * Необходимо сохранить навигацию в sessionStorage.
               * Иначе она может не успеть сохранится, и произойдет рассинхрон.
               * В итоге бек увидит дубликат и перекинет назад:
               *
               * https://youtrack.mamba.ru/issue/M-11056
               */
              // TODO поменять на saveStorageAction, когда выйдет в мастер
              saveStateToSessionStorage(getState())
              fullPageReload()
            },
            { once: true }
          )

          dispatch(goBackAction())
        }

        if (returnUrl) {
          dispatch(push(returnUrl))
          return
        }

        dispatch(goBackAction())
      }
    }
  }
