import { createLocaleInApiQuery } from 'api/functions'
import { paths } from 'api/generated/location'

import { fetchApi } from './fetchApi'

type Response =
  paths['/location/suggestions/{locationId}']['get']['responses']['200']['schema']

export const fetchLocationSuggestListApi = (
  locationId: string,
  locale: string
) => {
  return fetchApi<Response>(
    `/location/suggestions/${locationId}${createLocaleInApiQuery(
      locale
    )}` as '/location/suggestions/{locationId}'
  )
}
