import React, { FC } from 'react'

import { useWindowScrollbar } from './hooks/useWindowScrollbar'
import { Scrollbar } from './Scrollbar'

export const WindowScrollbar: FC = () => {
  const { scrollbarRef } = useWindowScrollbar()

  return <Scrollbar ref={scrollbarRef} positionFixed={true} />
}
