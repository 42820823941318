import { fetchApi } from 'api/index'

export const profileApi = async (statusNames = []) =>
  fetchApi(`/profiles/my?statusNames=${statusNames.join(',')}`)

export const profileEmailApi = async () => fetchApi('/profiles/my/email')

export const testPasswordApi = (password: string) =>
  fetchApi('/users/password/testPassword', {
    method: 'POST',
    body: JSON.stringify({ password }),
  })

export const fetchSettingsContainerApi = () =>
  fetchApi('/container', {
    method: 'POST',
    body: JSON.stringify([
      { method: 'GET', url: '/notifications/email_notifications' },
      { method: 'GET', url: '/incognito' },
      { method: 'GET', url: '/settings/profile_visibility/status' },
      { method: 'GET', url: '/settings/vip/settings' },
      { method: 'GET', url: '/settings/messenger_filter/settings' },
      { method: 'GET', url: '/settings/main_photo_mode/mode' },
    ]),
  })

export const fetchMainPhotoUpdateSettingApi = () =>
  fetchApi('/settings/main_photo_mode/mode')

export const changeUserEmailApi = (
  newEmail: string,
  password: string | null
) => {
  return fetchApi('/email/change', {
    method: 'POST',
    body: JSON.stringify({ newEmail, password }),
  })
}
