import { fetchQueryApi } from './fetchApi'
import { paths } from './generated/system_settings'

type CometGetQuery = paths['/system_settings']['get']
type CometQuery = CometGetQuery['parameters']['query']
type CometResponse = CometGetQuery['responses']['200']['schema']

export const fetchWebSocketUrlApi = () => {
  return fetchQueryApi<CometResponse, CometQuery>('/system_settings', {
    settings: 'comet',
  })
}
