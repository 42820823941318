import React, { FC, ReactNode } from 'react'

import { createPortal } from 'react-dom'

import {
  UniNoticeButtonData,
  UniNoticeImages,
  UniNoticeSecondaryHandler,
} from 'api/uninotice/uninotice.types'
import { WithClassName } from 'common/types'
import { Colors } from 'common-constants/colors'
import { FlexGrow } from 'components/presentational'
import { UniNoticeButtonColumn } from 'components/uninotice/UniNoticeButton'
import { UniNoticeContentCommon } from 'components/uninotice/UniNoticeContentCommon'
import {
  FlexBox,
  FlexChildButton,
  FlexChildContent,
  FlexChildInlineButton,
  InlineViewAligner,
} from 'components/uninotice/UniNoticeView.styled'

interface UniNoticeProps {
  title?: ReactNode
  text?: ReactNode
  textComponent?: ReactNode
  imageSource?: UniNoticeImages
  imageComponent?: ReactNode
  primaryButton?: UniNoticeButtonData
  primaryComponent?: ReactNode
  secondaryComponent?: ReactNode
  secondaryLink?: UniNoticeButtonData
  secondaryText?: string
  onPrimaryClick?: () => void
  onSecondaryClick?: UniNoticeSecondaryHandler
  noticeId?: string
  color?: string
}

/**
 * TODO:
 * Нужно вынести часть с шаблоном элементов нотиса из оберток определяющих лэйаут.
 * Обертки убрать в компоненты конкретных типов (Modal / Inline)
 */
const UniNoticeViewContent: FC<
  UniNoticeProps & {
    inline?: boolean
    gradient?: boolean
    customButtonsElement?: ReactNode
  }
> = ({
  title,
  text,
  textComponent,
  imageSource,
  imageComponent,
  primaryButton,
  primaryComponent,
  secondaryComponent,
  secondaryLink,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  noticeId,
  color = Colors.black,
  gradient = true,
  customButtonsElement,
}) => {
  return (
    <FlexBox data-name={`notice-${noticeId}`}>
      <FlexChildContent>
        <FlexGrow />
        <UniNoticeContentCommon
          title={title}
          text={text}
          textComponent={textComponent}
          imageSource={imageSource}
          imageComponent={imageComponent}
          noticeId={noticeId!}
          color={color}
        />
        <FlexGrow />
      </FlexChildContent>
      <FlexChildButton $isUseGradient={gradient}>
        <UniNoticeButtonColumn
          primaryButton={primaryButton}
          primaryComponent={primaryComponent}
          secondaryComponent={secondaryComponent}
          secondaryLink={secondaryLink}
          secondaryText={secondaryText}
          onPrimaryClick={onPrimaryClick}
          onSecondaryClick={onSecondaryClick}
        />
        {customButtonsElement}
      </FlexChildButton>
    </FlexBox>
  )
}

/**
 * UniNoticeViewContent не подходит для определенных случаев Inline-нотисов.
 */
export const UniNoticeInlineView: FC<
  { primaryButtonPortalContainer?: Element | null } & UniNoticeProps &
    WithClassName
> = ({
  className,
  title,
  text,
  textComponent,
  imageSource,
  imageComponent,
  primaryButton,
  /** Нужен для того, чтобы с помощью React.createPortal зарендерить кнопку юнинотиса в другом месте DOM */
  primaryButtonPortalContainer,
  primaryComponent,
  secondaryComponent,
  secondaryLink,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
  noticeId,
  color = Colors.black,
}) => {
  const uniNoticeButtonElement = (
    <UniNoticeButtonColumn
      primaryButton={primaryButton}
      primaryComponent={primaryComponent}
      secondaryComponent={secondaryComponent}
      secondaryLink={secondaryLink}
      secondaryText={secondaryText}
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
    />
  )

  return (
    <InlineViewAligner className={className}>
      <UniNoticeContentCommon
        title={title}
        text={text}
        textComponent={textComponent}
        imageSource={imageSource}
        imageComponent={imageComponent}
        noticeId={noticeId!}
        color={color}
      />
      <FlexChildInlineButton>
        {primaryButtonPortalContainer
          ? createPortal(uniNoticeButtonElement, primaryButtonPortalContainer)
          : uniNoticeButtonElement}
      </FlexChildInlineButton>
    </InlineViewAligner>
  )
}

export default UniNoticeViewContent
