import { useShallowEqualSelector } from './useShallowEqualSelector'

export const useRedesign = () => {
  return useShallowEqualSelector(({ systemReducer: { redesign } }) => redesign)
}

export const useRedesignLayout = (): boolean => {
  return useShallowEqualSelector(
    ({ systemReducer: { redesignLayout } }) => redesignLayout
  )
}
