import {
  AppDispatch,
  AsyncAction,
  AsyncThunkAction,
} from 'actions/actions.types'
import { AppGetState } from 'actions/actions.typesNext'
import { CustomMetaResult, fetchCustomMetaDataApi } from 'api/meta/metaDataApi'

export const UPDATE_META_DATA_BOOTSTRAP = 'UPDATE_META_DATA_BOOTSTRAP'
export const FETCH_CUSTOM_META_DATA = 'FETCH_CUSTOM_META_DATA'

interface UpdateMetaDataBootstrapAction extends CustomMetaResult {
  type: typeof UPDATE_META_DATA_BOOTSTRAP
}

interface FetchCustomMetaDataAction {
  type: typeof FETCH_CUSTOM_META_DATA
}

export const updateCustomMetaDataAction = ({
  title,
  description,
  metaRobots,
  canonicalUrl,
}: CustomMetaResult) => ({
  type: UPDATE_META_DATA_BOOTSTRAP,
  title,
  description,
  canonicalUrl,
  metaRobots,
})

interface FetchCustomMetaDataAction extends AsyncAction<CustomMetaResult> {
  type: typeof FETCH_CUSTOM_META_DATA
}

export const fetchCustomMetaDataAction =
  (path: string) => (dispatch: AppDispatch, getState: AppGetState) => {
    /** Не запрашиваем, если мета была проставлена в ручную в <Meta /> */
    if (!getState().metaData.hasInfo) {
      return dispatch({
        type: FETCH_CUSTOM_META_DATA,
        promise: () => fetchCustomMetaDataApi({ url: path }),
      })
    }

    return Promise.resolve()
  }

export const SET_CUSTOM_META_DATA = 'SET_CUSTOM_META_DATA'

interface SetCustomMetaData {
  type: typeof SET_CUSTOM_META_DATA
  title: string
  header: string
  description: string
  canonicalUrl: string
  metaRobots: string | undefined
}

/**
 * Ручная установка меты из конкретных страниц.
 * @param title
 * @param description
 * @param canonicalUrl
 * @param metaRobots
 */
export const setCustomMetaDataAction =
  (
    title: string | undefined,
    description: string | undefined,
    canonicalUrl: string | undefined,
    metaRobots: string | undefined,
    header: string | undefined
  ): AsyncThunkAction<unknown> =>
  (dispatch, getState) => {
    const { metaData } = getState()
    if (
      metaData.title !== title ||
      metaData.header !== header ||
      metaData.description !== description ||
      metaData.canonicalUrl !== canonicalUrl ||
      metaData.metaRobots !== metaRobots
    ) {
      return dispatch({
        type: SET_CUSTOM_META_DATA,
        title,
        header,
        description,
        metaRobots,
        canonicalUrl,
      })
    }
  }

export type MetaDataUpdateTypes =
  | UpdateMetaDataBootstrapAction
  | FetchCustomMetaDataAction
  | SetCustomMetaData
