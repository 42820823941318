import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { goBackAction } from 'actions/route/routeAction'
import { VoidHandler } from 'common/types'
import { GeoPermissionRequestSwitch } from 'components/block/GeoPermissionRequest/GeoPermissionRequestSwitch'
import { Content } from 'components/presentational/modal'
import { ModalNavigation } from 'components/presentational/ModalNavigation'
import { ViewContentModalNotice } from 'components/uninotice/UniNoticeView.styled'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { settingsReducer } from 'reducers/settings/settingsReducer'

import { GeolocationAbTestPageName } from './hooks/useGeolocationAbTestAnalytics.types'

export const GeoPermissionRequest: FC<{
  onSuccess?: VoidHandler
  pageName: GeolocationAbTestPageName
}> = ({ onSuccess, pageName }) => {
  useReducersInsert({ settingsReducer })
  const dispatch = useDispatch()

  const handleSuccess = () => {
    onSuccess?.()
    dispatch(goBackAction())
  }

  return (
    <Content>
      <ModalNavigation
        historyBack={true}
        title={
          <FormattedMessage
            id="search.settings.request_permission_title"
            defaultMessage="Поделитесь местоположением"
          />
        }
      />
      <ViewContentModalNotice>
        <GeoPermissionRequestSwitch
          title={null}
          onSuccess={handleSuccess}
          pageName={pageName}
        />
      </ViewContentModalNotice>
    </Content>
  )
}
