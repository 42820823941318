import { createContext, useMemo, useState } from 'react'

export const useModalLayoutContext = () => {
  const [overlayClickHandler, setOverlayClick] =
    useState<() => void | undefined>()

  const contextValueForChildren: ContextValueForChildren = useMemo(
    () => ({
      setOverlayClick(handler) {
        setOverlayClick(() => handler) // Почему-то handler вызывается внутри, хотя не должен. Похоже на баг в реакте
      },
      resetOverlayClick() {
        setOverlayClick(undefined)
      },
    }),
    []
  )

  return {
    overlayClickHandler,
    setOverlayClick,
    contextValueForChildren,
  }
}

interface ContextValueForChildren {
  setOverlayClick?: (clickHandler: () => void) => void
  resetOverlayClick?: () => void
}
export const ModalLayoutContextForChildren =
  createContext<ContextValueForChildren>({})
