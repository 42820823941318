import { useCallback, useEffect, useRef } from 'react'

import { useResizeObserver } from 'hooks/useResizeObserver'

import { ScrollbarRefProps } from '../Scrollbar.types'

export const useWindowScrollbar = () => {
  const scrollbarRef = useRef<ScrollbarRefProps>(null)

  const handleResize = useCallback(() => {
    scrollbarRef.current?.update(
      document.documentElement.scrollHeight,
      window.innerHeight
    )
  }, [])

  useResizeObserver({ current: appElement }, handleResize)

  const handleScroll = useCallback(() => {
    window.requestAnimationFrame(() => {
      scrollbarRef.current?.scroll(window.scrollY)
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return {
    /** Навесить на компонент Scrollbar */
    scrollbarRef,
  }
}

const appElement = document.getElementById('app')
