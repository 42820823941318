import {
  SocketContent,
  SocketData,
} from 'actions/socket/socketReceiveDataAction'
import { ToastUniNotice } from 'api/uninotice/uninotice.types'
import { notEmptyArray } from 'functions/array'
import { createUniNoticeChannel } from 'functions/comet'
import { filterToastUniNoticeByOpenedChatUserId } from 'functions/filterToastUniNoticeByOpenedChatUserId'
import { filterCometMessages } from 'reducers/functions'

/**
 * @param currentUserId - текущий авторизованный пользователь
 * @param openedChatUserId - id пользователя с кем открыт чат
 * @param result - сообщение из кометы uni-notice toast
 */
export const isNeedShowNewMessageShortCut = (
  currentUserId: number,
  openedChatUserId: number,
  result: SocketData<SocketContent>[]
): boolean => {
  const channelUniNotice = filterCometMessages<ToastUniNotice>(
    result as SocketData<ToastUniNotice>[],
    createUniNoticeChannel(currentUserId)
  )

  const filterMessagesToastUniNoticeArray =
    filterToastUniNoticeByOpenedChatUserId(channelUniNotice, openedChatUserId)

  return notEmptyArray(filterMessagesToastUniNoticeArray)
}
