import React, { FC } from 'react'

import { useParams } from 'react-router-dom'

import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { ThenParams, thenPartPath } from 'components/block/Then/Then.paths'
import { loginVariantsPath } from 'components/page/Boarding/boarding.paths'
import { promoVipOneRubleLoginPath } from 'components/page/Promo/VipPromo/VipPromo.path'
import { oneTimePasswordPath } from 'components/page/WebView/WebView.paths'
import { indexPath, inlineNoticePath, vipPromoPath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { removeLocaleSaveSlash } from 'components/system/system.functions'
import { InlineNoticeTypes } from 'components/uninotice/uninotice.types'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useIsAuthUserReady } from 'hooks/useIsAuthUserReady'
import { useLayoutContext } from 'hooks/useLayoutContext'

import { RedirectAfterAuth } from './redirectAfterAuth/RedirectAfterAuth'

const vipPromoLoginPath = mergeAllUrls(vipPromoPath, promoVipOneRubleLoginPath)

const findSpecificPath = (pathname: string) => {
  if (pathname === vipPromoLoginPath) {
    return vipPromoPath
  }
}

export const AuthorizedRedirect: FC<{
  uid?: string
  fromUnauthorizedOnlyUrl?: boolean
}> = ({ uid, fromUnauthorizedOnlyUrl }) => {
  console.info('AuthorizedRedirect', uid)
  const { startScreen, pathname, authorized, isReal, redirectAfterAuthPath } =
    useShallowEqualSelector(
      ({
        systemReducer: { startScreen },
        router: {
          location: { pathname },
        },
        authorizationReducer: { authorized, profile, redirectAfterAuthPath },
      }) => ({
        startScreen,
        pathname,
        authorized,
        isReal: profile?.isReal,
        redirectAfterAuthPath,
      })
    )

  const { baseUrl } = useLayoutContext()
  const { place } = useParams<ThenParams>()

  const pathWithoutLocale = removeLocaleSaveSlash(baseUrl)
  const fullNoticeExpiredUrl = mergeAllUrls(
    inlineNoticePath,
    InlineNoticeTypes.authLinkIsInvalidOrExpiredId
  )

  // Кастомный случай редиректа из модала в модал /promo/login,
  // возможно имеет смысл каким-то образом обобщить
  const specificPath = findSpecificPath(pathname)

  const startScreenPath = defineStartScreenPath(startScreen)

  const baseUrlWithoutLocale = removeLocaleSaveSlash(baseUrl)
  const authUserReady = useIsAuthUserReady()

  /** Не вмешиваемся в редиректы, если это webview OTP для витрины нативных приложений */
  if (pathname.endsWith(oneTimePasswordPath)) {
    return null
  }

  if (authUserReady && redirectAfterAuthPath) {
    return (
      <RedirectAfterAuth
        redirectAfterAuthPath={redirectAfterAuthPath}
        uid="redirect to url after auth"
      />
    )
  }

  /**
   * Если мы авторизованны и жмем на лого, то у нас нет страницы с pathname === '/'
   * Поэтому авторизованным попадая на pathname === '/' мы вызываем данный компонент
   * src/common/components/layout/authorizedRoutes.tsx:78 а как так у нас не меняется baseUrl,
   * то попадаем в условие baseUrlWithoutLocale !== indexPath, поэтому данное правильно создано только в том случае
   * Если мы авторизованы и pathname === '/'
   */
  if (authorized && pathname === indexPath) {
    /**
     * Зачем пуш был добавлен?
     * https://redmine.mamba.ru/issues/121984
     *
     * приводит к https://youtrack.mamba.ru/issue/M-1923
     */
    return <Replace uid="start screen authorized" to={startScreenPath} />
  }

  if (pathname.endsWith(loginVariantsPath)) {
    return <Replace uid="authorized login variant path" to={startScreenPath} />
  }

  if (pathWithoutLocale.startsWith(fullNoticeExpiredUrl)) {
    return (
      <Replace to={indexPath} uid="notice expired" from={pathWithoutLocale} />
    )
  } else if (place) {
    return (
      <Replace
        to={mergeAllUrls(pathWithoutLocale, thenPartPath, place)}
        uid="redirect to a place"
      />
    )
  } else if (baseUrlWithoutLocale !== indexPath && !fromUnauthorizedOnlyUrl) {
    return (
      <Replace
        to={baseUrlWithoutLocale}
        from={baseUrlWithoutLocale}
        uid="replace to baseUrl"
      />
    )
  } else if (specificPath) {
    return <Replace to={specificPath} uid="specific path" />
  } else if (isReal) {
    /**
     * Зачем пуш был добавлен?
     * https://redmine.mamba.ru/issues/121984
     *
     * приводит к https://youtrack.mamba.ru/issue/M-1923
     */
    return <Replace uid="start screen default" to={startScreenPath} />
  } else {
    return null
  }
}
