import { css } from 'styled-components'

export const flexBoxCss = css`
  display: flex;
`

export const flexGrowCss = css`
  flex-grow: 1;
`

export const flexBoxGrowCss = css`
  ${flexBoxCss};
  ${flexGrowCss};
`
