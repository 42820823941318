import { Location } from 'history'
import { RouterActions, LOCATION_CHANGE } from 'redux-first-history'

import {
  SAVE_WINDOW_HISTORY_LENGTH,
  saveWindowHistoryLengthAction,
} from 'actions/storefront/saveWindowHistoryLengthAction'
import {
  RESTORE_PARTIAL_STATE,
  restorePartialStateAction,
} from 'actions/system/localStorageAction'
import { shellPath } from 'components/page/Pwa/Pwa.paths'

import { findStepsBackCount } from './locationsHistoryReducer.functions'

export interface BrowserLocationHistoryState {
  browserHistory: Location[]
  /**
   * storeFrontBrowserHistoryLength
   * если при переходе обратно из оплаты к нам на витрину
   * мы увидим разницу(внешний платежные системы добавили нам истории)
   * мы откатимся на разницу, между сохраненным значением, перед уходом с витрины
   * во внешнюю оплату, и тем что есть у браузера сейчас (window.history.length)
   */
  storeFrontBrowserHistoryLength: number
  isFirstClientRenderingPassed: boolean
}

export const initialState: BrowserLocationHistoryState = {
  browserHistory: [],
  storeFrontBrowserHistoryLength: 0,
  isFirstClientRenderingPassed: false,
}

export const locationsHistory = (
  state = initialState,
  action:
    | RouterActions
    | ReturnType<typeof saveWindowHistoryLengthAction>
    | ReturnType<typeof restorePartialStateAction>
): BrowserLocationHistoryState => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const {
        action: routerAction,
        location,
        location: { pathname },
      } = action.payload

      /**
       * Если запустилось из шелла, ее не нужно добавлять в историю.
       * https://youtrack.mamba.ru/issue/M-6650
       */
      if (pathname.startsWith(shellPath)) {
        return state
      }

      switch (routerAction) {
        case 'PUSH':
          return {
            ...state,
            browserHistory: [...state.browserHistory, location],
          }
        case 'POP':
          /**
           * Проблема при восстановление на клиенте state
           * Первое событие при hydrate history POP.
           * Клиенты, которые с сервера уже имеют правильный state.
           * При событие POP мы получим измененный state.
           * Чтобы изменить state только на клиенте и только при hydrate
           * воспользуемся proccess
           * PWA клиенты так же будут работать
           */

          if (state.isFirstClientRenderingPassed) {
            const stepsBackCount =
              findStepsBackCount(state.browserHistory, pathname) || -1

            const updatedBrowserHistory = state.browserHistory.slice(
              0,
              stepsBackCount
            )

            return {
              ...state,
              browserHistory: updatedBrowserHistory,
            }
          } else {
            const _firstRender = process.env.browser ? true : false

            if (state.browserHistory.length === 0) {
              return {
                ...state,
                browserHistory: [location],
                isFirstClientRenderingPassed: _firstRender,
              }
            }

            const findPathname = state.browserHistory.find((item) =>
              item.pathname.includes(location.pathname)
            )

            if (findPathname) {
              return {
                ...state,
                isFirstClientRenderingPassed: _firstRender,
              }
            } else {
              return {
                ...state,
                browserHistory: [...state.browserHistory, location],
                isFirstClientRenderingPassed: _firstRender,
              }
            }
          }

        case 'REPLACE':
          return {
            ...state,
            browserHistory: [...state.browserHistory.slice(0, -1), location],
          }
        default:
          return state
      }

    /**
     * После возвращение с внешних store.
     * Мы должны точно понимать, какая длина истории браузера была до того как мы ушли.
     * Когда мы возвращаемся, то сайт запускается с нуля и мы снова можем вызвать данный action,
     * но уже с измененной историей браузера.
     *
     * Логика следующая:
     *
     * 1. Зашли на модал.  Запомнили значение длины истории только на входе.
     * 2. Переходы между модалами идут через replace, а соотвественно не меняют длину истории(по крайней мере так было)
     * 3. Соотвественно если какой либо внешний источник добавил длину, то будет расхождения
     * 4. Вышли с витрины, сбросили значение для истории для витрины.
     *
     * И так по циклу (edited)
     */
    case SAVE_WINDOW_HISTORY_LENGTH:
      if (state.storeFrontBrowserHistoryLength > 0 && action.value !== 0) {
        return state
      }

      return {
        ...state,
        storeFrontBrowserHistoryLength: action.value,
      }

    case RESTORE_PARTIAL_STATE:
      if (action.name === 'locationsHistory') {
        return {
          ...state,
          ...action.partialState?.locationsHistory,
        }
      }

      return state

    default:
      return state
  }
}
