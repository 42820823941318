import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { localeOnlyRegExp, localeRegExp } from 'server/server.constants'

/**
 * Достает локаль из пути, в том числе из параметра для PWA.
 * @param path
 * @param query
 * @returns
 */
const resolvePathLocale = (path: string, query: Record<string, string>) => {
  if (path.startsWith(shellPath)) {
    /**
     * Используется на сервере, пока оставим,
     * т.к. могут быть живы старые установки.
     */
    if (path === shellPath && query.forceLocale) {
      return query.forceLocale
    }

    const [_pwa, _shell, locale] = path.split('/').filter(Boolean)
    return locale
  }

  const localeOnlyResult = path.match(localeOnlyRegExp)

  if (localeOnlyResult) {
    return localeOnlyResult[1]
  }

  const pathWithLocaleResult = path.match(localeRegExp)

  if (pathWithLocaleResult) {
    return pathWithLocaleResult[1]
  }

  return null
}

export default resolvePathLocale
