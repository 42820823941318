import { definitions } from 'api/generated/notifications_emails'
import { definitions as photo_verification } from 'api/generated/photo_verification'
import { definitions as profile } from 'api/generated/profile'
import { definitions as real_user_general } from 'api/generated/real_user_general'
import { definitions as real_user_phone_verification } from 'api/generated/real_user_phone_verification'
import { definitions as settings } from 'api/generated/user_settings'
import {
  PhoneVerificationAction,
  PhoneVerificationMethod,
} from 'components/page/EnterVerificationCode/constants'
import { Phone } from 'reducers/authorization/Phone'
import { FormRange } from 'reducers/reducers.types'

export enum StatusVerified {
  NotVerified = 'not_verified',
  Verified = 'verified',
  PreviouslyVerified = 'previously_verified',
}

/** Сущность группы подписки из api/notifications/subscriptions */
export interface SubscriptionGroup {
  /** Идентификатор группы */
  id: string
  /** Имя группы */
  name: string
  /** Каноническое имя */
  сname: string
  /** Подписан ли пользователь на эту группу? */
  subscribed: boolean
  /** Описание группы */
  description?: string
}

export interface SettingsForm {
  name: string | undefined
  languageId: number | undefined
  email: string
  birth: string
  location: string
  defaultPhoto: string | undefined
  verificationCode: string
  incognito?: boolean
  atlas: boolean
  number: number // ?
  password: string
  passwordCheck: string
  passwordNewCheck: string
  eroticPhotos: boolean
  messageSound: boolean | null | undefined
  profileVisibilityStatus: string
  genderFilter: settings['MessengerFilterSettings']['genderFilter'] | null // ?
  likedFilter: boolean | undefined
  photoFilter: boolean | null
  isPhotoFilterAvailable: boolean | null
  vipFilter: boolean | undefined
  hiddenLastAccessTime: boolean
  invisibleMode: boolean
  hiddenAge: boolean
  ageFilter: FormRange
  filterEnabled: boolean | undefined
  phone: Phone
}

export interface SettingsReducerState {
  info: Record<string, boolean>
  gesture: string | null

  settingsLoading: boolean
  incognitoStatusUpdating: boolean
  gdprDate: number | null
  wrongPassword: boolean
  errorCode: null | string
  socialVerify: Record<string, { url: string }>

  gdprStatusLoading: boolean
  gdprStatusLoaded: boolean
  settingsContainerLoaded: boolean
  locationUpdating: boolean

  hasActiveNotification: boolean
  email: string // ?
  verificationMethods: real_user_general['AllowedRealMethodsList'] | null
  verificationMethodsLoading: boolean
  gestureLoading: boolean
  gestureLoaded: boolean
  photoStatus: photo_verification['PhotoVerificationStatus']['status'] | null

  phonePasswordRequired: boolean
  passwordRequiredLoading: boolean | null
  locationLoaded: boolean

  form: SettingsForm
  profile: Partial<SettingsForm>
  lockUpdates: Partial<Record<keyof SettingsForm, boolean>>

  personalSettingsLoaded: boolean

  willBeAllowedAt: string | null
  willBeAllowedAtLoaded: boolean

  variants: Record<string, string>
  notifications: definitions['EmailNotification'][]

  /** api/notifications/subscriptions */
  pushSubscriptionGroups: SubscriptionGroup[]

  vipSettingsLoaded: boolean
  photoStatusLoading: boolean

  phoneCodeList: real_user_phone_verification['RealUserPrefixesGroup'][] | null

  socialLoading: Record<string, boolean>
  externalVerify: Record<string, string | undefined>

  formError: Record<string, string>

  photoLoaded: boolean
  vipRequired: boolean

  location: profile['UserLocation']

  accountRemovalWithReasonRequesting: boolean
  accountRemovalWithReasonRequested: boolean
  accountRemovalWithReasonRequestFailed: boolean

  // Верификация телефона
  phoneVerificationMethod: PhoneVerificationMethod | null
  phoneVerificationAction: PhoneVerificationAction | null
  phoneVerificationMobileIdSuccess: boolean | null
}

export const initialState: SettingsReducerState = {
  settingsLoading: false, // блокирует изменение поля, в то время когда данные сохраняются на сервере
  incognitoStatusUpdating: false,
  socialLoading: {},
  photoStatusLoading: false,
  verificationMethodsLoading: false,
  gestureLoading: false,
  lockUpdates: {},
  profile: {
    ageFilter: {
      from: 18,
      to: 80,
    },
    // phone: { code: 7, number: 0, countryCode: 'ru' },

    email: '',
  },
  variants: {},
  form: {
    name: '',
    email: '',
    password: '',
    passwordCheck: '',
    passwordNewCheck: '',
    eroticPhotos: false,
    messageSound: null,
    profileVisibilityStatus: '',
    hiddenLastAccessTime: false,
    invisibleMode: false,
    hiddenAge: false,
    ageFilter: { from: 18, to: 80 },
    // phone: { code: 7, number: 0, countryCode: 'ru' },
    // Верификация телефона
    phone: {
      phoneNumber: null,
      countryCode: null,
      phoneNumberFull: null,
    },
    verificationCode: '',
    atlas: false,
    filterEnabled: false,
    genderFilter: null,
    photoFilter: null,
    isPhotoFilterAvailable: null,
    likedFilter: false,
    vipFilter: false,
    defaultPhoto: 'auto',
    location: '',
    birth: '',
    languageId: 0,
    number: 0,
  },
  formError: {},
  info: {},

  // reset
  vipSettingsLoaded: false,
  personalSettingsLoaded: false,
  gestureLoaded: false,
  photoLoaded: false,

  vipRequired: false,
  verificationMethods: null,
  phoneCodeList: null,
  errorCode: null,
  socialVerify: {},
  externalVerify: {},
  gesture: null,

  // На бекенде не смогли отдать целостно верификацию фото, поэтому приходится
  // использовать еще один метод
  // /api/photo_verification/status
  photoStatus: null,
  notifications: [],
  // api/notifications/subscriptions
  pushSubscriptionGroups: [],
  hasActiveNotification: false,
  email: '',
  willBeAllowedAt: null,
  willBeAllowedAtLoaded: false,
  gdprDate: null,
  gdprStatusLoading: false,
  gdprStatusLoaded: false,
  wrongPassword: false,
  phonePasswordRequired: false,
  passwordRequiredLoading: false,
  locationLoaded: false,
  location: {
    locationString: '',
    location: {
      country: 0,
      region: 0,
      city: 0,
      metro: 0,
      location: '',
      countryName: '',
      regionName: '',
      cityName: '',
      metroName: '',
    },
  },
  locationUpdating: false,
  settingsContainerLoaded: false,

  accountRemovalWithReasonRequesting: false,
  accountRemovalWithReasonRequested: false,
  accountRemovalWithReasonRequestFailed: false,

  // Верификация телефона
  phoneVerificationMethod: null,
  phoneVerificationAction: null,
  phoneVerificationMobileIdSuccess: null,
}
