import cookie from 'js-cookie'

import { fetchApi5 } from 'api/index'
import {
  paramsAlbumsClassMate,
  paramsPhotosClassMate,
  urlClassMateProxyPath,
} from 'components/page/UploaderPhotos/constants'
import { mergeClassMateAlbums } from 'components/page/UploaderPhotos/function/mergeClassMateAlbums'
import { buildQueryStringFromObject } from 'functions/queryString'

import { messengerSupportUploadPhotoApi } from './messengerSupport/messengerSupportUploadPhotoApi'

const getSpostCookie = () => cookie.get('s_post')

export const fetchUrlToUploadFileApi = () => {
  return fetchApi5(`/photos/upload?s_post=${getSpostCookie()}`)
}

export const uploadPhotoApi = (data, albumId = 0) => {
  const query = {
    albumId: albumId,
    s_post: getSpostCookie(),
  }
  return fetchApi5(`/photos/upload/v2${buildQueryStringFromObject(query)}`, {
    method: 'POST',
    body: data,
  })
}

export const uploadPhotoFromSocialNetworkApi = (photos, albumId) => {
  return fetchApi5('/photos/upload_from_social_networks', {
    method: 'POST',
    body: JSON.stringify({
      album_id: albumId,
      photos_links: photos,
      s_post: getSpostCookie(),
    }),
  })
}

export const uploaderPhotosSwitchApi = async (
  photos,
  albumId = 0,
  callback,
  userHasRestrictions
) => {
  const totalCount = photos.length
  const promises = []

  const request = userHasRestrictions
    ? messengerSupportUploadPhotoApi
    : uploadPhotoApi

  for (const item of photos) {
    const data = new FormData()
    data.append('photo[]', item)
    promises.push(
      request(data, albumId).then((json) => {
        callback(json)
        return json
      })
    )
  }

  const result = await Promise.all(promises)

  const succeededCount = result.reduce((acc, { photo, ok }) => {
    const success = userHasRestrictions ? ok : photo
    return success ? acc + 1 : acc
  }, 0)

  const limitCount = result.reduce((acc, item) => {
    if (item.internalError && item.internalError.errorCode) {
      return item.internalError.errorCode === 476 ? acc + 1 : acc
    }
  }, 0)

  const failedCount = result.reduce((acc, { photo, ok }) => {
    const fail = userHasRestrictions ? !ok : !photo
    return fail ? acc + 1 : acc
  }, 0)

  return {
    succeededCount,
    failedCount,
    totalCount,
    limitCount,
  }
}

export const fetchUrlClassMateApi = () => {
  return fetchApi5('/oauth/okru/login-url')
}

export async function authorizingInClassMateApi() {
  const query = {
    method: 'users.getLoggedInUser',
  }

  return await fetchProxyClassMateApi(query)
}

export const fetchAlbumsFromClassMateApi = async () => {
  const query = {
    method: 'photos.getAlbums',
    fields: paramsAlbumsClassMate.join(','),
  }

  return fetchProxyClassMateApi(query)
}

export const fetchPhotosFromAlbumClassMateApi = (albumId) => {
  const temporaryQuery = {
    method: 'photos.getPhotos',
    fields: paramsPhotosClassMate.join(','),
    count: '100',
  }
  const query = albumId ? { ...temporaryQuery, aid: albumId } : temporaryQuery
  return fetchProxyClassMateApi(query)
}

export const fetchPhotosFromClassMateApi = async (albums) => {
  const promises = albums.map(({ aid }) => {
    // Дернуть альбом по умолчанию c id нельзя, у него нет api, поэтому дергаем без id
    if (aid === 1) {
      return fetchPhotosFromAlbumClassMateApi()
    } else {
      return fetchPhotosFromAlbumClassMateApi(aid)
    }
  })
  const photos = await Promise.all(promises)
  return mergeClassMateAlbums(albums, photos)
}

const fetchProxyClassMateApi = async (query) => {
  const response = await fetch(
    `${urlClassMateProxyPath}${buildQueryStringFromObject(query)}`,
    {
      credentials: 'include',
    }
  )

  return response.json()
}

export const fetchFromClassMateApi = async () => {
  const { albums } = await fetchAlbumsFromClassMateApi()
  // api одноклассников не отдает альбом по умолчанию, поэтому вставляет в альбомы данный хак.
  const extendsAlbums = [{ aid: 1 }, ...albums]

  return await fetchPhotosFromClassMateApi(extendsAlbums)
}
