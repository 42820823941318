import { AsyncThunkAction } from 'actions/actions.types'
import { goBackAction } from 'actions/route/routeAction'
import {
  clearSettingAction,
  fetchSettingIfNeededAction,
} from 'actions/settingsAction'
import { toggleSearchVisibilityApi } from 'api/settings/settingsApi'
import { Fields } from 'components/page/Settings/Fields'

export const TOGGLE_SEARCH_VISIBILITY = 'TOGGLE_SEARCH_VISIBILITY'

export const toggleSearchVisibilityAction =
  (enabled: boolean): AsyncThunkAction =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_SEARCH_VISIBILITY,
      enabled,
      promise: async () => {
        const json = await toggleSearchVisibilityApi(enabled)

        dispatch(clearSettingAction(Fields.ProfileVisibilityStatus))
        dispatch(
          fetchSettingIfNeededAction(Fields.ProfileVisibilityStatus, () => {
            dispatch(goBackAction())
          })
        )

        return json
      },
    })
  }
