export const serviceWorkerPath = '/service-worker.js'
export const firebaseMessagingServiceWorkerPath = '/firebase-messaging-sw.js'

export const mockServiceWorkerPath = '/mock-service-worker.js'

/**
 * EDNA запрашивает установку скрипта по данному URL
 * Если файла не будет то внутри скрипта EDNA не будет
 * установлен флаг что notifications разрешены,
 * когда сайт не закрыт, но не в фокусе.
 */
export const webNotificationServiceWorkerPath = '/web-notification-sw.js'
