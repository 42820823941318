export const internalApiPath = '/node/api'

export interface FetchVkPhotosParams {
  token: string
}
export const vkPhotosPath = '/vk/photos'
export const checkAwayPath = '/check-away'
export const ednaLocalesPath = '/edna-locales'

export const sessionInitInternalPath = '/session-init-node'

export type InternalPath =
  | typeof vkPhotosPath
  | typeof sessionInitInternalPath
  | typeof ednaLocalesPath
