import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { fetchFeaturesApi } from 'api/system/fetchFeaturesApi'

export const FETCH_FEATURES = 'FETCH_FEATURES' as const

export const fetchFeaturesAction = (headers?: Api6NodeHeaders) => ({
  type: FETCH_FEATURES,
  promise: () => fetchFeaturesApi(headers),
})

/**
 * Если фичи обновились, нужно обновить шелл, т.к. в __INITIAL_STATE__
 * иначе кешируется результат.
 * @returns
 */
export const updateFeaturesAndUpdateShellIfNeededAction =
  () => async (dispatch: AppDispatchNext, getState: AppGetState) => {
    await dispatch(fetchFeaturesAction())

    if (
      JSON.stringify(window.__INITIAL_STATE__.systemReducer.features) !==
      JSON.stringify(getState().systemReducer.features)
    ) {
      console.info('Features updated, should update shell.')
      dispatch(updateShellAction())
    }
  }
