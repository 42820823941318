/**
 * @deprecated использовать buildQuery
 * @param inputObject
 */
export const buildQueryStringFromObject = (
  inputObject: Record<string, string>
) =>
  Object.keys(inputObject).reduce((result, key) => {
    if (inputObject[key] === undefined || inputObject[key] === null) {
      return result + ''
    }
    const separator = result === '' ? '?' : '&'
    const keyName = encodeURIComponent(key)
    const value = encodeURIComponent(inputObject[key])
    return result + separator + `${keyName}=${value}`
  }, '')

type Query = Record<string, string | boolean | number>

export const buildQuery = <Q = Query>(map: Q) =>
  Object.keys(map!)
    .filter(
      (key) => map[key] || map[key] === 0 || typeof map[key] === 'boolean'
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(map[key])}`)
    .join('&')

export const buildFullQuery = <Q = Query>(map: Q) => {
  const query = buildQuery(map)

  return query ? `?${query}` : ''
}

/**
 * Предполагается либо undefined, либо корректная строка вида:
 * ?some=value&another=value
 */
export const parseQuery = (search: string | undefined) => {
  if (search) {
    return search
      .substring(1)
      .split('&')
      .reduce((acc, next) => {
        const [name, value] = next.split('=')

        return {
          ...acc,
          [name]: value,
        }
      }, {})
  }

  return {}
}
