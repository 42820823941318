import { AsyncThunkAction } from 'actions/actions.types'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { sPostCookie } from 'common/constants'
import { removeAllSPosts } from 'common-constants/cookie'
import { readBrowserCookies } from 'functions/cookie/browserCookies'

/**
 * https://redmine.mamba.ru/issues/116506
 */
export const checkCookiesAction =
  (): AsyncThunkAction<unknown> => (dispatch) => {
    const cookies = readBrowserCookies().filter(
      ([name]) => name === sPostCookie
    )

    if (cookies.length > 1) {
      removeAllSPosts()
      dispatch(fetchMiniIfNeededAction())
    }
  }
