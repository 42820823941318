import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { indexPath } from 'components/paths'
import { findMailRuToken } from 'components/system/authorization/findMailRuToken'
import { replace } from 'functions/router'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/**
 * Задача этого хука, авторизовать пользователя.
 * В случае ошибки отправить на indexPath.
 */
export const useAuthorizeBySecretQueryParams = () => {
  const { authorizedBySecret, authorizationBySecretFailed } =
    useShallowEqualSelector(
      ({
        authorizationReducer: {
          authorizedBySecret,
          authorizationBySecretFailed,
        },
      }) => ({
        authorizedBySecret,
        authorizationBySecretFailed,
      })
    )

  const dispatch = useDispatch()

  useEffect(() => {
    if (authorizedBySecret) {
      dispatch(fetchMiniIfNeededAction())
    }
  }, [dispatch, authorizedBySecret])

  useEffect(() => {
    /** в случае мейла надо показать ошибку на странице логина */
    if (authorizationBySecretFailed && !findMailRuToken()) {
      dispatch(replace(indexPath))
    }
  }, [dispatch, authorizationBySecretFailed])
}
