import React from 'react'

import { isLoveMailru, isWamba } from 'common/constants'
import { useBoardingColor } from 'hooks/useBoardingColor'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const Background = () => {
  const color = useBoardingColor()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const redesign = useRedesign()

  if (redesign) {
    return null
  }

  if (partnerId && (isLoveMailru(partnerId) || isWamba(partnerId))) {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `html { background-color: ${color}; background-image: url('${require('./background.svg')}'); background-size: 503px 526px; }`,
        }}
      />
    )
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `html { background-color: var(--static-white); background-image: url('${require('./bg-orange.png')}'); background-size: 960px 960px; }`,
      }}
    />
  )
}
