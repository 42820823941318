import { AsyncAction } from 'actions/actions.types'
import { AsyncThunkAction } from 'actions/types'
import { ResetUniNoticesAction } from 'actions/uninotice/resetUniNoticesAction'
import { ToggleInlineNoticeAction } from 'actions/uninotice/toggleInlineNoticeAction'
import { pushOrReplaceUploadPhotoAction } from 'actions/uploader/uploaderPhotosAction'
import { NodeHeaders } from 'api/NodeHeaders'
import { UniNotice } from 'api/uninotice/UniNotice'
import { fetchUniNoticeApi, UniNoticeId } from 'api/uninotice/uniNoticeApi'
import { ackClickApi, ackViewApi } from 'api/uninotice/uniNoticeStatisticsApi'
import {
  giftPresentPath,
  storeFrontGiftToUserPath,
} from 'components/storefront/StoreFront.paths'
import {
  DISPLAY_INLINE,
  TYPE_SHORTCUT,
  UniNoticeType,
} from 'components/uninotice/UniNotice.constants'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'

export const FETCH_UNI_NOTICE = 'FETCH_UNI_NOTICE'
export const FETCH_INLINE_NOTICE_PAGE = 'FETCH_INLINE_NOTICE_PAGE'
export const ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX =
  'ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX'

export const CLEAR_INLINE_NOTICE = 'CLEAR_INLINE_NOTICE'

interface FetchUniNoticeAction {
  type: typeof FETCH_UNI_NOTICE
}

export interface UniNoticeParams {
  anketa_id?: number
  userId?: number | string
  recipientId?: number
  messenger?: string
  flow?: string
  totalCount?: number
  count?: number
  external_url?: string
  limit?: number
  photos?: {
    userId?: string[]
    photosIds?: number[]
  }
  contactUserId?: number
  contactOwnerId?: number
  orderId?: string
  paymentError?: unknown
}

export const fetchUniNoticeAction = (
  uniNoticeId: UniNoticeId,
  params: UniNoticeParams = {},
  headers?: NodeHeaders
) => ({
  type: FETCH_UNI_NOTICE,
  promise: () => fetchUniNoticeApi(uniNoticeId, params, headers),
})

export interface FetchInlineNoticePageAction extends AsyncAction<UniNotice> {
  type: typeof FETCH_INLINE_NOTICE_PAGE
}

export const fetchInlineNoticePageAction = (
  uniNoticeId: UniNoticeId,
  params: UniNoticeParams = {}
) => ({
  type: FETCH_INLINE_NOTICE_PAGE,
  promise: () => fetchUniNoticeApi(uniNoticeId, params),
})

export const ADD_NOTICE_TO_COLLECTION = 'ADD_NOTICE_TO_COLLECTION'

export interface AddUniNoticeToCollectionAction {
  type: typeof ADD_NOTICE_TO_COLLECTION
  id: UniNoticeId
  result: UniNotice
}

/**
 * Точно нужны два разных id-шника про одно и тоже?
 * @param uniNotice
 * @param id
 */
export const addUniNoticeToCollectionAction = (
  uniNotice: UniNotice,
  id: UniNoticeId
) => ({
  type: ADD_NOTICE_TO_COLLECTION,
  result: uniNotice,
  id,
})

interface addUniNoticeFromGraphqlToReduxAction {
  type: typeof ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX
  result: UniNotice
}

interface ClearInlineNoticeAction {
  type: typeof CLEAR_INLINE_NOTICE
}

export const clearInlineNoticeAction = () => ({
  type: CLEAR_INLINE_NOTICE,
})

export type UniNoticeTypes =
  | FetchUniNoticeAction
  | AddUniNoticeToCollectionAction
  | ToggleInlineNoticeAction
  | addUniNoticeFromGraphqlToReduxAction
  | FetchInlineNoticePageAction
  | ClearInlineNoticeAction
  | ResetUniNoticesAction

export const switchTypeGiftShowCaseAction =
  (profileId: number, type: string, display: string): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      systemReducer: { baseUrl },
    } = getState()
    if (display === DISPLAY_INLINE) {
      // Если мы открываем подарки из inline notice то нужно сделать push
      return dispatch(
        push(
          mergeAllUrls(
            baseUrl,
            storeFrontGiftToUserPath,
            profileId,
            giftPresentPath
          )
        )
      )
    }

    // Если мы открываем подарки из другого модала, то нужно сделать replace
    return dispatch(
      replace(
        mergeAllUrls(
          baseUrl,
          storeFrontGiftToUserPath,
          profileId,
          giftPresentPath
        )
      )
    )
  }

export const switchUploadAction =
  (type: string): AsyncThunkAction =>
  (dispatch) => {
    if (type === TYPE_SHORTCUT) {
      return dispatch(pushOrReplaceUploadPhotoAction(true))
    } else {
      return dispatch(pushOrReplaceUploadPhotoAction())
    }
  }

export enum UniNoticeStatistics {
  view = 'view',
  click = 'click',
}

const SAVE_UNI_NOTICE_STATISTICS = 'SAVE_UNI_NOTICE_STATISTICS'

export const saveUniNoticeStatisticsAction =
  (id: UniNoticeId, type: UniNoticeStatistics): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      authorizationReducer: { authorized },
    } = getState()
    if (authorized) {
      dispatch({
        type: SAVE_UNI_NOTICE_STATISTICS,
        promise: () => {
          switch (type) {
            case UniNoticeStatistics.view:
              return ackViewApi(id)
            case UniNoticeStatistics.click:
              return ackClickApi(id)
          }
        },
      })
    }
  }

export const saveUniNoticeClickStatisticsAction =
  (id: UniNoticeId, actionId: UniNoticeType | string): AsyncThunkAction =>
  (dispatch) => {
    if (actionId !== UniNoticeType.none) {
      return dispatch(
        saveUniNoticeStatisticsAction(id, UniNoticeStatistics.click)
      )
    }
    return Promise.resolve()
  }

export const addUniNoticeFromGraphqlToReduxAction = (uniNotice: UniNotice) => ({
  type: ADD_UNI_NOTICE_FROM_GRAPHQL_TO_REDUX,
  result: uniNotice,
})
