import { AsyncAction, AsyncThunkActionVoid } from 'actions/actions.types'
import { fetchEmailNotificationsApi } from 'api/fetchEmailNotificationsApi'
import { paths as notifications_emails } from 'api/generated/notifications_emails'

export const FETCH_EMAIL_SUBSCRIPTIONS = 'FETCH_EMAIL_SUBSCRIPTIONS' as const

export interface FetchEmailSubscriptionsIfNeededAction
  extends AsyncAction<
    notifications_emails['/notifications/email_notifications']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_EMAIL_SUBSCRIPTIONS
}

export const fetchEmailSubscriptionsIfNeededAction =
  (): AsyncThunkActionVoid => (dispatch, getState) => {
    const {
      settingsReducer: { notifications },
    } = getState()

    if (notifications?.length) {
      return
    }

    const action: FetchEmailSubscriptionsIfNeededAction = {
      type: FETCH_EMAIL_SUBSCRIPTIONS,
      promise: fetchEmailNotificationsApi,
    }

    dispatch(action)
  }
