export const REQUEST_OPEN_CHAT_SUPPORT_FROM_SETTINGS =
  'REQUEST_OPEN_CHAT_SUPPORT_FROM_SETTINGS' as const

export const requestOpenChatSupportFromSettingsAction = (value: boolean) => ({
  type: REQUEST_OPEN_CHAT_SUPPORT_FROM_SETTINGS,
  value,
})

export const REQUEST_OPEN_CHAT_SUPPORT_FROM_REDIRECT =
  'REQUEST_OPEN_CHAT_SUPPORT_FROM_REDIRECT' as const

export const requestOpenChatSupportFromRedirectAction = (value: boolean) => ({
  type: REQUEST_OPEN_CHAT_SUPPORT_FROM_REDIRECT,
  value,
})
