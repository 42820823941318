export const clientThrottle = (
  callback: Function,
  ms: number,
  lastCall = false
): VoidFunction => {
  let isThrottled = false
  let lastCallTimer: number = 0

  return (...params: unknown[]) => {
    if (!isThrottled) {
      isThrottled = true
      callback(...params)
      clearTimeout(lastCallTimer)
      setTimeout(() => {
        isThrottled = false
      }, ms)
    } else if (lastCall) {
      clearTimeout(lastCallTimer)
      lastCallTimer = window.setTimeout(() => {
        isThrottled = true
        callback(...params)
        setTimeout(() => {
          isThrottled = false
        }, ms)
      }, ms)
    }
  }
}

export const debounce = (callback: Function, ms: number, immediate = false) => {
  let timer: number | null = null
  let calledImmediately = false

  return (...params: unknown[]) => {
    if (timer) {
      clearTimeout(timer)
    }

    if (immediate && !calledImmediately) {
      callback(...params)
      calledImmediately = true
    }

    timer = window.setTimeout(() => {
      if (!immediate) {
        callback(...params)
      }
      timer = null
      calledImmediately = false
    }, ms)
  }
}
