import { FC, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { updatePageVisibilityAction } from 'actions/system/viewAction'

export const WatchPageVisibility: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleUpdate = () =>
      dispatch(updatePageVisibilityAction(!document.hidden))
    document.addEventListener('visibilitychange', handleUpdate)
    return () => {
      document.removeEventListener('visibilitychange', handleUpdate)
      dispatch(updatePageVisibilityAction(true))
    }
  }, [dispatch])

  return null
}
