import { AsyncThunkActionVoid } from 'actions/actions.types'
import { AsyncThunkAction } from 'actions/types'

export const SET_COUNTER_DOWN = 'SET_COUNTER_DOWN'
export const DECREASE_COUNTER_DOWN = 'DECREASE_COUNTER_DOWN'
export const SET_COUNTER_DOWN_ID = 'SET_COUNTER_DOWN_ID'

interface SetCounterDownAction {
  type: typeof SET_COUNTER_DOWN
  timer: number
}

export const setCounterDownAction = (timer: number): SetCounterDownAction => ({
  type: SET_COUNTER_DOWN,
  timer,
})

interface DecreaseCounterDownAction {
  type: typeof DECREASE_COUNTER_DOWN
}

export const decreaseCounterDownAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    const { timer } = getState().counterDown
    if (timer) {
      dispatch({
        type: DECREASE_COUNTER_DOWN,
      })
    }
  }

export const clearCounterDownAction =
  (): AsyncThunkActionVoid => (dispatch, getState) => {
    const { counterDown } = getState()

    if (counterDown) {
      clearInterval(counterDown.timerId)
      dispatch(setCounterDownIdAction(0))
      dispatch(setCounterDownAction(0))
    } else {
      return Promise.resolve()
    }
  }

interface SetCounterDownIdAction {
  type: typeof SET_COUNTER_DOWN_ID
  id: number
}

export const setCounterDownIdAction = (id: number) => ({
  type: SET_COUNTER_DOWN_ID,
  id,
})

export type CounterDownTypes =
  | SetCounterDownAction
  | DecreaseCounterDownAction
  | SetCounterDownIdAction
