import { mergeAllUrls } from 'functions/mergeAllUrls'

export const settingsFieldPath = 'field'

export const settingsPrivacyPath = 'privacy'
export const settingsMainPath = 'main'
export const accountDeletedPath = 'account-deleted'
export const verificationErrorPath = 'error'
export const verificationParamsPath = '/:provider/:code'
export const verifyBySocialLoadingPath = 'social/loading'
export const notificationsPath = 'notifications'
export const emailPath = 'email'
export const notificationsEmailPath = `notifications/${emailPath}`
export const pushPath = 'push'
export const notificationsPushPath = `notifications/${pushPath}`
export const aboutCompanyPath = 'company'
export const settingsDeleteProfilePath = 'remove-profile'
export const settingsRemoveFromSearchPath = 'remove-from-search'
export const settingsRemovedFromSearchPath = 'remove-from-search-success'
export const fullRemovePath = 'full-remove'
export const confirmRemovePath = 'confirm-remove'
export const gdprAcceptPath = 'gdpr-accept'
export const gdprRequestPath = 'gdpr-request'
export const gdprStartedPath = 'gdpr-started'
export const gdprReadyPath = 'gdpr-ready'
export const gdprArchiveNotFoundPath = 'gdpr-archive-not-found'
export const gdprDeleteWarningPath = 'gdpr-delete-warning'
export const gdprRemovePath = 'gdpr-remove'
export const passwordSuccessPath = 'password-success'
export const changeEmailPath = 'field/email'
export const changePasswordPath = 'field/password'
export const fieldPersonalPath = 'field/personal'
export const settingsVerificationPath = 'verification'

export const fieldPath = '/field/:field'

export const emailSuccessPath = 'email-success'
export const emailSuccessFullPath = mergeAllUrls(
  settingsMainPath,
  emailSuccessPath
)
export const passwordSuccessFullPath = mergeAllUrls(
  settingsMainPath,
  passwordSuccessPath
)
