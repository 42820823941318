import cookie from 'js-cookie'

import { paths } from 'api/generated/password_reset'

import { postApi } from './fetchApi'
import { fetchApi5 } from './index'

export const registrationDataApi = (locale) => {
  return fetchApi5(`/registration/?lang_id=${locale}`)
}

export const logoutApi = () => {
  const s_post = cookie.get('s_post')
  return fetchApi5(`/logout?s_post=${s_post}`)
}

type PostEMail = paths['/password_reset/requestResetByEmail']['post']
type BodyEMail = PostEMail['parameters']['body']['body']
type ResponseEMail = PostEMail['responses']['204']

export const resetPasswordEmailApi = (email: string) =>
  postApi<ResponseEMail, BodyEMail>('/password_reset/requestResetByEmail', {
    email,
  })

type PostEMailCross = paths['/password_reset/requestResetByEmailCross']['post']
type BodyEMailCross = PostEMailCross['parameters']['body']['body']
type ResponseEMailCross = PostEMailCross['responses']['204']

export const fetchPasswordCrossUserApi = (email: string) =>
  postApi<ResponseEMailCross, BodyEMailCross>(
    '/password_reset/requestResetByEmailCross',
    {
      email,
    }
  )

type PostSms = paths['/password_reset/requestResetBySms']['post']
type BodySms = PostSms['parameters']['body']['body']
type ResponseSms = PostSms['responses']['204']

export const resetPasswordPhoneApi = (
  phone: string,
  ignoreCrossAuth: boolean = true
) =>
  postApi<ResponseSms, BodySms>('/password_reset/requestResetBySms', {
    phone,
    ignoreCrossAuth,
  })

type PostVerifySms = paths['/password_reset/verifySmsCode']['post']
type BodyVerifySms = PostVerifySms['parameters']['body']['body']
type ResponseVerifySms = PostVerifySms['responses']['204']

export const verifySmsCodeApi = (phone: string, code: string) =>
  postApi<ResponseVerifySms, BodyVerifySms>('/password_reset/verifySmsCode', {
    phone,
    code,
  })
