export const OPEN_SIDEBAR_MENU_MAILRU = 'OPEN_SIDEBAR_MENU_MAILRU'
export const CLOSE_SIDEBAR_MENU_MAILRU = 'CLOSE_SIDEBAR_MENU_MAILRU'
export const GET_SIDEBAR_MENU_MAILRU = 'GET_SIDEBAR_MENU_MAILRU'

export function toogleSideBarMenuAction() {
  return (dispatch, getState) => {
    if (!getState().menuSideBarMailruReducer.open) {
      dispatch(openSideBarMenuMenuAction())
    } else {
      dispatch(closeSideBarMenuMenuAction())
    }
  }
}

export function openSideBarMenuMenuAction() {
  return {
    type: OPEN_SIDEBAR_MENU_MAILRU,
  }
}

export function closeSideBarMenuMenuAction() {
  return {
    type: CLOSE_SIDEBAR_MENU_MAILRU,
  }
}

export function getMenuMailRuAction(result) {
  return {
    type: GET_SIDEBAR_MENU_MAILRU,
    result,
  }
}
