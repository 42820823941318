import {
  CLOSE_WEB_SOCKET_CONNECTION,
  OPEN_WEB_SOCKET_CONNECTION,
  FETCH_WEB_SOCKET_URL,
  CLOSE_SOCKET,
  PUSH_SOCKET_MESSAGE,
  SHIFT_SOCKET_MESSAGE,
  NEW_SOCKET,
  SET_NEW_MESSAGE_CURSOR,
  WebSocketActionTypes,
} from 'actions/webSocketAction'
import { CometMessage } from 'api/comet/comet.types'
import { CometMethod } from 'common-constants/comet'

import { defaultPromiseReducer } from './defaultPromiseReducer'

const updateActiveSubscriptions = (
  currentActiveSubscriptions: Record<string, boolean>,
  message: string
) => {
  const messageObject: CometMessage = JSON.parse(message)
  const nextActiveSubscriptions = { ...currentActiveSubscriptions }

  switch (messageObject.method) {
    case CometMethod.subscribe:
      return messageObject.params.reduce((subscriptions, item) => {
        subscriptions[item.channel] = true
        return subscriptions
      }, nextActiveSubscriptions)
    case CometMethod.unsubscribe:
      messageObject.params.forEach((item) => {
        delete nextActiveSubscriptions[item.channel]
      })
      return nextActiveSubscriptions
    default:
      return currentActiveSubscriptions
  }
}

export interface WebSocketState {
  socketId: number
  isWebSocketConnectionOpen: boolean
  urlWebSocket: string
  messagesQueue: string[]
  activeSubscriptions: Record<string, boolean>
  cursorNewMessage: number | undefined
}

export const webSocketReducer = (
  state = {
    socketId: 0,
    isWebSocketConnectionOpen: false,
    urlWebSocket: '',
    messagesQueue: [],
    activeSubscriptions: {},
    cursorNewMessage: undefined,
  },
  action: WebSocketActionTypes
) => {
  switch (action.type) {
    case NEW_SOCKET:
      return { ...state, socketId: state.socketId + 1 }

    case FETCH_WEB_SOCKET_URL:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result && result.comet) {
          const { websocket: urlWebSocket } = result.comet

          return {
            urlWebSocket,
          }
        }

        return state
      })

    case OPEN_WEB_SOCKET_CONNECTION:
      return {
        ...state,
        isWebSocketConnectionOpen: true,
      }

    case CLOSE_WEB_SOCKET_CONNECTION:
      return {
        ...state,
        isWebSocketConnectionOpen: false,
      }

    case CLOSE_SOCKET:
      return {
        ...state,
        isWebSocketConnectionOpen: false,
        urlWebSocket: '',
      }

    case PUSH_SOCKET_MESSAGE:
      return {
        ...state,
        messagesQueue: [...state.messagesQueue, action.payload.message],
      }

    case SET_NEW_MESSAGE_CURSOR:
      return {
        ...state,
        cursorNewMessage: action.cursor + 1,
      }

    case SHIFT_SOCKET_MESSAGE:
      if (state.messagesQueue.length) {
        return {
          ...state,
          messagesQueue: state.messagesQueue.slice(1),
          activeSubscriptions: updateActiveSubscriptions(
            state.activeSubscriptions,
            state.messagesQueue[0]
          ),
        }
      }
      return state

    default:
      return state
  }
}
