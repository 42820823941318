import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { fetchUserAlbumMainApi } from 'api/profile/fetchUserAlbumMainApi'

export const fetchProfileAlbumMainAction =
  (headers?: Api6NodeHeaders) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    if (
      getState().userReducer.albumIdMainLoading ||
      getState().userReducer.albumIdMain
    ) {
      return Promise.resolve()
    }

    return dispatch(fetchProfileAlbumPlainAction(headers))
  }

export type FetchProfileAlbumMainTypes = ReturnType<
  typeof fetchProfileAlbumPlainAction
>

export const FETCH_MAIN_ALBUM = 'FETCH_MAIN_ALBUM' as const

export const fetchProfileAlbumPlainAction = (headers?: Api6NodeHeaders) => ({
  type: FETCH_MAIN_ALBUM,
  promise: () => fetchUserAlbumMainApi(headers),
})
