import { Location } from 'history'

/**
 * Возвращает -N с конца массива до найденного path
 * Возвращает undefined, если нет совпадений или нет смысла искать
 */
export const findStepsBackCount = (history: Location[], path: string) => {
  /**
   * Почему если меньше двух ничего не делаем?
   */
  if (history.length < 2) {
    return
  }

  for (let i = history.length - 1; i >= 0; i--) {
    if (history[i].pathname === path) {
      return -history.length + i + 1
    }
  }
}
