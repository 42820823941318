import { indexPath } from 'components/paths'

export const createRandomArrayIndex = <T>(inputArray: T[]) =>
  Math.floor(Math.random() * inputArray.length)

export const findRandomItem = <T>(inputArray: T[]): T => {
  return inputArray[createRandomArrayIndex(inputArray)]
}

export const shuffle = <T>(inputArray: T[]) => {
  const resultArray = [...inputArray]
  resultArray.forEach((currentItem, currentIndex) => {
    const randomIndex = createRandomArrayIndex<T>(inputArray)
    resultArray[currentIndex] = resultArray[randomIndex]
    resultArray[randomIndex] = currentItem
  })
  return resultArray
}

export const notEmptyArray = <T>(array: T[]) => array && array.length > 0

export const isBaseUrlIncludesStartPath = (baseUrl: string) =>
  baseUrl !== indexPath

export const withoutNullValues = ({ value }: { value: unknown }) =>
  value !== null

export const splitOnFixedParts = <T>(
  input: T[],
  size = 100
): Array<Array<T>> => {
  const result = new Array<Array<T>>()
  while (input.length > 0) {
    result.push(input.splice(0, size))
  }
  return result
}

/**
 * "Срезаем" голову
 * @param array
 * @param limit
 * @returns
 */
export const limitArrayHead = <T>(array: T[], limit = 30) => {
  if (array.length > limit) {
    return array.slice(1)
  }
  return array
}
