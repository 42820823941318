import React, { FC } from 'react'

import { searchPath } from 'components/page/Search/Search.paths'
import RedirectWithStatus from 'components/system/RedirectWithStatus'

export const LegacyDeletedServicesRedirect: FC = () => {
  return (
    <RedirectWithStatus
      uid="redirect-legacy-service-delete"
      to={searchPath}
      status={301}
    />
  )
}
