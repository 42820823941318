import React, { FC, FunctionComponent } from 'react'

import { RouteComponentProps } from 'react-router'

import {
  loginPath,
  otherSocialNetworksPath,
} from 'components/page/Boarding/boarding.paths'
import {
  supportFormPath,
  supportFormSuccessPath,
} from 'components/page/Boarding/SupportForm/SupportForm.paths'
import {
  authorizationCaptchaPath,
  authorizationCaptchaSettingPath,
  unauthorizedCaptchaPath,
  unauthorizedCaptchaReasonsPath,
} from 'components/page/Captcha/Captcha.paths'
import {
  gdprRemovePath,
  settingsVerificationPath,
  gdprAcceptPath,
  gdprDeleteWarningPath,
  settingsPrivacyPath,
} from 'components/page/Settings/Settings.paths'
import { confirmExitPath } from 'components/page/Settings/Verification/Verification.paths'
import { socialAuthorizationSuccessPartPath } from 'components/page/SocialAuthorization/socialAuthorization.paths'
import { finalStepPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { pwaOfflinePath } from 'components/routes/pwa.path'
import { ErrorBoundaryWithRedirect } from 'components/system/ErrorBoundaryWithRedirect'
import { Replace } from 'components/system/Replace'
import { SentryRoute } from 'components/system/SentryRoute'
import {
  removeLocale,
  removeLocaleSaveSlash,
} from 'components/system/system.functions'
import { UnauthorizedRedirect } from 'components/system/UnauthorizedRedirect'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { isAccessibleRouteForUserWithRestrictions } from 'functions/isAccessibleRouteForUserWithRestrictions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { notExistsAnywhere } from 'functions/path'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { AuthorizedRedirect } from './AuthorizedRedirect'
import { CaptchaRedirect } from '../CaptchaRedirect'
import {
  appAgreementIndexPath,
  appConfidentialityPath,
  blockedByIpReasonsPath,
  blockedByPersonalPath,
  blockedByTrackReasonsPath,
  boundSocialAccountPath,
  editNamePath,
  enterEmailPath,
  indexPath,
  inlineNoticePath,
  logoutPath,
  registrationPath,
  settingsPath,
  supportChatPath,
  supportFormExtendPath,
} from '../paths'

const defaultRender = (
  props: RouteComponentProps,
  Component: FC<RouteComponentProps>,
  RenderComponent: FC<RouteComponentProps>
) => {
  if (RenderComponent) {
    return (
      <ErrorBoundaryWithRedirect uid="render-render">
        <RenderComponent {...props} />
      </ErrorBoundaryWithRedirect>
    )
  }

  return (
    <ErrorBoundaryWithRedirect uid="render-component">
      <Component {...props} />
    </ErrorBoundaryWithRedirect>
  )
}

const createSettingsUrl = () => {
  return mergeAllUrls(settingsPath)
}

export const CheckConditionRoute: FC<{
  component: FC<RouteComponentProps>
  render: FunctionComponent
  authorizedOnly?: boolean
  unauthorizedOnly?: boolean
  path: string
  exact?: boolean
}> = ({
  component: Component,
  render: RenderComponent,
  authorizedOnly,
  unauthorizedOnly,
  ...rest
}) => {
  const {
    authorizing,
    authorized,
    registrationVendor,
    location,
    profilePersonalBanned,
    realStatusNeeded,
    ipBlocked,
    showCaptcha,
    trackBlocked,
    gdprBlocked,
    additionalRegistrationNeeded,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorizing, authorized, registrationVendor },
      userReducer: { login },
      router: { location },
      errorReducer: {
        profilePersonalBanned,
        realStatusNeeded,
        ipBlocked,
        showCaptcha,
        trackBlocked,
        gdprBlocked,
        additionalRegistrationNeeded,
      },
    }) => ({
      authorizing,
      authorized,
      login,
      registrationVendor,
      location,
      profilePersonalBanned,
      realStatusNeeded,
      ipBlocked,
      showCaptcha,
      trackBlocked,
      gdprBlocked,
      additionalRegistrationNeeded,
    })
  )

  const layoutContext = useLayoutContext()
  const pathnameWithoutLocale = removeLocale(location.pathname)
  const pathnameWithoutLocaleAndSlash = removeLocaleSaveSlash(location.pathname)

  /**
   * TODO: rest содержит старые данные тогда как смена url уже произошла
   * надо разобраться
   */
  return (
    <SentryRoute
      {...rest}
      render={(props) => {
        const currentRender = () =>
          defaultRender(props, Component, RenderComponent)

        if (showCaptcha) {
          if (shouldShowCaptcha(pathnameWithoutLocale)) {
            return <CaptchaRedirect />
          }
          return currentRender()
        } else if (gdprBlocked) {
          const gdprAcceptUrl = mergeAllUrls(
            createSettingsUrl(),
            settingsPrivacyPath,
            gdprAcceptPath
          )
          if (shouldShowGdprAccept(pathnameWithoutLocale)) {
            return <Replace to={gdprAcceptUrl} uid="CheckConditionRoute-gdpr" />
          }
          return currentRender()
        } else if (ipBlocked) {
          if (
            shouldRedirectBlockedByIp(pathnameWithoutLocale) &&
            !isAccessibleRouteForUserWithRestrictions(pathnameWithoutLocale)
          ) {
            return (
              <Replace
                to={mergeAllUrls(layoutContext.baseUrl, blockedByIpReasonsPath)}
                uid="CheckConditionRoute-blockedByIp"
              />
            )
          }
          return currentRender()
        } else if (
          trackBlocked &&
          shouldShowBlockedByTrack(pathnameWithoutLocale) &&
          !isAccessibleRouteForUserWithRestrictions(pathnameWithoutLocale)
        ) {
          return (
            <Replace
              to={blockedByTrackReasonsPath}
              uid="CheckConditionRoute-trackBlocked"
            />
          )
        } else if (realStatusNeeded) {
          const realVerificationUrl = mergeAllUrls(
            createSettingsUrl(),
            settingsVerificationPath
          )
          if (
            shouldRedirectToReal(pathnameWithoutLocale, realVerificationUrl)
          ) {
            return (
              <Replace
                to={realVerificationUrl}
                uid="CheckConditionRoute-redirectToReal"
              />
            )
          }
          return currentRender()
        } else if (profilePersonalBanned) {
          if (shouldRedirectToProfilePersonalBanned(pathnameWithoutLocale)) {
            return (
              <Replace
                uid="check-condition-banned"
                to={blockedByPersonalPath}
                push
              />
            )
          }
          return currentRender()
        } else if (additionalRegistrationNeeded && !authorized) {
          if (
            pathnameWithoutLocaleAndSlash.startsWith(indexPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(loginPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(registrationPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(
              socialAuthorizationSuccessPartPath
            ) ||
            pathnameWithoutLocaleAndSlash.startsWith(boundSocialAccountPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(otherSocialNetworksPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(`/${finalStepPath}`) ||
            pathnameWithoutLocaleAndSlash.startsWith(enterEmailPath) ||
            pathnameWithoutLocaleAndSlash.startsWith(appAgreementIndexPath)
          ) {
            return currentRender()
          }
          if (registrationVendor) {
            return (
              <Replace
                to={mergeAllUrls(
                  socialAuthorizationSuccessPartPath,
                  registrationVendor
                )}
                uid="CheckConditionRoute-registrationVendor"
              />
            )
          }
          return (
            <Replace
              to={registrationPath}
              uid="CheckConditionRoute-registration"
            />
          )
        } else if (authorizedOnly && !authorized) {
          return <UnauthorizedRedirect uid="CheckConditionRoute" />
        } else if (unauthorizedOnly && authorized && !authorizing) {
          return <AuthorizedRedirect fromUnauthorizedOnlyUrl={true} />
        } else {
          return currentRender()
        }
      }}
    />
  )
}

const allowedLegalPaths = [appConfidentialityPath]

const allowedSupportFormPaths = [
  supportFormPath,
  supportFormExtendPath,
  supportFormSuccessPath,
]

const allowedBlockedIpPaths = [
  ...allowedSupportFormPaths,
  ...allowedLegalPaths,
  logoutPath,
  blockedByIpReasonsPath,
]

const shouldRedirectBlockedByIp = (pathname: string) =>
  notExistsAnywhere(allowedBlockedIpPaths, pathname)

const allowedBlockedByTrackPaths = [
  ...allowedSupportFormPaths,
  ...allowedLegalPaths,
  logoutPath,
  blockedByTrackReasonsPath,
  '/profile/settings/privacy/gdpr-delete-warning',
  '/profile/settings/privacy/gdpr-remove',
]

const shouldShowBlockedByTrack = (pathname: string) =>
  notExistsAnywhere(allowedBlockedByTrackPaths, pathname)

const gdprPaths = [
  gdprAcceptPath,
  gdprDeleteWarningPath,
  gdprRemovePath,
  confirmExitPath,
  pwaOfflinePath,
]

export const shouldShowGdprAccept = (pathname: string) =>
  notExistsAnywhere(gdprPaths, pathname)

function shouldShowCaptcha(pathname: string) {
  return (
    [
      unauthorizedCaptchaPath,
      unauthorizedCaptchaReasonsPath,
      authorizationCaptchaPath,
      authorizationCaptchaSettingPath,
    ].indexOf(pathname) === -1
  )
}

function shouldRedirectToProfilePersonalBanned(pathname: string) {
  return (
    pathname.indexOf(authorizationCaptchaPath) === -1 &&
    pathname.indexOf(supportFormExtendPath) === -1 &&
    pathname.indexOf(supportFormPath) === -1 &&
    pathname.indexOf(blockedByPersonalPath) === -1 &&
    pathname.indexOf(editNamePath) === -1 &&
    pathname.indexOf(appConfidentialityPath) === -1 &&
    pathname.indexOf('/profile/settings/privacy/gdpr-delete-warning') === -1 &&
    pathname.indexOf('/profile/settings/privacy/gdpr-remove') === -1 &&
    pathname.indexOf(logoutPath) === -1 &&
    pathname.indexOf(supportChatPath) === -1
  )
}

const shouldRedirectToReal = (pathname: string, realVerificationUrl: string) =>
  !pathname.startsWith(realVerificationUrl) &&
  ![
    mergeAllUrls(noticePath, UniNoticeId.viberVerification),
    mergeAllUrls(noticePath, UniNoticeId.telegramVerification),
    mergeAllUrls(noticePath, UniNoticeId.whatsAppVerification),
    mergeAllUrls(inlineNoticePath, UniNoticeId.viberVerification),
    mergeAllUrls(inlineNoticePath, UniNoticeId.telegramVerification),
    mergeAllUrls(inlineNoticePath, UniNoticeId.whatsAppVerification),
  ].includes(pathname)
