import { definitions, paths } from 'api/generated/photoline'

import { fetchQueryApi } from './fetchApi'

export type PhotolinePosts = definitions['PhotolinePosts']
export type PhotolinePostWithAuthor = definitions['PhotolinePostWithAuthor']
type Method = paths['/photoline/main']
type Query = Method['get']['parameters']['query']

export const fetchInitialPhotoLineApi = (limit?: number) => {
  return fetchQueryApi<PhotolinePosts, Query>('/photoline/main', {
    statusNames: 'hasVerifiedPhoto,online',
    limit,
  })
}
