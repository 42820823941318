import { postApi } from 'api/fetchApi'
import {
  operations as userSettings,
  definitions as userSettingsDefinitions,
} from 'api/generated/user_settings'

export const requestAccountRemovalWithReasonApi = (
  removalReason: userSettingsDefinitions['UserRemovalReason']['value'],
  secretOperation?: string
) => {
  return postApi<Response, Body>(
    '/settings/delete_account/requestAccountRemovalWithReason',
    {
      removalReason: { value: removalReason },
      secret: secretOperation,
    } as Body
  )
}

type Operation =
  userSettings['user_settings_request_account_removal_with_reason_requestAccountRemovalWithReason']
type Response = Operation['responses']['200']['schema']
type Body = Operation['parameters']['body']['body']
