export const mambaColor = '#ed6a0b'
export const balloonColor = 'rgb(224,96,101)'
export enum Colors {
  warning = '#e06065',
  warningHover = '#ca565b',
  warningActive = '#b34d51',
  warningBackground = '#fff9ea',
  link = '#46aae9',
  location = '#b2b2b2',
  white = '#ffffff',
  black = '#000000',
  yandexButton = 'rgb(255, 51, 51)',
  appleButton = 'rgb(30, 30, 30)',
  vkontakte = 'rgb(70, 128, 194)',
  vkConnect = 'rgb(39, 135, 245)',
  mailRu = 'rgb(2, 95, 249)',
  odnoklassniki = 'rgb(239, 131, 8)',
  specialGray = '#7e8fa2',
  darkGrey = '#8d8d8f',
  darkGreyText = 'rgb(46, 61, 73)',
  hoverColor = '#ffffff',
  linkHover = '#0076c0',
  linkSelected = '#f56323',
  backgroundLight = '#ecf0f6',
  backgroundHoverLight = '#d7e0ee',
  dark = '#2e3d49',
  checkboxActive = '#0065a4',
  note = '#9d9d9d',
  noteIcon = '#cdcccc',
  transparentSmallWhite = 'rgba(255, 255, 255, 0.26)',
  transparentFullWhite = 'rgba(255, 255, 255, 0)',
  navigationUserPanelBgColor = '#f4f8fd',
  transparentFull = 'transparent',
  informationNote = 'rgb(254, 213, 118)',
  iconBackgroundColorAquamarine = '#4ecfa8',
  iconBackgroundStartOnline = '#7af0cc',
  errorColor = '#bb1515',
  borderPhoneColor = '#e5e5e5',
  backgroundSelfMessage = '#6679bf',
  backgroundChatWillBeDeletedMessage = '#fceff0',
  lockedTagDefaultColor = '#f88b5e',
  lockedTagHoverColor = '#e87343',
  lockedTagActiveColor = '#e2622d',
  simpleTagWithActionActiveColor = '#cfd8e8',
  simpleTagWithActionFocusBoxShadow = '#c3daff',
  favouriteActive = '#fed576',
  editorBorder = '#f2eeee',
  disabledIcon = '#B1B9C3',
  noteColor = '#999999',
  locationAutoCompleteColor = '#8b999c',
  inputPlaceholderColor = '#757575',
  disabledCheckBoxWithChecked = '#d5d5d5',
  replyMessage = '#9ab6cd',
  tooltipInnerBorder = '#dddada',
  backgroundColorBannerDeleting = '#f5f7fa',
  lightBlue = '#7CD5F6',
  blackTransparentForty = 'rgba(0, 0, 0, .4)',
  blackTransparentSixty = 'rgba(0, 0, 0, .6)',
  blackTransparentSeventyFive = 'rgba(0, 0, 0, .75)',
  hoverProviderIconUploader = '#E4ECF5',
  endaNewMessage = 'red',
  moderation = '#FFD768',

  disabledIconColor = '#B1B9C3',
  favoritesSvgBackground = '#fed576',
  guaranteedShowsSvgBackground = '#41bade',
  invisibleSvgBgColor = '#2e3d49',

  inputPlaceholder = '#d9d9d9',
  textTeamo = '#1d2540',
  titleTeamo = '#333f5f',
  containerTeamo = '#eaf7ff',
  landingBanner = 'rgba(47, 61, 74, 0.5)',
  photoMask = 'rgba(255, 150, 102, 0.68)',
  contactScrollBar = '#d8d8d8',
  likeWrapper = 'rgba(236, 240, 246, 0.5)',
  yonjaBackground = '#ffc736',
  mailruBackground = '#334ffb',
  mailruMenu = '#333',
  mailruBorderColor = '#262626',
  mailMenuText = '#a6a6a6',
  textareaBackground = '#ebecef',
  ratingCardBackground = '#ececec',
  ratingModalBackground = '#eee',
  ratingMatchBackground = '#ccc',
  inputBorder = '#cfd8e3',
  supportOldBrowserText = '#263352',
  radioLabel = '#0079c0',
  radioLabelHover = '#00cfff',
  bannerMainColor = '#f90',
  bannerBackground = '#ffffd7',
  switchOverlayBackground = 'rgba(248, 139, 94, 0.6)',
  imgBorderColor = '#ffd11e',
  notFoundBackground = '#0C4589',

  waveColor = '#432a86',
  waveAgeColor = '#ff7c30',
}

// Старый дизайн для папки /presentational и прочего
export const disabledColor = '#e0e1e4'
export const placeholderColor = '#666'
export const backgroundTransparentDefault = 'rgba(0, 0, 0, .4)'
export const backgroundTransparentHover = 'rgba(0, 0, 0, .5)'
export const backgroundTransparentActive = 'rgba(0, 0, 0, .6)'
export const backgroundColorDropZoneHover = 'rgba(70, 170, 233, 0.15)'

export const wambaColor = '#e40075'
export const yonjaColor = '#e00171'
export const mailuColor = '#005ff9'
export const mabmaColor = '#ed6b0b'

export enum ColorGradients {
  checkerboardWhiteGray = 'linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to right, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%), linear-gradient(to bottom, #fff 50%, #444 50%)',
  transparentToDarkBlueGray = 'linear-gradient(to bottom, rgba(46, 61, 73, 0) 1%, rgba(46, 61, 73, 1))',
  softGrayStripes = 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',

  warmGold = 'linear-gradient(to bottom, #ffdf55, #ffbc3d)',
  deepAqua = 'linear-gradient(to bottom, #00c2b5, #0089a6)',
  deepLavender = 'linear-gradient(to bottom, #9d66dd, #6436b9)',
  vibrantPurple = 'linear-gradient(177deg, #b570f4 -28%, #7e3de6 149%)',
  aquaGreen = 'linear-gradient(to bottom, #67f1d3, #4ecfa8)',
  warmRed = 'linear-gradient(106.11deg, #f38084 11.21%, #e06065 88.79%)',
  fadeToMintRight = 'linear-gradient(to right,rgba(129, 239, 206, 0) 2%,#81efce)',
}

export enum BoxShadow {
  Default = '0 0 8px 0 rgba(0, 0, 0, 0.15)',
  Overlay = '0 0 0 9999em rgba(255, 255, 255, 0.4)',
  Soft = '0 0 8px 0 rgba(0, 0, 0, 0.03)',
  Medium = '0 0 8px 0 rgba(0, 0, 0, 0.12)',
  Strong = '0 5px 15px 0 rgba(0, 0, 0, 0.15)',
  Inset = 'inset 0 0 6px rgba(0, 0, 0, 0.5)',
  InsetSubtle = 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.2)',
  Raised = '0 2px 0 -1px rgba(0, 0, 0, 0.1)',
  BorderLight = '0 0 6px 0 rgba(0, 0, 0, 0.15)',
  BorderMedium = '0 0 6px 0 rgba(0, 0, 0, 0.16)',
  BorderStrong = '0 0 8px 0 rgba(0, 0, 0, 0.2)',
  BorderSoft = '0 0 5px 0 rgba(0, 0, 0, 0.15)',
}
