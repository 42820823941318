/* eslint-disable max-lines */
import {
  AppAction,
  AsyncAction,
  AsyncThunkAction2,
  AsyncThunkActionVoid,
  PlainAction,
} from 'actions/actions.types'
import { createFormBuilderSettings } from 'actions/createFormBuilderSettings'
import { toggleProfileUpdateAction } from 'actions/profile/toggleProfileUpdateAction'
import { AsyncThunkAction } from 'actions/types'
import { UpdateFieldAction } from 'actions/user/updateFieldAction'
import { Api6Error } from 'api/api.types'
import { ApiResult } from 'api/fetchApi'
import { ResponseWithFromBuilder } from 'api/FormBuilder'
import { paths as incognito } from 'api/generated/incognito'
import { paths as notifications_emails } from 'api/generated/notifications_emails'
import { paths as password_reset } from 'api/generated/password_reset'
import { paths } from 'api/generated/profile'
import { paths as user_settings } from 'api/generated/user_settings'
import { formBuilderHasErrors } from 'api/index'
import { profileWithDetailsApi } from 'api/profile/profileWithDetailsApi'
import { changeUserDefaultPhotoModeApi } from 'api/settings/changeUserDefaultPhotoModeApi'
import { profilePersonalApi, saveProfileApi } from 'api/settings/settingsApi'
import {
  changeUserEmailApi,
  fetchMainPhotoUpdateSettingApi,
  fetchSettingsContainerApi,
  profileApi,
  profileEmailApi,
  testPasswordApi,
} from 'api/userApi'
import { indexPath } from 'components/paths'
import { goBack, replace } from 'functions/router'
import { Phone } from 'reducers/authorization/Phone'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'

import { resetBlockedPersonalAction } from './errorAction'
import { fetchFeaturesAction } from './system/fetchFeaturesAction'

export const CHANGE_USER_DEFAULT_PHOTO_MODE = 'CHANGE_USER_DEFAULT_PHOTO_MODE'

/** @deprecated UserActionTypes */
export type UserActionType =
  | FetchPersonalSettingsAction
  | UpdateSettingsFieldAction

export const FETCH_PROFILE = 'FETCH_PROFILE'

export interface FetchProfileIfNeededAction
  extends AsyncAction<{
    name: string
    languageId: number
  }> {
  type: typeof FETCH_PROFILE
}

export const fetchProfileIfNeededAction =
  (field: string, statusNames?: []): AsyncThunkAction =>
  (dispatch, getState) => {
    if (
      field === 'languageId' &&
      !getState().settingsReducer.profile.languageId
    ) {
      dispatch({
        type: FETCH_PROFILE,
        promise: () => profileApi(statusNames),
      })
    }
  }

export const CHECK_PASSWORD_PROFILE = 'CHECK_PASSWORD_PROFILE'

export interface FetchCheckPasswordProfileAction
  extends AsyncAction<
    password_reset['/users/password/testPassword']['post']['responses']['200']['schema']
  > {
  type: typeof CHECK_PASSWORD_PROFILE
}

export const fetchCheckPasswordProfileAction = (password: string) => ({
  type: CHECK_PASSWORD_PROFILE,
  promise: () => testPasswordApi(password),
})

export const FETCH_PROFILE_DETAILS = 'FETCH_PROFILE_DETAILS'

interface ProfileWithDetailsAction
  extends AsyncAction<
    paths['/profiles/my']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_PROFILE_DETAILS
}

export const profileWithDetailsAction =
  (): AsyncThunkAction2 => (dispatch, getState) => {
    if (!getState().userReducer.details) {
      return dispatch({
        type: FETCH_PROFILE_DETAILS,
        promise: () => profileWithDetailsApi(),
      })
    }
  }

export const fetchPersonalSettingsIfNeededAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    if (
      !getState().settingsReducer.personalSettingsLoaded &&
      getState().authorizationReducer.profile?.isReal
    ) {
      dispatch(fetchPersonalSettingsAction())
    }
  }

export const FETCH_MAIN_PHOTO_UPDATE_SETTING = 'FETCH_MAIN_PHOTO_UPDATE_SETTING'

export interface FetchMainPhotoUpdateSettingAction
  extends AsyncAction<
    user_settings['/settings/main_photo_mode/mode']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_MAIN_PHOTO_UPDATE_SETTING
}

export const fetchMainPhotoUpdateSettingAction = () => ({
  type: FETCH_MAIN_PHOTO_UPDATE_SETTING,
  promise: () => fetchMainPhotoUpdateSettingApi(),
})

export const RESET_LOCATION_SETTINGS = 'RESET_LOCATION_SETTINGS'

export interface ResetLocationSettingsAction extends PlainAction {
  type: typeof RESET_LOCATION_SETTINGS
}

export const FETCH_PERSONAL_SETTINGS = 'FETCH_PERSONAL_SETTINGS'

export interface FetchPersonalSettingsAction
  extends AsyncAction<ResponseWithFromBuilder> {
  type: typeof FETCH_PERSONAL_SETTINGS
}

export const fetchPersonalSettingsAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    dispatch({
      type: FETCH_PERSONAL_SETTINGS,
      promise: () => profilePersonalApi(getState().systemReducer.locale),
    })
  }

export const UPDATE_SETTING_FIELD_ACTION = 'UPDATE_SETTING_FIELD_ACTION'

export interface UpdateSettingsFieldAction extends AsyncAction {
  type: typeof UPDATE_SETTING_FIELD_ACTION
  name: keyof SettingsForm
  value: string
}

export const updateSettingsFieldAction = (
  name: string,
  value:
    | string
    | number
    | boolean
    | Record<string, number | string | null>
    | Phone
) => ({
  type: UPDATE_SETTING_FIELD_ACTION,
  name,
  value,
})

export const SAVE_SETTING_FIELD_LEGACY_ACTION =
  'SAVE_SETTING_FIELD_LEGACY_ACTION'

export interface SaveFormBuilderSettingsFieldAction
  extends AsyncAction<ResponseWithFromBuilder> {
  type: typeof SAVE_SETTING_FIELD_LEGACY_ACTION
  field: keyof SettingsForm
}

/**
 * Старый способ сохранить настройки пользователя. Нужно будет переписать на новый по аналогии
 * @deprecated
 * @param field
 * @param successPath
 */
export const saveFormBuilderSettingsFieldAction =
  (field: string, successPath?: string): AsyncThunkAction =>
  (dispatch, getState) => {
    const { settingsReducer, systemReducer, errorReducer } = getState()
    dispatch({
      type: SAVE_SETTING_FIELD_LEGACY_ACTION,
      field,
      promise: async () => {
        const { settings, tag } = createFormBuilderSettings(
          field,
          settingsReducer.form
        )

        const json = await saveProfileApi(tag, settings, systemReducer.locale)
        if (json.formBuilder && !formBuilderHasErrors(json.formBuilder)) {
          if (errorReducer.profilePersonalBanned) {
            dispatch(resetBlockedPersonalAction())
            dispatch(replace(indexPath))
            return
          } else if (successPath) {
            dispatch(replace(successPath))
          } else {
            dispatch(toggleProfileUpdateAction(true))
            dispatch(goBack())
            if (field === 'location') {
              dispatch(fetchFeaturesAction())
            }
          }
          dispatch(resetSettingsAction()) // ?
        }
        return json
      },
    })
  }

export const SAVE_SETTING_FIELD_EMAIL_ACTION = 'SAVE_SETTING_FIELD_EMAIL_ACTION'

export interface SaveEmailFieldAction
  extends AsyncAction<ResponseWithFromBuilder> {
  type: typeof SAVE_SETTING_FIELD_EMAIL_ACTION
  field: keyof SettingsForm
}

export const saveEmailFieldAction =
  (
    field: string,
    successPath: string
  ): AsyncThunkActionVoid<ApiResult<{ ok: boolean }>> =>
  async (dispatch, getState) => {
    const {
      settingsReducer: {
        form: { email, passwordCheck },
      },
      settingsFieldEmail: { isRequiredPassword },
    } = getState()

    const password = isRequiredPassword ? passwordCheck : null

    const { result: json } = await dispatch({
      type: SAVE_SETTING_FIELD_EMAIL_ACTION,
      field,
      promise: () => changeUserEmailApi(email, password),
    } as AppAction)

    if (json?.ok) {
      dispatch(replace(successPath) as AppAction)
    }
  }

export const FETCH_EMAIL_PROFILE = 'FETCH_EMAIL_PROFILE'

export interface FetchProfileEmailAction
  extends AsyncAction<{
    email: string
    domain: string
  }> {
  type: typeof FETCH_EMAIL_PROFILE
}

export const fetchProfileEmailAction = () => ({
  type: FETCH_EMAIL_PROFILE,
  promise: () => profileEmailApi(),
})

export const RESET_SETTINGS = 'RESET_SETTINGS'

export interface ResetSettingsAction extends PlainAction {
  type: typeof RESET_SETTINGS
}

export const resetSettingsAction = () => ({
  type: RESET_SETTINGS,
})

export const FETCH_USER_STATISTICS = 'FETCH_USER_STATISTICS'

interface FetchStatisticsAction
  extends AsyncAction<{ day: number; month: number }> {
  type: typeof FETCH_USER_STATISTICS
}

export const FETCH_USER_SETTINGS_CONTAINER = 'FETCH_USER_SETTINGS_CONTAINER'

export interface FetchSettingsContainerAction
  extends AsyncAction<
    [
      {
        body: notifications_emails['/notifications/email_notifications']['get']['responses']['200']['schema']
        code: 200 | 401 | 403
      },
      {
        body: incognito['/incognito']['get']['responses']['200']['schema']
        code: 200 | 401 | 403
      },
      {
        body: user_settings['/settings/profile_visibility/status']['get']['responses']['200']['schema']
        code: 200 | 401 | 403
      },
      {
        body: user_settings['/settings/vip/settings']['get']['responses']['200']['schema'] &
          Api6Error
        code: 200 | 401 | 403
      },
      {
        body: user_settings['/settings/messenger_filter/settings']['get']['responses']['200']['schema']
        code: 200 | 401 | 403
      },
      {
        body: user_settings['/settings/main_photo_mode/mode']['get']['responses']['200']['schema']
        code: 200 | 401 | 403
      }
    ]
  > {
  type: typeof FETCH_USER_SETTINGS_CONTAINER
}

export const fetchSettingsContainerAction = () => ({
  type: FETCH_USER_SETTINGS_CONTAINER,
  promise: () => fetchSettingsContainerApi(),
})

export const changeUserDefaultPhotoModeAction =
  (mode: string): AsyncThunkAction =>
  (dispatch) => {
    dispatch({
      type: CHANGE_USER_DEFAULT_PHOTO_MODE,
      promise: async () => {
        const json = await changeUserDefaultPhotoModeApi(mode)
        if (json.ok) {
          dispatch(goBack())
        }
        return json
      },
    })
  }

export type UserActionTypes =
  | ProfileWithDetailsAction
  | FetchStatisticsAction
  | UpdateFieldAction
