import { Phone } from 'reducers/authorization/Phone'

type Value = string | boolean | number | Phone

export interface UpdateFieldAction {
  name: string
  value: Value
}

export interface FormState<T> {
  form: Record<keyof T, Value>
}

export const defaultUpdateFieldReducer = <T extends FormState<unknown>>(
  state: T,
  action: UpdateFieldAction
) => {
  const { name, value } = action
  return {
    ...state,
    form: {
      ...state.form,
      [name]: value,
    },
  } as unknown as T
}
