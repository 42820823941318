import { AsyncThunkAction } from 'actions/types'
import { goBack, push } from 'functions/router'

/**
 * @deprecated src/common/actions/route/routeAction.ts:19
 */
export const goBackAction = (): AsyncThunkAction => (dispatch) => {
  // https://redmine.mamba.ru/issues/104994
  // defaultModalOverlayClickHandler.bind(this)
  dispatch(goBack())
}

export const SET_STATUS_CODE = 'SET_STATUS_CODE'

interface SetStatusCodeAction {
  type: typeof SET_STATUS_CODE
  value: number
}

export const setStatusCodeAction = (value: number) => ({
  type: SET_STATUS_CODE,
  value,
})

export const SHOW_NOT_FOUND = 'SHOW_NOT_FOUND'

interface ShowNotFoundAction {
  type: typeof SHOW_NOT_FOUND
  value: boolean
}

export const showNotFoundAction =
  (value: boolean): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      systemReducer: { showNotFound },
      authorizationReducer: { authorizing },
    } = getState()

    if (showNotFound !== value && !authorizing) {
      // console.log('showNotFoundAction', new Error())
      return dispatch({
        type: SHOW_NOT_FOUND,
        value,
      })
    }
  }

export const pushBaseUrl = (): AsyncThunkAction => (dispatch, getState) => {
  return dispatch(push(getState().systemReducer.baseUrl))
}

export type SystemRouteTypes = SetStatusCodeAction | ShowNotFoundAction
