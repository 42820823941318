import cookie from 'js-cookie'

import { sPostCookie } from 'common/constants'

export enum Cookies {
  sPost = 's_post',
  locale = 'locale',
}

const removeCookie = () => (domain: string) => {
  cookie.remove(sPostCookie, { domain })
  cookie.remove(sPostCookie, { domain: '.' + domain })
}

/**
 * https://redmine.mamba.ru/issues/116221
 */
export const removeAllSPosts = () => {
  const hostParts = location.host.split('.')
  const domains = hostParts.reduce(
    (acc: string[], current, index) => [
      ...acc,
      hostParts.slice(index).join('.'),
    ],
    []
  )
  domains.forEach(removeCookie())
}
