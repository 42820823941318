import { RefObject, useEffect, useRef } from 'react'

import { ResizeObserver } from '@juggle/resize-observer'

export const useResizeObserver = <T extends HTMLElement>(
  nodeRef: RefObject<T | null>,
  handleResize: ResizeObserverCallback
) => {
  const node = nodeRef.current

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize)

    if (node) {
      resizeObserver.observe(node)
    }

    return () => {
      if (node) {
        resizeObserver.unobserve(node)
      } else {
        resizeObserver.disconnect()
      }
    }
  }, [handleResize, node])
}

export const useResizeObserverUpdate = <T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void
) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const element = ref?.current

    if (!element) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0])
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [callback, ref])

  return ref
}

export const useMutationObserver = <T extends HTMLElement>(
  nodeRef: RefObject<T | null>,
  callback: () => void
) => {
  const node = nodeRef.current

  useEffect(() => {
    const observer = new MutationObserver(callback)

    if (node) {
      observer.observe(node, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
      })
    }

    return () => {
      if (node) {
        observer.disconnect()
      }
    }
  }, [callback, node])
}
