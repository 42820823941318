import { v4 as uuidV4 } from 'uuid'

import { AsyncThunkAction } from 'actions/types'
import {
  addNoticeShortCutCollectionAction,
  pushShortCutNotificationAction,
} from 'actions/uninotice/noticeShortCutAction'
import { toggleInlineNoticeAction } from 'actions/uninotice/toggleInlineNoticeAction'
import { addUniNoticeToCollectionAction } from 'actions/uninotice/uniNoticeAction'
import { UniNotice } from 'api/uninotice/UniNotice'
import { UniNoticePayload } from 'api/uninotice/uninotice.types'
import {
  displayContainer,
  displayInline,
  notification,
  shortcut,
} from 'common/constants'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import {
  UniNoticeId,
  uniNoticesIdsListInBottomSheet,
} from 'components/uninotice/uninotice.types'
import { replaceUrlNoticeId } from 'components/uninotice/uniNoticeIdList'
import { isNoticeNewMessage } from 'functions/isNoticeNewMessage'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'

const resolveNoticeId = (uniNotice: UniNotice) =>
  isNoticeNewMessage(uniNotice)
    ? uniNotice.noticeId + '-' + uuidV4().substring(0, 8)
    : uniNotice.noticeId

export const displayUniNoticeAction =
  (uniNotice: UniNotice): AsyncThunkAction =>
  (dispatch, getState) => {
    const id = resolveNoticeId(uniNotice)

    /**
     * Порядок для шорката такой
     * Сначала добавляем в общую коллекцию.
     * Потом помещаем в очередь на показ.
     */
    if (uniNotice.type === shortcut) {
      dispatch(addNoticeShortCutCollectionAction(uniNotice, id))
      dispatch(pushShortCutNotificationAction(uniNotice.noticeId, id))
    } else {
      /**
       * Если было несколько запросов, то action вызовется несколько раз.
       * Но в store добавится только один благодаря Record<string, object>
       */
      dispatch(addUniNoticeToCollectionAction(uniNotice, id))
    }

    if (uniNotice.type === notification) {
      switch ((uniNotice.payload as UniNoticePayload).appearance!.display) {
        /**
         * Встраиваемый блок в страницу.
         */
        case displayInline:
          dispatch(toggleInlineNoticeAction(uniNotice.noticeId, true))
          break

        /**
         * Модальное окно.
         * (возможно в дальнейшем будут кейсы, когда с сервера будет сыпаться сразу несколько нотисов
         * заточенных на показ модалок. в таком случае надо будет отдельно выстраивать очередь.
         * на данный момент таких кейсов нет, поэтому сразу роут на нужный урл.)
         */
        case displayContainer:
          const {
            systemReducer: { baseUrl, redesign },
          } = getState()

          /**
           * В рамках редизайна, некоторые нотисы нужно отображать в bottom sheet.
           * В таком случае, никакого роутинга.
           */
          if (
            redesign &&
            uniNoticesIdsListInBottomSheet.includes(
              uniNotice.noticeId as UniNoticeId
            )
          ) {
            return
          }

          /**
           * Есть notice который надо replace делать.
           * По-идее везде должен быть replace, иначе событие,
           * которые вызвало, будет постоянно повторяться
           */
          /**
           * https://youtrack.mamba.ru/issue/M-1884
           * Оставил заглушку для profileBanned, будем выяснять в чем дело.
           * Перенес в отдельный hook.
           * Возможно пересмотрим работу с ошибками.
           */
          if (id === UniNoticeType.user_banned) {
            return
          } else if (replaceUrlNoticeId.indexOf(uniNotice.noticeId) > -1) {
            dispatch(replace(mergeAllUrls(baseUrl, noticePath, id)))
          } else {
            dispatch(push(mergeAllUrls(baseUrl, noticePath, id)))
          }
          break
      }
    }
  }
