export const TOP_LOCATION_LIMIT = 26

export const seoGeoLandingPath = '/landing'
export const allRegionsPath = 'all-regions'
export const allCitiesPath = 'all-cities'

export const MALE = 'M'
export const ANY = 'N'
export const FEMALE = 'F'

export const GUYS = 'guys'
export const GIRLS = 'girls'
export const GAYS = 'gays'
export const LESBIANS = 'lesbians'

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
  LESBIAN = 'FF',
  GAY = 'MM',
}

export interface GenderItem {
  id: Gender
  name: string
  fieldName: string
  path: string
}

export const genderSearchParameters: Record<string, string> = {
  guys: Gender.MALE,
  girls: Gender.FEMALE,
  gays: Gender.GAY,
  lesbians: Gender.LESBIAN,
}

export const genderList: GenderItem[] = [
  {
    id: Gender.MALE,
    name: 'genderM',
    fieldName: 'search.settings.gender.M',
    path: 'guys',
  },
  {
    id: Gender.FEMALE,
    name: 'genderF',
    fieldName: 'search.settings.gender.F',
    path: 'girls',
  },
]

export const genderAlternative: GenderItem[] = [
  {
    id: Gender.GAY,
    name: 'genderGay',
    fieldName: 'search.settings.gender.GAY',
    path: 'gays',
  },
  {
    id: Gender.LESBIAN,
    name: 'genderLesbian',
    fieldName: 'search.settings.gender.LESBIAN',
    path: 'lesbians',
  },
]
