import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import { styled } from '@linaria/react'

import { ScrollbarRefProps } from './Scrollbar.types'

export const scrollbarHeightDesign = 80

export const Scrollbar = forwardRef<ScrollbarRefProps, ScrollbarProps>(
  ({ positionFixed = false }, ref) => {
    const [parentHeight, setParentHeight] = useState<number>(0)
    const [scrollHeight, setScrollHeight] = useState<number>(0)
    const [scrollTop, setScrollTop] = useState<number>(0)

    const dimensionMoreNull = useMemo(
      () =>
        parentHeight > 0 && scrollHeight > 0 && parentHeight !== scrollHeight,
      [parentHeight, scrollHeight]
    )

    const heightBar = useMemo(() => {
      if (dimensionMoreNull) {
        return (parentHeight * scrollbarHeightDesign) / scrollHeight
      }
    }, [dimensionMoreNull, parentHeight, scrollHeight])

    useImperativeHandle(ref, () => {
      return {
        scroll: (scrollTop) => {
          setScrollTop(scrollTop)
        },
        update: (scrollHeight, parentHeight) => {
          if (scrollHeight && parentHeight) {
            setParentHeight(parentHeight)
            setScrollHeight(scrollHeight)
          }
        },
      }
    })

    if (dimensionMoreNull) {
      const _scrollTop = (scrollTop * scrollbarHeightDesign) / scrollHeight

      return (
        <Parent positionFixed={positionFixed}>
          <Indicator
            style={{
              height: `${heightBar}px`,
              transform: `translateY(${_scrollTop}px)`,
            }}
          />
        </Parent>
      )
    }

    // TODO: loader|shimmer?
    return null
  }
)

type ScrollbarProps = { positionFixed?: boolean }

export const Parent = styled.div<ScrollbarProps>`
  width: var(--spacing-4px);
  height: var(--spacing-80px);
  border-radius: var(--spacing-2px);
  flex-shrink: 0;
  background-color: var(--warm-opacity-default);
  overflow: hidden;
  position: ${({ positionFixed }) => (positionFixed ? 'fixed' : 'absolute')};
  top: 50%;
  transform: translateY(-50%);

  html[dir='ltr'] & {
    right: var(--spacing-4px);
  }

  html[dir='rtl'] & {
    left: var(--spacing-4px);
  }
`

export const Indicator = styled.div`
  border-radius: var(--spacing-2px);
  background-color: var(--warm-hard);
  width: 100%;
`
