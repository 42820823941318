import { css } from 'styled-components'

import { fontFamily } from 'common/constants'
import { PaperCss } from 'components/presentational/separator'

export const ModalCss = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: 10px 0;
  ${PaperCss};
  padding: 0;
  border-width: 0;
  font-size: 15px;
  font-family: ${fontFamily};
  border-radius: 10px;
`
