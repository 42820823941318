import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { fetchQueryApi } from 'api/fetchApi'
import { paths } from 'api/generated/abtests'

export enum AbTestName {
  /** Пользователь должен загрузить фото в фотоальбом */
  PhotoUpload = 'photoUpload',
  FeaturedPhotosHits = 'featuredPhotosHits',
  PromoCodeReg = 'promocodereg',
  /** Список контактов без блока Новые контакты */
  NewContactsBlock = 'NewContactsBlock',
  MessagesFromLiked = 'messages_from_liked',
  /** https://youtrack.mamba.ru/issue/M-4013 */
  HideAdvBlock = 'hideAdvBlocks',
  /** https://youtrack.mamba.ru/issue/M-3377 */
  НitsHiddenData = 'hitsHiddenData',
  BestOptionForRequestingLocation = 'bestOptionForRequestingLocation',
}

export const ACTIVE_AB_TESTS = [
  AbTestName.HideAdvBlock,
  AbTestName.НitsHiddenData,
  AbTestName.BestOptionForRequestingLocation,
  AbTestName.MessagesFromLiked,
]

type FetchAbTestsGroupListMethod = paths['/abtests/my/groups-list']['get']
type FetchAbTestsGroupListQuery =
  FetchAbTestsGroupListMethod['parameters']['query']
type FetchAbTestsGroupListResult =
  FetchAbTestsGroupListMethod['responses']['200']['schema']

export const fetchAbTestsGroupsListApi = (
  testNames: AbTestName[],
  headers?: Api6NodeHeaders
) =>
  fetchQueryApi<FetchAbTestsGroupListResult, FetchAbTestsGroupListQuery>(
    '/abtests/my/groups-list',
    { testNames },
    { headers }
  )

export const fetchAbTestsMyGroupApi = (
  testName: AbTestName,
  headers?: Api6NodeHeaders
) => fetchQueryApi('/abtests/my/group', { testName }, { headers })

type FetchAbTestsGroupMethod = paths['/abtests/group']['get']
type FetchAbTestsGroupResult =
  FetchAbTestsGroupListMethod['responses']['200']['schema']

export const fetchAbTestsGroupApi = (
  testName: AbTestName,
  headers?: Api6NodeHeaders
) =>
  fetchQueryApi<
    FetchAbTestsGroupResult,
    FetchAbTestsGroupMethod['parameters']['query']
  >(
    '/abtests/group',
    {
      // @ts-expect-error: неполнота сгенерированных типов open-api
      testName,
    },
    { headers }
  )
