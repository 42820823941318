import { AppDispatch, AsyncAction } from 'actions/actions.types'
import { fetchBundleNameApi } from 'api/internal/fetchBundleNameApi'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { tryUpdatePwa } from 'functions/pwa/tryUpdatePwa'

export const CHECK_BUNDLE_UPDATE = 'CHECK_BUNDLE_UPDATE'

export interface CheckBundleUpdateAction extends AsyncAction {
  type: typeof CHECK_BUNDLE_UPDATE
}
export const checkBundleUpdateAction = () => async (dispatch: AppDispatch) => {
  await dispatch({
    type: CHECK_BUNDLE_UPDATE,
    promise: () => fetchBundleNameApi(),
  })

  if (isPwaSupported()) {
    console.info('Check PWA update', new Date())

    /** Запускаем установку апдейта */
    tryUpdatePwa(() => dispatch(setShouldAppReloadAction(true))).catch(
      (error) => console.error('PWA updated failed', error)
    )
  }
}

export const SHOULD_APP_RELOAD = 'SHOULD_APP_RELOAD' as const

export const setShouldAppReloadAction = (value: boolean) => ({
  type: SHOULD_APP_RELOAD,
  value,
})
