import cookie from 'js-cookie'

import { fetchApi } from 'api/fetchApi'
import { paths } from 'api/generated/start_screen'
import { fetchApi5 } from 'api/index'
import { DEFAULT_LOCALE } from 'common/constants'
import { buildQuery } from 'functions/queryString'

export const loginApi = async (
  login: string,
  password: string,
  computerLevel: boolean,
  partnerId: number,
  locale = DEFAULT_LOCALE,
  isBindingWithSocialNetworkAccount?: boolean
) => {
  await loginBuilderApi(locale)

  const { crossAuthTokenId } = await crossAuthApi(
    login,
    password,
    computerLevel,
    partnerId
  )

  const token = await tokenLoginApi(
    crossAuthTokenId,
    locale,
    isBindingWithSocialNetworkAccount
  )
  const { result } = await fetchStartScreenApi()

  return {
    ...result,
    ...token,
    startScreen: result?.startScreen ? result?.startScreen : null,
  }
}

const loginBuilderApi = (locale: string) => {
  return fetchApi5(`/login/builder/?lang_id=${locale}`)
}

const tokenLoginApi = (
  token: string,
  locale: string,
  isBindingWithSocialNetworkAccount?: boolean
) => {
  const s_post = cookie.get('s_post')
  return fetchApi5(
    `/login/builder?crossAuthTokenId=${token}&lang_id=${locale}&reqType=json`,
    {
      body: JSON.stringify({
        crossAuthTokenId: token,
        s_post,
        isBindingWithSocialNetworkAccount,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  )
}

const crossAuthApi = async (
  login: string,
  password: string,
  computerLevel: boolean,
  partnerId: number
) => {
  const s_post = cookie.get('s_post')
  const response = await fetch(
    `${process.env.AUTH_HOST}/token.json?partnerId=${partnerId}`,
    {
      body: buildQuery({
        login,
        password,
        s_post: s_post!,
        domain: '',
        level: computerLevel,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    }
  )
  return await response.json()
}

type Post = paths['/start_screen']['get']
type Response = Post['responses']['200']['schema']

const fetchStartScreenApi = () => fetchApi<Response>('/start_screen')
