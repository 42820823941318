import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { pushModalAction } from 'actions/route/routeAction'
import { fetchAskPhotoApi } from 'api/rating/fetchAskPhotoApi'
import {
  albumPath,
  uploadPhotosPath,
} from 'components/page/UploaderPhotos/Uploader.paths'
import {
  isToSoonToShowPhotoUploader,
  setNextTimeToShowPhotoUploader,
} from 'hooks/useShowPhotoUploader/useShowPhotoUploader.functions'

export const FETCH_ASK_PHOTO = 'FETCH_ASK_PHOTO'

export interface FetchAskPhotoAction
  extends AsyncAction<Awaited<ReturnType<typeof fetchAskPhotoApi>>['result']> {
  type: typeof FETCH_ASK_PHOTO
}

export const fetchAskPhotoNeededAction = (): FetchAskPhotoAction => ({
  type: FETCH_ASK_PHOTO,
  promise: () => fetchAskPhotoApi(),
})

export const checkIfAskPhotoNeededAction =
  (): AsyncThunkAction<void> => async (dispatch, getState) => {
    const {
      userReducer: { userId },
    } = getState()

    const tooEarlyToFetchData = isToSoonToShowPhotoUploader(userId!)

    if (!tooEarlyToFetchData) {
      const { result } = await dispatch(fetchAskPhotoNeededAction())

      if (result?.timeRepeat) {
        // Сохраняем дату, до которой не нужно делать запрос
        setNextTimeToShowPhotoUploader(userId!, result.timeRepeat)
      }

      if (result?.showUploader) {
        dispatch(pushModalAction(uploadPhotosPath, albumPath))
      }
    }
  }
