import { details, features } from 'api/api.constants'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { postApi } from 'api/fetchApi'
import { paths as systemSettings } from 'api/generated/system_settings'

type Response =
  systemSettings['/features']['post']['responses']['200']['schema']
type Body = systemSettings['/features']['post']['parameters']['body']['body']

/**
 * Для данного запроса надо передавать значение brand, но не имеет значение какой
 * Поэтому передаем brand=Mamba
 * https://youtrack.mamba.ru/issue/M-5137#focus=Comments-4-34335.0-0
 */
export const fetchFeaturesApi = (headers?: Api6NodeHeaders) => {
  const _headers = headers
    ? {
        ...headers,
        'Mamba-Client': JSON.stringify({
          ...(headers?.['Mamba-Client']
            ? JSON.parse(headers['Mamba-Client'] as string)
            : {}),
          brand: 'Mamba',
        }),
      }
    : {
        'Mamba-Client': {
          brand: 'Mamba',
        },
      }

  return postApi<Response, Body>(
    '/features',
    {
      features,
      details,
    },
    {
      headers: _headers as Api6NodeHeaders,
    }
  )
}
