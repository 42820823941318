import { AppDispatch, AsyncAction } from 'actions/actions.types'
import { AppGetState } from 'actions/actions.typesNext'
import { fetchMetaDataApi, MetaApiResult } from 'api/system/fetchMetaDataApi'

export const FETCH_META = 'FETCH_META'

export interface FetchMetaDataAction extends AsyncAction<MetaApiResult> {
  type: typeof FETCH_META
}

export const fetchMetaDataAction =
  (path: string) => (dispatch: AppDispatch, getState: AppGetState) => {
    if (!getState().systemReducer.metaLoading) {
      return dispatch({
        type: FETCH_META,
        promise: () => fetchMetaDataApi(path),
      })
    }
    return Promise.resolve()
  }
