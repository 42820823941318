import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { addAppsFlyerWebIdApi } from 'api/appsflyer/addAppsFlyerWebIdApi'

export const ADD_APPS_FLYER_USER_ID = 'ADD_APPS_FLYER_USER_ID' as const

export const addAppsFlyerUserIdThunkAction =
  (appsFlyerUserId: string) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      miscellaneous: { lastAppsFlyerUserIdSentToServer },
    } = getState()

    if (appsFlyerUserId === lastAppsFlyerUserIdSentToServer) {
      return
    }

    return dispatch(addAppsFlyerUserIdAction(appsFlyerUserId))
  }

export const addAppsFlyerUserIdAction = (webId: string) => ({
  type: ADD_APPS_FLYER_USER_ID,
  promise: () => addAppsFlyerWebIdApi(webId),
  webId,
})
