import { FC } from 'react'

/**
 * Нужен, чтобы модал существовал и не было 404.
 * Конкретные условия для правильного сплита описываются например
 * в src/common/components/page/UserProfile/UserProfileLayout.tsx:33
 * @constructor
 */
export const ThenPseudoModal: FC = () => {
  return null
}
