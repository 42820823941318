import { postApi } from 'api/fetchApi'
import {
  operations as userSettings,
  definitions as userSettingsDefinitions,
} from 'api/generated/email_operations'

export const removeAccountApi = (
  removalReason: userSettingsDefinitions['UserRemovalReason']['value'],
  code: Body['code'],
  mailstat: string
) => {
  return postApi<Response, Body>('/settings/removeAccount', {
    removalReason: { value: removalReason },
    code,
    mailstat,
  })
}

type Operation =
  userSettings['user_settings_remove_account_with_reason_removeAccountWithReason']
type Response = Operation['responses']['204']['schema']
type Body = Operation['parameters']['body']['body']
