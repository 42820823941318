export const consoleTime = (_label) => {
  if (process.env.production) {
  } else {
    // console.time(label)
  }
}

export const consoleTimeEnd = (_label) => {
  if (process.env.production) {
  } else {
    // console.timeEnd(label)
  }
}

export const consoleLog = (...debugArguments) => {
  if (process.env.production) {
  } else {
    console.info(...debugArguments)
  }
}

export const consoleError = (...debugArguments) => {
  if (process.env.production) {
  } else {
    console.error(...debugArguments)
  }
}
