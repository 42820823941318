import React from 'react'

import loadable from '@loadable/component'

import { LOCALES_REGEXP } from 'common-constants/locale'
import { AppRoute } from 'components/components.types'
import { AllRegionsAndCitiesLoadable } from 'components/page/Landing/AllCities/AllRegionsAndCitiesLoadable'
import {
  allCitiesPath,
  allRegionsPath,
} from 'components/page/Landing/constants'
import { createGenericSeoLandingRedirectUrl } from 'components/page/Landing/createGenericSeoLandingRedirectUrl'
import { LandingViewLoadable } from 'components/page/Landing/LandingView/LandingViewLoadable'
import { seoLandingPath } from 'components/paths'
import { FoundComponent } from 'components/system/FoundComponent'
import { withLoader } from 'components/system/loadable/withLoader'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { seriousRelationshipFullPath } from './Special/Special.paths'

const deprecatedPaths = [
  `/:locale(${LOCALES_REGEXP})/:country/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)?`,
  `/:locale(${LOCALES_REGEXP})/:country/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)?`,
  `/:locale(${LOCALES_REGEXP})/:country-:countryId(\\d+)/:gender(girls|guys|gays|lesbians)?`,
  `/:locale(${LOCALES_REGEXP})/:country-:countryId(\\d+)/${allCitiesPath}`,
]

const DeprecatedLocation = () => {
  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )
  console.error('Landing Index: deprecated URLs redirect')
  return (
    <RedirectWithStatus
      to={mergeAllUrls(
        seoLandingPath,
        createGenericSeoLandingRedirectUrl(pathname)
      )}
      status={301}
      uid="DeprecatedLocation"
    />
  )
}

export const deprecatedLocationRoutes = deprecatedPaths.map((path) => ({
  path,
  component: DeprecatedLocation,
  exact: false,
  shouldCheck: true,
}))

const genericPaths = [
  '/:country([\\w\\-]+)/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)',
  '/:country([\\w\\-]+)/:region/:city-:cityId(\\d+)',
  '/:country([\\w\\-]+)/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)',
  '/:country([\\w\\-]+)/:region-:regionId(\\d+)',
  '/:country([\\w\\-]+)-:countryId(\\d+)/:gender(girls|guys|gays|lesbians)',
  '/:country([\\w\\-]+)-:countryId(\\d+)',
]

export const cityLandingPath = `${seoLandingPath}/:country/:region-:regionId(\\d+)/${allCitiesPath}`
export const regionLandingPath = `${seoLandingPath}/:country-:countryId(\\d+)/${allRegionsPath}`

const allCitiesRoutes = [
  {
    path: cityLandingPath,
    component: AllRegionsAndCitiesLoadable,
    exact: false,
    shouldCheck: true,
  },
  {
    path: regionLandingPath,
    component: AllRegionsAndCitiesLoadable,
    exact: false,
    shouldCheck: true,
  },
]

const genericLocationRoutes: AppRoute[] = genericPaths.map<AppRoute>(
  (path) => ({
    path: mergeAllUrls(seoLandingPath, path),
    render: (props) => <LandingViewLoadable key={props.match.url} />,
    exact: false,
    shouldCheck: true,
  })
)

const genderPath = '/:gender(girls|guys|gays|lesbians)'

const landingLinksPaths = [genderPath, allRegionsPath, allCitiesPath]

/**
 * Для правильной работы 404-й
 */
const landingModalPaths = [
  '/:country([\\w\\-]+)/:region/:city-:cityId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  `/:country([\\w\\-]+)/:region-:regionId(\\d+)/${allCitiesPath}`,
  '/:country([\\w\\-]+)/:region-:regionId(\\d+)/:gender(girls|guys|gays|lesbians)?',
  `/:country([\\w\\-]+)-:countryId(\\d+)/${allRegionsPath}`,
  '/:country([\\w\\-]+)-:countryId(\\d+)',
  ...landingLinksPaths,
]

export const landingFoundRoutes = [
  ...landingModalPaths.map((path) => ({
    path: mergeAllUrls(seoLandingPath, path),
    // path,
    component: FoundComponent,
    shouldCheck: true,
    exact: false,
  })),
  ...landingLinksPaths.map((path) => ({
    path,
    component: FoundComponent,
    shouldCheck: true,
    exact: false,
  })),
]

/**
 * Порядок важен, если мы попадаем на all-regions или all-cities то baseUrl
 * неправильно пишется в state и остальные ссылки неправильно работают
 */
export const geoSeoLandingRoutes: AppRoute[] = [
  {
    path: seriousRelationshipFullPath,
    component: loadable(
      () => import('components/page/Landing/Special/SeriousRelationship'),
      withLoader
    ),
    shouldCheck: true,
    exact: false,
  },
  ...allCitiesRoutes,
  ...deprecatedLocationRoutes,
  ...genericLocationRoutes,
]
