import { firebaseMessagingServiceWorkerPath } from 'common-constants/client-service.paths'

import { isServiceWorkerSupported } from './isServiceWorkerSupported'

/**
 * https://developer.chrome.com/docs/workbox/remove-buggy-service-workers
 */
export const unregisterServiceWorkers = () => {
  if (!isServiceWorkerSupported()) {
    return console.info('Service worker is not supported')
  }

  console.info('Unregistering service workers')
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister().catch(console.error)
    }
  })
  console.info('Service workers unregistered')
}

export const unregisterFirebaseMessagingServiceWorker = () => {
  if (!isServiceWorkerSupported()) {
    return
  }

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      if (
        registration.active?.scriptURL.endsWith(
          firebaseMessagingServiceWorkerPath
        )
      ) {
        registration
          .unregister()
          .then((unregistered) => {
            console.info(
              `Is firebase messaging service worker unregistered: ${unregistered}`
            )
          })
          .catch((error) => {
            console.error(
              `Firebase messaging service worker unregistration error: ${error}`
            )
          })
      }
    }
  })
}
