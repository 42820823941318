import { AsyncThunkAction } from 'actions/actions.types'
import { definitions } from 'api/generated/uniweb'

export const UPDATE_META = 'UPDATE_META'

export interface UpdateDirectMetaAction {
  type: typeof UPDATE_META
  title: string
  description: string
  keywords: string
}

export const updateDirectMetaAction =
  (meta: definitions['PageMetaInfo']): AsyncThunkAction =>
  (dispatch) => {
    if (meta) {
      const { title, description, keywords } = meta

      return dispatch({
        type: UPDATE_META,
        title,
        description,
        keywords,
      })
    }
  }
