import { postApi } from 'api/fetchApi'

import { SearchRequest } from './search.types'

export const saveSearchSettingListApi = (form: SearchRequest) => {
  return postApi('/search/options', {
    request: {
      ...form,
    },
  })
}
